import emt from "../../assets/emt.png"
const Data =[
    {
        img:emt,
        h1:"analog panel meters",
        title:"Elapsed Time Meter (Hours Run Meter)",
        description:"ETM72 & ETM96 Elapsed Time Meters (Hours Run Meters) are used to monitor ON/RUN time of plant/equipment which allows the user to check functions such as Production Efficiency, Cost and service period monitoring."

        
    },
    {
        img:emt,
        h1:"multifunction meters",
        title:"DIN rail Moving Iron meter (EQ,PQ,VQ,ZQ 35)",
        description:"EQ35, PQ35, VQ35 & ZQ35 meters measure AC/DC Voltage or Current and frequency. These meters are installed on 35mm Din rail, the mounting width is 45mm and it is housed in UL 94 V-0 approved polycarbonate case and can be mounted in Mosaic mounting (cascading)."

        
    },
    {
        img:emt,
        id:"rishabanalog",
        title:"Challenger series meters",
        h1:"current transformers",

        description:"Challenger 361, 362, 363 & 364 meters measure AC/DC Voltage or Current and frequency. The design features detachable lower fascia which allows the flexibility of either surface or window mounting. These meters have a rear zero adjuster screw for tamperproof installation."

        
    },  {
        img:emt,
        h1:"digital multimeter",
        title:"Compact VAF Meter - RISH CR12",
        description:"RISH CR12 Compact VAF Meter measures important electrical parameters in 3 Phase 4 Wire , 3 Phase 3 Wire , 1 Phase Network & replaces the multiple analog panel meters."

        
    },  {
        img:emt,
        h1:"earth testers",
        title:"Compact VAF Meter - RISH CD30/60",
        description:"RISH CD30/60 Compact VAF Meter measures electrical parameters in 1 Phase Network for AC direct current measurement application."
        

        
    },  {
        img:emt,
        h1:"Power Supply",
        title:"3 Phase RJ12 Current Transformer  ",
        description:"Rish 3 Phase CTs are Square & round type, encapsulated with UL94V-0 approved polycarbonate. It has RJ12 100mA secondary output with RJ12 interface cable, Available from 60A...800A primary current for Square type and 63A,125A & 250A for nano round type CT's." 

        

        
    },
    // {
    //     img:emt,
        
    //     title:"RISH RESIN CAST PROTECTION CT",
    //     description:"Rish resin cast protection CTs are square type and molded with epoxy resin. Protection CTs feature with transparent terminal cover & wide range of primary current ratings, case widths.  Wall or foot mounting options available."

        
    // },  {
    //     img:emt,
    //     title:"RISH Compact Split Core CT ",
    //     description:"Rish Compact Split core CTs are compact in size as compared to earlier Split core CTs and encapsulated with UL94V-0 approved polycarbonate having an openable jaw which has special kind of locking profile to avoid accidental opening."

        

        
    // },  {
    //     img:emt,
    //     title:"Rishabh Young / Rishabh iYoung ",
    //     description:"The new Rishabh Young and Rishabh iYoung Multimeter are compact, Handy & Trendy multimeters specially targeted for applications of Electrical contractors, Service Industry, Technicians, HVAC and also the education sector i.e., students who will be the future of tomorrow."

        
    // },
    // ,  {
    //     img:emt,
    //     title:"RISH Compact Split Core CT ",
    //     description:"Rish Compact Split core CTs are compact in size as compared to earlier Split core CTs and encapsulated with UL94V-0 approved polycarbonate having an openable jaw which has special kind of locking profile to avoid accidental opening."

        

        
    // },  {
    //     img:emt,
    //     title:"Rishabh Young / Rishabh iYoung ",
    //     description:"The new Rishabh Young and Rishabh iYoung Multimeter are compact, Handy & Trendy multimeters specially targeted for applications of Electrical contractors, Service Industry, Technicians, HVAC and also the education sector i.e., students who will be the future of tomorrow."

        
    // },
    // ,  {
    //     img:emt,
    //     title:"RISH Compact Split Core CT ",
    //     description:"Rish Compact Split core CTs are compact in size as compared to earlier Split core CTs and encapsulated with UL94V-0 approved polycarbonate having an openable jaw which has special kind of locking profile to avoid accidental opening."

        

        
    // },  {
    //     img:emt,
    //     title:"Rishabh Young / Rishabh iYoung ",
    //     description:"The new Rishabh Young and Rishabh iYoung Multimeter are compact, Handy & Trendy multimeters specially targeted for applications of Electrical contractors, Service Industry, Technicians, HVAC and also the education sector i.e., students who will be the future of tomorrow."

        
    // },
    // ,  {
    //     img:emt,
    //     title:"RISH Compact Split Core CT ",
    //     description:"Rish Compact Split core CTs are compact in size as compared to earlier Split core CTs and encapsulated with UL94V-0 approved polycarbonate having an openable jaw which has special kind of locking profile to avoid accidental opening."

        

        
    // },  {
    //     img:emt,
    //     title:"Rishabh Young / Rishabh iYoung ",
    //     description:"The new Rishabh Young and Rishabh iYoung Multimeter are compact, Handy & Trendy multimeters specially targeted for applications of Electrical contractors, Service Industry, Technicians, HVAC and also the education sector i.e., students who will be the future of tomorrow."

        
    // },
    


]

export default Data