import React from "react";
import "./crompton.css";
import crologo from "../../assets/cromlogo.png";
import c from "../../assets/c.png";
import Cdata from "./Cdata";
import led from "../../assets/led.png";
import jupneo from "../../assets/jupneo.png";
import highbay from "../../assets/highbay.png";
import neptune from "../../assets/neptune.png";
import batten from "../../assets/batten.png";
import batenlaser from "../../assets/batenlaser.png";
import slims from "../../assets/slims.png";
import laser from "../../assets/laser.png";
import ultron from "../../assets/ultron.png";
import ultronplus from "../../assets/ultronplus.png";
import { useNavigate } from "react-router-dom";
import corp from "../../assets/pdfs/corp.pdf"
import { useState } from "react";

function Crompton() {
  const navigate = useNavigate();
  const [showMore, setShowMore] = useState(true);

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };
  const [showMore2, setShowMore2] = useState(true);

  const toggleShowMore2 = () => {
    setShowMore2(!showMore2);
  };
  const [showMore3, setShowMore3] = useState(true);

  const toggleShowMore3 = () => {
    setShowMore3(!showMore3);
  };
  const [showMore4, setShowMore4] = useState(true);

  const toggleShowMore4 = () => {
    setShowMore4(!showMore4);
  };
  const [showMore5, setShowMore5] = useState(true);

  const toggleShowMore5 = () => {
    setShowMore5(!showMore5);
  };
  const [showMore6, setShowMore6] = useState(true);

  const toggleShowMore6 = () => {
    setShowMore6(!showMore6);
  };
  return (

    <>
      {/* <div className="cromptonfmain"> */}
      <section className="section_process">
          <div className="padding-global">
            <div className="container-large _90vw">
              <div className="padding-top padding-xhuge">
                <div className="w-layout-grid process-layout_component">
                  <div className="process-layout_content-left" style={{top:'0%',position:'inherit'}}>
                    <div className="margin-bottom margin-medium">
                      <div className="section-number-wrapper margin-bottom margin-medium">
                      
                      </div>
                      <div className="margin-bottom">
                      <h2
  text-split=""
  words-slide-from-right=""
  className="heading-style-h2 _8vw-title"
>
  <img src={crologo} className="elmexlompsc" style={{ marginRight: '10px' ,marginTop:'-0.2rem'}} />
  Crompton
</h2>
<br></br>
                        <p style={{color:'#005E8C'}} className="elmexlompsccp">SMART SOLUTIONS. STRONG RELATIONSHIPS.</p>
                        <p style={{color:'#005E8C'}}>CROMPTON GREAVES EXPERTS IN PROFESSIONAL LIGHTINGS</p>
                      </div>
                    </div>
                    <p
                      style={{ opacity: "1" }}
                      className="text-size-medium max-width-medium text-weight-light"
                    >
                    We are one of the leading consumer companies in India with a 75+ years old brand legacy. As of February 2016, we are an independent company under professional management and have 2 business segments – Lighting and Electrical Consumer Durables. We market our products under the “Crompton” brand name in India and select export markets.
                    </p>
                    {/* <div className="terminalaccordian"> */}
                {/* <p style={{color:'#005E8C'}}>PRODUCT RANGE</p> */}
                <div className="mainaccp" >
            <p style={{color:'#005E8C'}}>PRODUCT RANGE</p>
              
              <div className="accordionItem-0-3-239"  >
                <button className="accordionButton-0-3-240 selected">
                LED Wellglass

        <svg
  className="dropdownIcon-0-3-241"
  width="6"
  height="10"
  viewBox="0 0 6 10"
  fill="none"
>
  <path
    d="M4.99995 5L0 0L-0.699995 0.7L3.59995 5L-0.699995 9.3L0 10L4.99995 5Z"
    fill="currentColor"
  ></path>
</svg>

                </button>
                <div className="optionsList-0-3-242">
                  <div className="optionsListInner-0-3-243"></div>
                </div>
              </div>
              <div className="accordionItem-0-3-239"   >
                <button className="accordionButton-0-3-240 selected">
                Highbay
        <svg
  className="dropdownIcon-0-3-241"
  width="6"
  height="10"
  viewBox="0 0 6 10"
  fill="none"
>
  <path
    d="M4.99995 5L0 0L-0.699995 0.7L3.59995 5L-0.699995 9.3L0 10L4.99995 5Z"
    fill="currentColor"
  ></path>
</svg>

                </button>
                <div className="optionsList-0-3-242">
                  <div className="optionsListInner-0-3-243"></div>
                </div>
              </div>
              <div className="accordionItem-0-3-239" >
                <button className="accordionButton-0-3-240 selected">
                Under Canopy Lights

        <svg
  className="dropdownIcon-0-3-241"
  width="6"
  height="10"
  viewBox="0 0 6 10"
  fill="none"
>
  <path
    d="M4.99995 5L0 0L-0.699995 0.7L3.59995 5L-0.699995 9.3L0 10L4.99995 5Z"
    fill="currentColor"
  ></path>
</svg>

                </button>
                <div className="optionsList-0-3-242">
                  <div className="optionsListInner-0-3-243"></div>
                </div>
              </div>
              <div className="accordionItem-0-3-239" >
                <button className="accordionButton-0-3-240 selected">
                Battens

        <svg
  className="dropdownIcon-0-3-241"
  width="6"
  height="10"
  viewBox="0 0 6 10"
  fill="none"
>
  <path
    d="M4.99995 5L0 0L-0.699995 0.7L3.59995 5L-0.699995 9.3L0 10L4.99995 5Z"
    fill="currentColor"
  ></path>
</svg>

                </button>
                <div className="optionsList-0-3-242">
                  <div className="optionsListInner-0-3-243"></div>
                </div>
              </div>
              <div className="accordionItem-0-3-239"  >
                <button className="accordionButton-0-3-240 selected">
                LED Tubes

        <svg
  className="dropdownIcon-0-3-241"
  width="6"
  height="10"
  viewBox="0 0 6 10"
  fill="none"
>
  <path
    d="M4.99995 5L0 0L-0.699995 0.7L3.59995 5L-0.699995 9.3L0 10L4.99995 5Z"
    fill="currentColor"
  ></path>
</svg>

                </button>
                <div className="optionsList-0-3-242">
                  <div className="optionsListInner-0-3-243"></div>
                </div>
              </div>
            </div>
                    <div className="button-group margin-top margin-large">
                   
                    </div>
                  </div>
                  <div className="process-layout_content-right">
                   
                    <div
                      className="process-layout_content-item content-item-2"
                      style={{ color: "white",border:'none' }}
                    >
                      <div className="margin-bottom margin-xsmall">
                      <img src={c} className="insideproductim"></img>
                      </div>
                      <div className="margin-bottom margin-xsmall">
                        
                      </div>
                     
                    </div>
                 
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        
        <div className="cromptoncardsdeta">
          <div className="cromrow">
            <ul className="cromcards">
              <li className="cromcards-item">
                <div className="cromcard" id="ledone">
                  <h1>LED Wellglass</h1>
                  <div className="cromcard-image ">
                    <img src={led}></img>
                  </div>
                  <div className="cromcard-content">
                    <div className="cromcard-title">PHOTON</div>
                    {/* <div className="cromcard-title">LED WELLGLASS</div> */}
                    <div id="profile-description">
      <div className={`text ${showMore ? 'show-more-height' : ''}`}>
        <p className="profile-descriptionhh">Housing made of high pressure die cast aluminium</p>
        <p className="profile-descriptionhh">Ingress pritection of IP66</p>
        <p className="profile-descriptionhh">Luminaire efficacy of 110 Lumens/W & 130 Lumens/W</p>
     
        
        <p className="profile-descriptionhh">UV protected clear PC cover with option of diffused cover</p>       
        <p className="profile-descriptionhh">Potted Driver & High Power LED</p>       
        <p className="profile-descriptionhh">Total Harmonic Distortion less 10 %</p> 
        <p className="profile-descriptionhh">50000 burning hours</p>       
        <p className="profile-descriptionhh">Operating Temperature: -10 degree-50 degree</p> 
             </div>
      {/* <div className="show-more" onClick={toggleShowMore}> */}
        {/* {showMore ? '(Show More)' : '(Show Less)'} */}
        <div className="show-more" onClick={toggleShowMore}>
        {showMore ? (
          <>
          Show More  <i className="fas fa-angle-down" /> 
          </>
        ) : (
          <>
          Show Less  <i className="fas fa-angle-up" /> 
          </>
        )}
      </div>
      {/* </div> */}
    </div>
                  </div>
                </div>
              </li>
              <li className="cromcards-item">
                <div className="cromcard">
                  <h1>Jupiter Neo</h1>
                  <div className="cromcard-image ">
                    <img src={jupneo}></img>
                  </div>
                  {/* <div className="cromcard-content" style={{ marginTop: "5rem" }}> */}
                    {/* <div className="cromcard-title">Photon</div> */}
                    {/* <div className="cromcard-title">HIGHBAY</div> */}
                    <div className="cromcard-content" style={{marginTop:'3rem'}}>
                    <div className="cromcard-title">HIGHBAY</div>
                    {/* <div className="cromcard-title">LED WELLGLASS</div> */}
                    <div id="profile-description">
      <div className={`text ${showMore2 ? 'show-more-height' : ''}`}>
        <p className="profile-descriptionhh">Housing made of high pressure die cast aluminium</p>
        <p className="profile-descriptionhh">Ingress pritection of IP66</p>
        <p className="profile-descriptionhh">Luminaire efficacy of 110 Lumens/W</p>
     
        
        <p className="profile-descriptionhh">Diffuser made of high grade toughened glass</p>       
        <p className="profile-descriptionhh">Potted Driver & High Power LED</p>       
        <p className="profile-descriptionhh">Total Harmonic Distortion less 10 %</p> 
        <p className="profile-descriptionhh">Internal surge protection of 4KV with additional 10KV surge</p>       
        <p className="profile-descriptionhh">Operating Temperature: -10 degree-50 degree</p> 
             </div>
      {/* <div className="show-more" onClick={toggleShowMore}> */}
        {/* {showMore ? '(Show More)' : '(Show Less)'} */}
        <div className="show-more" onClick={toggleShowMore2}>
        {showMore2 ? (
          <>
          Show More  <i className="fas fa-angle-down" /> 
          </>
        ) : (
          <>
          Show Less  <i className="fas fa-angle-up" /> 
          </>
        )}
      </div>
      {/* </div> */}
    </div>
                  </div>

                   
                    
                  {/* </div> */}
                </div>
              </li>
              <li className="cromcards-item">
                <div className="cromcard">
                  <h1>Surround</h1>
                  <div className="cromcard-image ">
                    <img src={highbay}></img>
                  </div>
                  <div className="cromcard-content" style={{marginTop:'3rem'}}>
                    <div className="cromcard-title">HIGHBAY</div>
                    {/* <div className="cromcard-title">LED WELLGLASS</div> */}
                    <div id="profile-description">
      <div className={`text ${showMore3 ? 'show-more-height' : ''}`}>
        <p className="profile-descriptionhh">Housing made of high pressure die cast aluminium</p>
        <p className="profile-descriptionhh">Ingress pritection of IP66</p>
        <p className="profile-descriptionhh">Luminaire efficacy of 110, 130 & 140 lumens/W</p>
     
        
        <p className="profile-descriptionhh">Diffuser made of high grade toughened glass</p>       
        <p className="profile-descriptionhh">Potted Driver & High Power LED</p>       
        <p className="profile-descriptionhh">Total Harmonic Distortion less 10 %</p> 
        <p className="profile-descriptionhh">Internal surge protection of 4KV with additional 10KV surge</p>       
        <p className="profile-descriptionhh">Operating Temperature: -10 degree-50 degree</p> 
             </div>
      {/* <div className="show-more" onClick={toggleShowMore}> */}
        {/* {showMore ? '(Show More)' : '(Show Less)'} */}
        <div className="show-more" onClick={toggleShowMore3}>
        {showMore3 ? (
          <>
          Show More  <i className="fas fa-angle-down" /> 
          </>
        ) : (
          <>
          Show Less  <i className="fas fa-angle-up" /> 
          </>
        )}
      </div>
      {/* </div> */}
    </div>
                  </div>
                </div>
              </li>
              {/*  */}

              {/*  */}
            </ul>
            <ul className="cromcards">
              <li className="cromcards-item">
                <div className="cromcard">
                  <h1>under canopy lights</h1>
                  <div className="cromcard-image">
                    <img src={neptune}></img>
                  </div>
                  <div className="cromcard-content" >
                    <div className="cromcard-title">Neptune</div>
                    {/* <div className="cromcard-title">LED WELLGLASS</div> */}
                    <div id="profile-description">
      <div className={`text ${showMore4 ? 'show-more-height' : ''}`}>
        <p className="profile-descriptionhh">Housing made of high pressure die cast aluminium</p>
        <p className="profile-descriptionhh">Ingress pritection of IP66</p>
        <p className="profile-descriptionhh">Luminaire efficacy of 110, 130 & 140 lumens/W</p>
     
        
        <p className="profile-descriptionhh">Diffuser made of high grade toughened glass</p>       
        <p className="profile-descriptionhh">Potted Driver & High Power LED</p>       
        <p className="profile-descriptionhh">Total Harmonic Distortion less 10 %</p> 
        <p className="profile-descriptionhh">Internal surge protection of 4KV with additional 10KV surge</p>   
        <p className="profile-descriptionhh">Available in 600x600mm frame</p>    
        <p className="profile-descriptionhh">Operating Temperature: -10 degree-50 degree</p> 
             </div>
      {/* <div className="show-more" onClick={toggleShowMore}> */}
        {/* {showMore ? '(Show More)' : '(Show Less)'} */}
        <div className="show-more" onClick={toggleShowMore4}>
        {showMore4 ? (
          <>
          Show More  <i className="fas fa-angle-down" /> 
          </>
        ) : (
          <>
          Show Less  <i className="fas fa-angle-up" /> 
          </>
        )}
      </div>
      {/* </div> */}
    </div>
                  </div>
                </div>
              </li>
              <li className="cromcards-item">
                <div className="cromcard">
                  <h1>Battens</h1>
                  <div className="cromcard-image ">
                    <img src={batten}></img>
                  </div>
                  <div className="cromcard-content">
                    <div className="cromcard-title">Immensa Smart Batten</div>
                    {/* <div className="cromcard-title">Battens</div> */}
                    <p className="cromcard-text">
                      Smart batten which is dimmable and tunable between Warm
                      White
                    </p>
                    <p className="cromcard-text">
                      Natural Light and Cool Day Light. Voice control via Alexa
                      or Google Assistant
                    </p>
                    <p className="cromcard-text">
                      Control from anywhere using My Crompton App
                    </p>

                  </div>
                </div>
              </li>
              <li className="cromcards-item">
                <div className="cromcard">
                  <h1>Battens</h1>
                  <div className="cromcard-image ">
                    <img src={batenlaser}></img>
                  </div>
                  <div className="cromcard-content" style={{ marginTop: "4rem" }}>
                    <div className="cromcard-title">Laser Ray Neo Color</div>
                    {/* <div className="cromcard-title">Battens</div> */}
                    <p className="cromcard-text">
                      Bright Batten with 100 lumens per watt
                    </p>
                    <p className="cromcard-text">Contemporary design</p>
                    <p className="cromcard-text">
                      50% energy savings over conventional tube lights
                    </p>

                    {/* <div className="tab">
                      <input type="checkbox" id="chckc5"></input>
                      <label
                        className="tab-label"
                        for="chckc5"
                        style={{ textAlign: "right" }}
                      >
                        Show more
                      </label>
                      <div className="tab-content">
                        UV protected clear PC cover with option of diffused
                        cover
                      </div>
                      <div className="tab-content">
                        Potted Driver & High Power LED
                      </div>
                      <div className="tab-content">
                        Total Harmonic Distortion less than 10 %
                      </div>
                      <div className="tab-content">50000 burning hours</div>
                    </div> */}
                  </div>
                </div>
              </li>

              {/*  */}

              {/*  */}
            </ul>
            <ul className="cromcards">
              <li className="cromcards-item">
                <div className="cromcard">
                  <h1>Battens</h1>
                  <div className="cromcard-image ">
                    <img src={slims}></img>
                  </div>
                  <div className="cromcard-content">
                    <div className="cromcard-title">Slim Shakti</div>
                    {/* <div className="cromcard-title">Battens</div> */}
                    <p className="cromcard-text">
                      Slim batten with Contemporary design
                    </p>
                    <p className="cromcard-text">
                      Bright batten with 100 lumens per watt
                    </p>
                    <p className="cromcard-text">
                      Wide and Uniform light distribution with Glare Free light
                    </p>

                   
                  </div>
                </div>
              </li>
              <li className="cromcards-item">
                <div className="cromcard">
                  <h1>Battens</h1>
                  <div className="cromcard-image ">
                    <img src={laser}></img>
                  </div>
                  <div className="cromcard-content">
                    <div className="cromcard-title">Laser Ray Neo</div>
                    {/* <div className="cromcard-title">Battens</div> */}
                    <p className="cromcard-text">
                      Bright Batten with 100 lumens per watt
                    </p>
                    <p className="cromcard-text">Contemporary design</p>
                    <p className="cromcard-text">
                      50% energy savings over conventional tube lights
                    </p>

                  
                  </div>
                </div>
              </li>
              <li className="cromcards-item">
                <div className="cromcard">
                  <h1>LED Tubes</h1>
                  <div className="cromcard-image ">
                    <img src={ultron}></img>
                  </div>
                  
                  <div className="cromcard-content" style={{marginTop:'0rem'}}>
                    <div className="cromcard-title">Ultron Plus</div>
                    {/* <div className="cromcard-title">LED WELLGLASS</div> */}
                    <div id="profile-description">
      <div className={`text ${showMore5 ? 'show-more-height' : ''}`}>
        <p className="profile-descriptionhh">Housing made of high pressure die cast aluminium</p>
        <p className="profile-descriptionhh">Ingress protection of IP20</p>
        <p className="profile-descriptionhh">High lumens: 18W with 2100 lumens and 13W with 1600 lumens</p>
     
        
        <p className="profile-descriptionhh">FR grade engineering plastic end caps</p>       
        <br></br>
        <p className="profile-descriptionhh">PCB is mounted on extruded metal plate</p>       
        <p className="profile-descriptionhh">Total Harmonic Distortion less than 20 % (less than 10% variant also available)</p> 
        <p className="profile-descriptionhh">Internal surge protection of 2.5KV</p>       
        <p className="profile-descriptionhh">Operating Temperature -10 degree to 45 degree</p> 
             </div>
      {/* <div className="show-more" onClick={toggleShowMore}> */}
        {/* {showMore ? '(Show More)' : '(Show Less)'} */}
        <div className="show-more" onClick={toggleShowMore5}>
        {showMore5 ? (
          <>
          Show More  <i className="fas fa-angle-down" /> 
          </>
        ) : (
          <>
          Show Less  <i className="fas fa-angle-up" /> 
          </>
        )}
      </div>
      {/* </div> */}
    </div>
                  </div>
                </div>
              </li>

              {/*  */}

              {/*  */}
            </ul>
            <ul className="cromcards">
              <li className="cromcards-item">
                <div className="cromcard">
                  <h1>LED Tubes</h1>
                  <div className="cromcard-image ">
                    <img src={ultronplus} style={{width:"20rem"}}></img>
                  </div>
                  <div className="cromcard-content" style={{marginTop:'0rem'}}>
                    <div className="cromcard-title">Ultron </div>
                    {/* <div className="cromcard-title">LED WELLGLASS</div> */}
                    <div id="profile-description">
      <div className={`text ${showMore6 ? 'show-more-height' : ''}`}>
        <p className="profile-descriptionhh">Housing made of high pressure die cast aluminium</p>
        <p className="profile-descriptionhh">Ingress protection of IP20</p>
        <p className="profile-descriptionhh">High lumens: 18W with 2100 lumens and 13W with 1600 lumens</p>
     
      
        <p className="profile-descriptionhh">FR grade engineering plastic end caps</p>  
        <br></br>     
        <p className="profile-descriptionhh">PCB is mounted on extruded metal plate</p>       
        {/* <p className="profile-descriptionhh">Total Harmonic Distortion less than 20 % (less than 10% variant also available)</p>  */}
        <p className="profile-descriptionhh">Internal surge protection of 2.5KV</p>       
        <p className="profile-descriptionhh">Operating Temperature -10 degree to 45 degree</p> 
             </div>
      {/* <div className="show-more" onClick={toggleShowMore}> */}
        {/* {showMore ? '(Show More)' : '(Show Less)'} */}
        <div className="show-more" onClick={toggleShowMore6}>
        {showMore6 ? (
          <>
          Show More  <i className="fas fa-angle-down" /> 
          </>
        ) : (
          <>
          Show Less  <i className="fas fa-angle-up" /> 
          </>
        )}
      </div>
      {/* </div> */}
    </div>
                  </div>
                </div>
              </li>

              {/*  */}

              {/*  */}
            </ul>
          </div>
          <div className='downlaod-solapnel' >
      
      <a className='havellviewmore' href={corp} target='_blank'> 
   Download Brochure
</a>

    </div>
        </div>
     
      {/* </div> */}
    
    </>
  );
}

export default Crompton;
