import React from "react";
import "./rishab.css";
import rilogo from "../../assets/rilogo.png";
import rii from "../../assets/rii.png";
import CardData from "./Data";
import Card from "./Card";
import { Link } from 'react-router-dom';
import ri from "../../assets/pdfs/ri.pdf"

function Rishab() {
  // const dataComp = Data.map((data) => {});

  return (
    <>
    <div className="rishbanmep">
    <section className="section_process">
          <div className="padding-global">
            <div className="container-large _90vw">
              <div className="padding-top padding-xhuge">
                <div className="w-layout-grid process-layout_component">
                  <div className="process-layout_content-left" style={{top:'0%',position:'inherit'}}>
                    <div className="margin-bottom margin-medium">
                      <div className="section-number-wrapper margin-bottom margin-medium">
                      
                      </div>
                      <div className="margin-bottom">
                        
                        <h2
                          text-split=""
                          words-slide-from-right=""
                          className="heading-style-h2 _8vw-title"
                        //  style={{fontSize:'3vw'}}
                        >
                          <img src={rilogo} className="rishablogop"></img>
                          Rishabh Instruments
                        </h2>
                        <p className="rishabsdesp" style={{color:'rgb(0, 94, 140)'}}>Appliances, Electrical, and Electronics Manufacturing
</p>
                        
                      </div>
                    </div>
                    <p
                      style={{ opacity: "1" }}
                      className="text-size-medium max-width-medium text-weight-light"
                    >
                   Rishabh Instruments Pvt. Ltd., an organisation that has built its core competence in manufacturing, design and development of Solar Inverters, Test and Measuring Instruments and Industrial Control Products on strong fundamentals; in terms of people, infrastructure and financials. The company provides a hands-on, value driven, professional environment considering people its most important asset.
                   <br></br><br></br>
                   Our core focus on technology, cost competitive manufacturing and customer support has proved to be the evolving blend paving our growth journey. We wish to touch as many lives as possible in the field of instrumentation and clean energy, and energy management. 
                    </p>
                    {/* <div className="terminalaccordian"> */}
                {/* <p style={{color:'#005E8C'}}>PRODUCT RANGE</p> */}
                <div className="mainaccp">
            <p style={{color:'#005E8C'}}>PRODUCT RANGE</p>
              <div className="accordionItem-0-3-239" 
              // onClick={() => navigate("/elmex/Elmex/SolarProducts")}
              >
                <button className="accordionButton-0-3-240 selected">
                analog panel meters 
                <svg
  className="dropdownIcon-0-3-241"
  width="6"
  height="10"
  viewBox="0 0 6 10"
  fill="none"
>
  <path
    d="M4.99995 5L0 0L-0.699995 0.7L3.59995 5L-0.699995 9.3L0 10L4.99995 5Z"
    fill="currentColor"
  ></path>
</svg>

                </button>
                <div className="optionsList-0-3-242">
                  <div className="optionsListInner-0-3-243"></div>
                </div>
              </div>
              <div className="accordionItem-0-3-239"  
              // onClick={() => navigate("/elmex/Elmex/TerminalBlocks")}
              >
                <button className="accordionButton-0-3-240 selected">
                multifunction meters
                <svg
  className="dropdownIcon-0-3-241"
  width="6"
  height="10"
  viewBox="0 0 6 10"
  fill="none"
>
  <path
    d="M4.99995 5L0 0L-0.699995 0.7L3.59995 5L-0.699995 9.3L0 10L4.99995 5Z"
    fill="currentColor"
  ></path>
</svg>

                </button>
                <div className="optionsList-0-3-242">
                  <div className="optionsListInner-0-3-243"></div>
                </div>
              </div>
              <div className="accordionItem-0-3-239"  
              // onClick={() => navigate("/elmex/Elmex/TerminalBlocks")}
              >
                <button className="accordionButton-0-3-240 selected">
                current transformers
                <svg
  className="dropdownIcon-0-3-241"
  width="6"
  height="10"
  viewBox="0 0 6 10"
  fill="none"
>
  <path
    d="M4.99995 5L0 0L-0.699995 0.7L3.59995 5L-0.699995 9.3L0 10L4.99995 5Z"
    fill="currentColor"
  ></path>
</svg>

                </button>
                <div className="optionsList-0-3-242">
                  <div className="optionsListInner-0-3-243"></div>
                </div>
              </div>
              <div className="accordionItem-0-3-239"  
              // onClick={() => navigate("/elmex/Elmex/TerminalBlocks")}
              >
                <button className="accordionButton-0-3-240 selected">
                digital multimeter
                <svg
  className="dropdownIcon-0-3-241"
  width="6"
  height="10"
  viewBox="0 0 6 10"
  fill="none"
>
  <path
    d="M4.99995 5L0 0L-0.699995 0.7L3.59995 5L-0.699995 9.3L0 10L4.99995 5Z"
    fill="currentColor"
  ></path>
</svg>

                </button>
                <div className="optionsList-0-3-242">
                  <div className="optionsListInner-0-3-243"></div>
                </div>
              </div>
              <div className="accordionItem-0-3-239"  
              // onClick={() => navigate("/elmex/Elmex/TerminalBlocks")}
              >
                <button className="accordionButton-0-3-240 selected">
                earth testers
                <svg
  className="dropdownIcon-0-3-241"
  width="6"
  height="10"
  viewBox="0 0 6 10"
  fill="none"
>
  <path
    d="M4.99995 5L0 0L-0.699995 0.7L3.59995 5L-0.699995 9.3L0 10L4.99995 5Z"
    fill="currentColor"
  ></path>
</svg>

                </button>
                <div className="optionsList-0-3-242">
                  <div className="optionsListInner-0-3-243"></div>
                </div>
              </div>
              <div className="accordionItem-0-3-239"  
              // onClick={() => navigate("/elmex/Elmex/TerminalBlocks")}
              >
                <button className="accordionButton-0-3-240 selected">
                power supply
                <svg
  className="dropdownIcon-0-3-241"
  width="6"
  height="10"
  viewBox="0 0 6 10"
  fill="none"
>
  <path
    d="M4.99995 5L0 0L-0.699995 0.7L3.59995 5L-0.699995 9.3L0 10L4.99995 5Z"
    fill="currentColor"
  ></path>
</svg>
                </button>
                <div className="optionsList-0-3-242">
                  <div className="optionsListInner-0-3-243"></div>
                </div>
              </div>
             
            </div>
                    {/* <div className="button-group margin-top margin-large">
                   
                    </div> */}
                  </div>
                  <div className="riiam">
                  <div className="process-layout_content-right">
                   
                    <div
                      className="process-layout_content-item content-item-2"
                      style={{ color: "white",border:'none' }}
                    >
                      <div className="margin-bottom margin-xsmall">
                      <img src={rii} className="insideproductim" ></img>
                      </div>
                      <div className="margin-bottom margin-xsmall">
                        
                      </div>
                     
                    </div>
                 
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <div className="rishabcardrdetail"> */}
      
      <Card details={CardData} />
      {/* </div> */}
      <div className='downlaod-solapnel' style={{opacity:'1'}}>
      
      <a className='havellviewmore' href={ri} target='_blank' style={{marginTop:'3rem'}}> 
      Download Brochure
</a>

    </div>
    <div className='downlaod-solapnel' style={{opacity:'0'}} >
      
      <a className='havellviewmore' href={ri} target='_blank' style={{marginTop:'3rem'}}> 
      Download Brochure
</a>

    </div>
    </div>
    </>
  );
}
 
export default Rishab;
