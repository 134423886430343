import React from "react";
import "./loader.css";

function Loader() {
  return (
    <div className="gvkmainloader">
      <div className="lcontainer">
        <div className="row">
          <div className="col-sm-6 text-center">
        
            <div className="loader"></div>
          </div>
        
        </div>
      </div>
    </div>
  );
}

export default Loader;
