const Solardata =

[

  {
      "id":1,
      "name":"Rated Voltage",
      "city":"1500V DC",
      
  },
  
  {
      "id":2,
      "name":"Rated Current",
      "city":"25A (2.5 mm²), 30A (4.0 mm², 6.0 mm²)"
  },
  
  {
      "id":3,
      "name":"RMS Test Voltage",
      "city":"8kV (1500V)"
  },
  
  {
      "id":4,
      "name":"Impulse withstand Voltage",
      "city":"16 kV"
  },
  
  {
      "id":5,
      "name":"Degree of Protection",
      "city":"IP 67 / IP 68"
  },
  
  {
      "id":6,
      "name":"Contact Material",
      "city":"Copper with Tin Plating"
  },
  {
      "id":7,
      "name":"Ambient Temperature",
      "city":"-40° C to +85° C"
  },
  
  {
      "id":8,
      "name":"Max. Operating Temp.",
      "city":"+110° C"
  },
  
  {
      "id":9,
      "name":"Polution Degree",
      "city":"3"
  },{
    "id":10,
    "name":"Contact Resistance",
    "city":"< 0.5 mΩ"
  },
  {
    "id":10,
    "name":"Insertion Force",
    "city":"< 0.5 N"
  },
  {
    "id":10,
    "name":"Withdrawal Force",
    "city":"> 50 N"
  },
  {
    "id":11,
    "name":"Locking System",
    "city":"Snap In"
  }
 

]
  export default Solardata