import React from "react";
import "./elmex.css";
import elmex2 from "../../assets/elm.png";
import { useNavigate } from "react-router-dom";
import el from "../../assets/el.png"
function Elmex() {
    const navigate = useNavigate();
  return (
    <>
   <section className="section_process">
          <div className="padding-global">
            <div className="container-large _90vw">
              <div className="padding-top padding-xhuge">
                <div className="w-layout-grid process-layout_component">
                  <div className="process-layout_content-left" style={{top:'0%',position:'inherit'}}>
                    <div className="margin-bottom margin-medium">
                      <div className="section-number-wrapper margin-bottom margin-medium">
                      
                      </div>
                      <div className="margin-bottom">
                        
                        <h2
                          text-split=""
                          words-slide-from-right=""
                          className="heading-style-h2 _8vw-title"
                        //  style={{fontSize:'2.8vw'}}
                        >
                          <img src={el}  className="elmexlomp"></img>
                        Elmex
                        </h2>
                        <p style={{color:'rgb(0, 94, 140)',marginTop:'1rem'}}> TERMINAL BLOCKS AND SOLAR PRODUCTS</p>
                        
                      </div>
                    </div>
                    <p
                      style={{ opacity: "1" }}
                      className="text-size-medium max-width-medium text-weight-light"
                    >
                    Elmex is a pioneer in the field of electrical wire termination technology in India. Its journey began in 1963 as a manufacturer of Terminal Blocks (Din Rail Mounted Connectors) for the switchgear industry. Steered by the vision of our founder-chairman Mr. J. D. Ray, it is today regarded as one of India's leading manufacturers of terminal blocks. elmex is a pioneer in the field of electrical wire termination technology and a leading manufacturer of terminal blocks in India.
                    </p>
                    {/* <div className="terminalaccordian"> */}
                {/* <p style={{color:'#005E8C'}}>PRODUCT RANGE</p> */}
                <div className="mainaccp" >
            <p style={{color:'#005E8C'}}>PRODUCT RANGE</p>
              <div className="accordionItem-0-3-239" 
              onClick={() => navigate("/elmex/Elmex/SolarProducts")}
              >
                <button className="accordionButton-0-3-240 selected">
                solar products
                <svg
  className="dropdownIcon-0-3-241"
  width="6"
  height="10"
  viewBox="0 0 6 10"
  fill="none"
>
  <path
    d="M4.99995 5L0 0L-0.699995 0.7L3.59995 5L-0.699995 9.3L0 10L4.99995 5Z"
    fill="currentColor"
  ></path>
</svg>

                </button>
                <div className="optionsList-0-3-242">
                  <div className="optionsListInner-0-3-243"></div>
                </div>
              </div>
              <div className="accordionItem-0-3-239"  onClick={() => navigate("/elmex/Elmex/TerminalBlocks")}>
                <button className="accordionButton-0-3-240 selected">
                TERMINAL BLOCKS
                <svg
  className="dropdownIcon-0-3-241"
  width="6"
  height="10"
  viewBox="0 0 6 10"
  fill="none"
>
  <path
    d="M4.99995 5L0 0L-0.699995 0.7L3.59995 5L-0.699995 9.3L0 10L4.99995 5Z"
    fill="currentColor"
  ></path>
</svg>


                </button>
                <div className="optionsList-0-3-242">
                  <div className="optionsListInner-0-3-243"></div>
                </div>
              </div>
             
            </div>
                    {/* <div className="button-group margin-top margin-large">
                   
                    </div> */}
                  </div>
                  <div className="process-layout_content-right">
                   
                    <div
                      className="process-layout_content-item content-item-2"
                      style={{ color: "white",border:'none' }}
                    >
                      <div className="margin-bottom margin-xsmall">
                      <img src={elmex2} className="insideproductim" ></img>
                      </div>
                      <div className="margin-bottom margin-xsmall">
                        
                      </div>
                     
                    </div>
                 
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
    </>
  );
}

export default Elmex;
