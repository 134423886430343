import React from "react";
import keii from "../../assets/keii.png";
import kei1 from "../../assets/kei1.png";
import kei2 from "../../assets/kei2.png";
import kei3 from "../../assets/kei3.png";
import kei4 from "../../assets/kei4.png";
import image1 from "../../assets/image 1.png";
import image2 from "../../assets/image 2.png";
// import image3 from "../../assets/image 3.png";
import image4 from "../../assets/image 4.png";
import image5 from "../../assets/image 5.png";
import image6 from "../../assets/image 6.png";
import image7 from "../../assets/image 7.png";
import image8 from "../../assets/image 8.png";
import image9 from "../../assets/image 9.png";
import image10 from "../../assets/image 10.png";
import image11 from "../../assets/image 11.png";
import Group23 from "../../assets/Group 23.png";
import Group24 from "../../assets/Group 24.png";
import Group25 from "../../assets/Group 25.png";
import Group26 from "../../assets/Group 26.png";
import Group27 from "../../assets/Group 27.png";
import Group28 from "../../assets/Group 28.png";
import Group29 from "../../assets/Group 29.png";
import Group30 from "../../assets/Group 30.png";
import Group31 from "../../assets/Group 31.png";
import Group32 from "../../assets/Group 32.png";
// import Group31 from "../../assets/Group 31.png";
import Group33 from "../../assets/Group 33.png";
import Group34 from "../../assets/Group 34.png";
import Group35 from "../../assets/Group 35.png";
import Group36 from "../../assets/Group 36.png";
import Group37 from "../../assets/Group 37.png";
import Group38 from "../../assets/Group 38.png";
import Group39 from "../../assets/Group 39.png";
import Group40 from "../../assets/Group 40.png";
import Group41 from "../../assets/Group 41.png";
import Group42 from "../../assets/Group 42.png";
import Havells from "../../assets/pdfs/Elmeasure.pdf";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Routes,
  useLocation
} from "react-router-dom";

// import "./havell.css";
import "../Havells/havell.css";
import elee1 from "../../assets/elee1.png";
import elee2 from "../../assets/elee2.png"

import havelllogo from "../../assets/havelllogo.png";
import { useNavigate } from "react-router-dom";
import elmeasurelogo from "../../assets/elmeasurelogo.png";
import elmain from "../../assets/elmain.png";

function Elmeasure() {
  const navigate = useNavigate();
  function handleClick(event) {
    event.preventDefault();
    const sectionId = event.target.getAttribute("data-section-id");
    const section = document.getElementById(sectionId);
    section.scrollIntoView({ behavior: "smooth" });
  }
  const productItems = [
    { title: "energy and power monitors", link: "" },
    { title: "protection and control", link: "" },
    { title: "industrial communication", link: "" },
    { title: "low voltage switchgear", link: "" },
    { title: "source changeover-switches", link: "" },
    { title: "process measurements", link: "" },
    { title: "current transformers", link: "" },
    { title: "energy management solutions", link: "" },
  ];

  const panelCardItems = [
    { img: kei1, title: "HT POWER CABLES" },
    { img: kei2, title: "LT POWER CABLES" },
    { img: kei3, title: "LT CONTROL CABLES" },
    { img: kei4, title: "FIRE SURVIVAL CABLES" },
  ];
  const products = [
    {
      title: 'Molded Case Circuit Breakers (MCCB)',
      imgSrc: elmain,
      features: [
        { iconSrc: 'icon-url', title: 'LSIG', description: 'Protection' },
        { iconSrc: 'icon-url', title: 'IS/IEC 60947-2', description: 'Circuit Breaker Standards' }
      ],
      link: '/low-voltage-switchgear/mccb'
    },
    // Add more product objects as needed
  ];
  const ProductCard = ({ title, imgSrc, features, link }) => (
    <a className="cursor-pointer" href={link}>
      {/* Render the product card content */}
    </a>
  );
  return (
    <div>
      <div className="elmseauremainp"> 
        <section className="section_process">
          <div className="padding-global">
            <div className="container-large _90vw">
              <div className="padding-top padding-xhuge">
                <div className="w-layout-grid process-layout_component">
                  <div
                    className="process-layout_content-left"
                    style={{ top: "0", position: "inherit" }}
                  >
                    <div className="margin-bottom margin-medium">
                      <div className="section-number-wrapper margin-bottom margin-medium"></div>
                      <div className="margin-bottom">
                        <div className="havellcenter-container">
                          <h2
                            text-split=""
                            words-slide-from-right=""
                            className="havellogo"
                          >
                            <img src={elmeasurelogo} alt="Havell Logo" />
                          </h2>
                        </div>
<br></br>
                        <h2
                          text-split=""
                          words-slide-from-right=""
                          className="heading-style-h2 _8vw-title elmeasureheading"
                        >
                         Products for Reliable & efficient Power Management





                        </h2>
                      </div>
                    </div>
                    <p
                      style={{ opacity: "1" }}
                      className="text-size-medium max-width-medium text-weight-light"
                    >
                    Elmeasure is an industry leader in smart and sustainable energy management.We specialise in helping businesses, industries and end users reduce their waste, decrease their carbon footprint and monitor and control their energy efficiency.
                    </p>

                    <div>
                      <div className="mainaccp">
                        <p style={{ color: "#005E8C" }}>PRODUCT RANGE</p>
                        {productItems.map((product, index) => (
                          <div
                            className="accordionItem-0-3-239"
                            onClick={() => navigate(product.link)}
                            key={index}
                          >
                            <button className="accordionButton-0-3-240 selected">
                              {product.title}
                              <svg
  className="dropdownIcon-0-3-241"
  width="6"
  height="10"
  viewBox="0 0 6 10"
  fill="none"
>
  <path
    d="M4.99995 5L0 0L-0.699995 0.7L3.59995 5L-0.699995 9.3L0 10L4.99995 5Z"
    fill="currentColor"
  ></path>
</svg>

                            </button>
                            <div className="optionsList-0-3-242">
                              <div className="optionsListInner-0-3-243"></div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>

                    <div className="button-group margin-top margin-large"></div>
                  </div>
                  <div className="process-layout_content-right">
                    <div
                      className="process-layout_content-item content-item-2"
                      style={{ color: "white", border: "none" }}
                    >
                      <div className="margin-bottom margin-xsmall">
                        <img src={elmain} className="insideproductim"></img>
                      </div>
                      <div className="margin-bottom margin-xsmall"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*  */}
        <div className="white-line not-visible-mobile about-line"></div>
        <section className="section_process">
          <div className="padding-global">
            <div className="container-large _90vw">
            
              <div className="padding-top padding-xhuge">
              <h4 style={{textAlign:'center'}} className="elmeasuresubheadingpr">energy and power monitors</h4>
                <div className="w-layout-grid process-layout_component elmeasureallprod" >
               
                  <div
                    className="process-layout_content-left"
                    style={{ top: "0", position: "inherit" }}
                  >
                    <div className="margin-bottom margin-medium">
                      <div className="section-number-wrapper margin-bottom margin-medium"></div>
                      <div className="margin-bottom">
                        <a
                          className="cursor-pointer"
                          
                        >
                          <div className="flex items-center justify-start gap-6 sm:mb-6">
                            <div className="relative w-[170px] h-[170px]">
                              <img
                                alt="Molded Case Circuit Breakers (MCCB)"
                                sizes="100vw"
                                // srcSet=""
                                src={image1}
                                decoding="async"
                                data-nimg="fill"
                                className="object-contain"
                                loading="lazy"
                                style={{
                                  position: "absolute",
                                  height: "100%",
                                  width: "100%",
                                  left: 0,
                                  top: 0,
                                  right: 0,
                                  bottom: 0,
                                  color: "transparent",
                                }}
                              />
                            </div>
                            <div className="flex flex-col">
                              <h4 className="m_heading5_bold leading-5 text-elm-white" style={{color:"#5c5c5c"}}>
                              DC Energy Meters
                              </h4>
                              <div className="h-[1px] mt-3 mb-3 elm_white_to_transparent"></div>
                              <div className="flex items-center gap-x-4">
                                <div
                                  className="flex flex-col items-start"
                                  style={{ opacity: 1, transform: "none" }}
                                >
                                  <div className="object-position-left">
                                    <img
                                      src={elee1}
                                      width="58"
                                      height="41"
                                      decoding="async"
                                      data-nimg="1"
                                      className="h-5 w-14 svg-filter-blue"
                                      loading="lazy"
                                      style={{
                                        color: "transparent",
                                        objectPosition: "left",
                                        objectFit: "contain",
                                      }}
                                    />
                                  </div>
                                  <p className="font-bold text-[14px] w-full text-elm-white">
                                  Measurements
                                  </p>
                                  {/* <p className="font-light text-[12px] text-elm-white leading-3 w-full">
                                  Smart DC metering 
                                  </p> */}
                                  <p className="font-light text-[12px] text-elm-white leading-3 w-full">
                                  Smart DC metering for renewable energy sources
                                  </p>
                                </div>
                                <div
                                  className="flex flex-col items-start"
                                  style={{ opacity: 1, transform: "none" }}
                                >
                                  <div className="object-position-left">
                                    <img
                                      src={elee2}
                                      width="29"
                                      height="37"
                                      decoding="async"
                                      data-nimg="1"
                                      className="h-5 w-14 svg-filter-blue"
                                      loading="lazy"
                                      style={{
                                        color: "transparent",
                                        objectPosition: "left",
                                        objectFit: "contain",
                                      }}
                                    />
                                  </div>
                                  <p className="font-bold text-[14px] w-full text-elm-white">
                                  Power Monitoring 
                                  </p>
                                  <p className="font-light text-[12px] text-elm-white leading-3 w-full" style={{opacity:'0'}}>
                                  Smart DC metering for renewable energy sources
                                  </p>
                                </div>
                                
                              </div>
                            </div>
                            
                          </div>
                        </a>
                      </div>
                    </div>

                   

                    
                  </div>
                  <div className="process-layout_content-right elmeasureproduct">
                    <a
                      className="cursor-pointer"
                    
                    >
                      <div className="flex items-center justify-start gap-6 sm:mb-6">
                        <div className="relative w-[170px] h-[170px]">
                          <img
                            alt="Molded Case Circuit Breakers (MCCB)"
                            sizes="100vw"
                            src={image2}
                            decoding="async"
                            data-nimg="fill"
                            className="object-contain"
                            loading="lazy"
                            style={{
                              position: "absolute",
                              height: "100%",
                              width: "100%",
                              left: 0,
                              top: 0,
                              right: 0,
                              bottom: 0,
                              color: "transparent",
                            }}
                          />
                        </div>
                        <div className="flex flex-col">
                          <h4 className="m_heading5_bold leading-5 text-elm-white" style={{color:"#5c5c5c"}}>
                          Branch Circuit Power Monitors
                          </h4>
                          <div className="h-[1px] mt-3 mb-3 elm_white_to_transparent"></div>
                          <div className="flex items-center gap-x-4">
                            <div
                              className="flex flex-col items-start"
                              style={{ opacity: 1, transform: "none" }}
                            >
                              <div className="object-position-left">
                                <img
                                  src={Group25}
                                  width="38"
                                  height="41"
                                  decoding="async"
                                  data-nimg="1"
                                  className="h-5 w-14 svg-filter-blue"
                                  loading="lazy"
                                  style={{
                                    color: "transparent",
                                    objectPosition: "left",
                                    objectFit: "contain",
                                  }}
                                />
                              </div>
                              <p className="font-bold text-[14px] w-full text-elm-white">
                              Measurements
                              </p>
                              <p className="font-light text-[12px] text-elm-white leading-3 w-full">
                              Compact device to enroute multiple channels
                              </p>
                            </div>
                            <div
                              className="flex flex-col items-start"
                              style={{ opacity: 1, transform: "none" }}
                            >
                              <div className="object-position-left">
                                <img
                                  src={Group26}
                                  width="29"
                                  height="37"
                                  decoding="async"
                                  data-nimg="1"
                                  className="h-5 w-14 svg-filter-blue"
                                  loading="lazy"
                                  style={{
                                    color: "transparent",
                                    objectPosition: "left",
                                    objectFit: "contain",
                                  }}
                                />
                              </div>
                              <p className="font-bold text-[14px] w-full text-elm-white">
                              Power monitoring 
                              </p>
                              <p className="font-light text-[12px] text-elm-white leading-3 w-full" style={{opacity:'0'}}>
                                Circuit Breaker Standards
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
                {/* <a >For more products click here</a> */}
              
              </div>
            </div>
       
          </div>
       
        </section>
       
        <div className="white-line not-visible-mobile about-line" style={{backgroundColor:"#5c5c5c"}}></div>
        <section className="section_process">
          <div className="padding-global">
            <div className="container-large _90vw">
            
              <div className="padding-top padding-xhuge">
              <h4 style={{textAlign:'center'}} className="elmeasuresubheadingpr">protection and control</h4>
                <div className="w-layout-grid process-layout_component elmeasureallprod">
               
                  <div
                    className="process-layout_content-left"
                    style={{ top: "0", position: "inherit" }}
                  >
                    <div className="margin-bottom margin-medium">
                      <div className="section-number-wrapper margin-bottom margin-medium"></div>
                      <div className="margin-bottom">
                        <a
                          className="cursor-pointer"
                      
                        >
                          <div className="flex items-center justify-start gap-6 sm:mb-6">
                            <div className="relative w-[170px] h-[170px]">
                              <img
                                alt="Molded Case Circuit Breakers (MCCB)"
                                sizes="100vw"
                                // srcSet=""
                                src={image4}
                                decoding="async"
                                data-nimg="fill"
                                className="object-contain"
                                loading="lazy"
                                style={{
                                  position: "absolute",
                                  height: "100%",
                                  width: "100%",
                                  left: 0,
                                  top: 0,
                                  right: 0,
                                  bottom: 0,
                                  color: "transparent",
                                }}
                              />
                            </div>
                            <div className="flex flex-col">
                              <h4 className="m_heading5_bold leading-5 text-elm-white" style={{color:"#5c5c5c"}}>
                              Demand controllers
                              </h4>
                              <div className="h-[1px] mt-3 mb-3 elm_white_to_transparent"></div>
                              <div className="flex items-center gap-x-4">
                                <div
                                  className="flex flex-col items-start"
                                  style={{ opacity: 1, transform: "none" }}
                                >
                                  <div className="object-position-left">
                                    <img
                                      src={Group27}
                                      width="58"
                                      height="41"
                                      decoding="async"
                                      data-nimg="1"
                                      className="h-5 w-14 svg-filter-blue"
                                      loading="lazy"
                                      style={{
                                        color: "transparent",
                                        objectPosition: "left",
                                        objectFit: "contain",
                                      }}
                                    />
                                  </div>
                                  <p className="font-bold text-[14px] w-full text-elm-white">
                                  Load Shedding Contral
                                  </p>
                                  {/* <p className="font-light text-[12px] text-elm-white leading-3 w-full">
                                  Smart DC metering 
                                  </p> */}
                                  <p className="font-light text-[12px] text-elm-white leading-3 w-full">
                                  Monitor the healthiness of the power systems effectively
                                  </p>
                                </div>
                                <div
                                  className="flex flex-col items-start"
                                  style={{ opacity: 1, transform: "none" }}
                                >
                                  <div className="object-position-left">
                                    <img
                                      src={Group28}
                                      width="29"
                                      height="37"
                                      decoding="async"
                                      data-nimg="1"
                                      className="h-5 w-14 svg-filter-blue"
                                      loading="lazy"
                                      style={{
                                        color: "transparent",
                                        objectPosition: "left",
                                        objectFit: "contain",
                                      }}
                                    />
                                  </div>
                                  <p className="font-bold text-[14px] w-full text-elm-white">
                                  Recordings
                                  </p>
                                  <p className="font-light text-[12px] text-elm-white leading-3 w-full" style={{opacity:'0'}}>
                                  Smart DC metering for renewable energy sources
                                  </p>
                                </div>
                                
                              </div>
                            </div>
                            
                          </div>
                        </a>
                      </div>
                    </div>

                   

                    
                  </div>
                  <div className="process-layout_content-right elmeasureproduct">
                    <a
                      className="cursor-pointer"
                     
                    >
                      <div className="flex items-center justify-start gap-6 sm:mb-6">
                        <div className="relative w-[170px] h-[170px]">
                          <img
                            alt="Molded Case Circuit Breakers (MCCB)"
                            sizes="100vw"
                            src={image5}
                            decoding="async"
                            data-nimg="fill"
                            className="object-contain"
                            loading="lazy"
                            style={{
                              position: "absolute",
                              height: "100%",
                              width: "100%",
                              left: 0,
                              top: 0,
                              right: 0,
                              bottom: 0,
                              color: "transparent",
                            }}
                          />
                        </div>
                        <div className="flex flex-col">
                          <h4 className="m_heading5_bold leading-5 text-elm-white" style={{color:"#5c5c5c"}}>
                          Power Factor Controllers
                          </h4>
                          <div className="h-[1px] mt-3 mb-3 elm_white_to_transparent"></div>
                          <div className="flex items-center gap-x-4">
                            <div
                              className="flex flex-col items-start"
                              style={{ opacity: 1, transform: "none" }}
                            >
                              <div className="object-position-left">
                                <img
                                  src={Group29}
                                  width="38"
                                  height="41"
                                  decoding="async"
                                  data-nimg="1"
                                  className="h-5 w-14 svg-filter-blue"
                                  loading="lazy"
                                  style={{
                                    color: "transparent",
                                    objectPosition: "left",
                                    objectFit: "contain",
                                  }}
                                />
                              </div>
                              <p className="font-bold text-[14px] w-full text-elm-white">
                              Of control
                              </p>
                              <p className="font-light text-[12px] text-elm-white leading-3 w-full">
                              For maximizing energy efficiency and savings on energy bills
                              </p>
                            </div>
                            <div
                              className="flex flex-col items-start"
                              style={{ opacity: 1, transform: "none" }}
                            >
                              <div className="object-position-left">
                                <img
                                  src={Group30}
                                  width="29"
                                  height="37"
                                  decoding="async"
                                  data-nimg="1"
                                  className="h-5 w-14 svg-filter-blue"
                                  loading="lazy"
                                  style={{
                                    color: "transparent",
                                    objectPosition: "left",
                                    objectFit: "contain",
                                  }}
                                />
                              </div>
                              <p className="font-bold text-[14px] w-full text-elm-white">
                              Accuracy
                              </p>
                              <p className="font-light text-[12px] text-elm-white leading-3 w-full" style={{opacity:'0'}}>
                                Circuit Breaker Standards
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
                {/* <a >For more products click here</a> */}
              </div>
            </div>
       
          </div>
       
        </section>
        <div className="white-line not-visible-mobile about-line" style={{backgroundColor:"#5c5c5c"}}></div>
        
        <section className="section_process">
          <div className="padding-global">
            <div className="container-large _90vw">
            
              <div className="padding-top padding-xhuge">
              <h4 style={{textAlign:'center'}} className="elmeasuresubheadingpr">industrial communication</h4>
                <div className="w-layout-grid process-layout_component elmeasureallprod">
               
                  <div
                    className="process-layout_content-left"
                    style={{ top: "0", position: "inherit" }}
                  >
                    <div className="margin-bottom margin-medium">
                      <div className="section-number-wrapper margin-bottom margin-medium"></div>
                      <div className="margin-bottom">
                        <a
                          className="cursor-pointer"
                        
                        >
                          <div className="flex items-center justify-start gap-6 sm:mb-6">
                            <div className="relative w-[170px] h-[170px]">
                              <img
                                alt="Molded Case Circuit Breakers (MCCB)"
                                sizes="100vw"
                                // srcSet=""
                                src={image6}
                                decoding="async"
                                data-nimg="fill"
                                className="object-contain"
                                loading="lazy"
                                style={{
                                  position: "absolute",
                                  height: "100%",
                                  width: "100%",
                                  left: 0,
                                  top: 0,
                                  right: 0,
                                  bottom: 0,
                                  color: "transparent",
                                }}
                              />
                            </div>
                            <div className="flex flex-col">
                              <h4 className="m_heading5_bold leading-5 text-elm-white" style={{color:"#5c5c5c"}}>
                              Network Gateways
                              </h4>
                              <div className="h-[1px] mt-3 mb-3 elm_white_to_transparent"></div>
                              <div className="flex items-center gap-x-4">
                                <div
                                  className="flex flex-col items-start"
                                  style={{ opacity: 1, transform: "none" }}
                                >
                                  <div className="object-position-left">
                                    <img
                                      src={Group31}
                                      width="38"
                                      height="41"
                                      decoding="async"
                                      data-nimg="1"
                                      className="h-5 w-14 svg-filter-blue"
                                      loading="lazy"
                                      style={{
                                        color: "transparent",
                                        objectPosition: "left",
                                        objectFit: "contain",
                                      }}
                                    />
                                  </div>
                                  <p className="font-bold text-[14px] w-full text-elm-white">
                                  Product design
                                  </p>
                                  <p className="font-light text-[12px] text-elm-white leading-3 w-full">
                                  Reliable IoT gateways to connect different systems together
                                  </p>
                                </div>
                                <div
                                  className="flex flex-col items-start"
                                  style={{ opacity: 1, transform: "none" }}
                                >
                                  <div className="object-position-left">
                                    <img
                                      src={Group32}
                                      width="29"
                                      height="37"
                                      decoding="async"
                                      data-nimg="1"
                                      className="h-5 w-14 svg-filter-blue"
                                      loading="lazy"
                                      style={{
                                        color: "transparent",
                                        objectPosition: "left",
                                        objectFit: "contain",
                                      }}
                                    />
                                  </div>
                                  <p className="font-bold text-[14px] w-full text-elm-white">
                                  networking
                                  </p>
                                  <p className="font-light text-[12px] text-elm-white leading-3 w-full">
                                    Circuit Breaker Standards
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>

                   

                    
                  </div>
               
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="white-line not-visible-mobile about-line" style={{backgroundColor:"#5c5c5c"}}></div>
        <section className="section_process">
          <div className="padding-global">
            <div className="container-large _90vw">
            
              <div className="padding-top padding-xhuge">
              <h4 style={{textAlign:'center'}} className="elmeasuresubheadingpr">low voltage switchgear</h4>
                <div className="w-layout-grid process-layout_component elmeasureallprod">
               
                  <div
                    className="process-layout_content-left"
                    style={{ top: "0", position: "inherit" }}
                  >
                    <div className="margin-bottom margin-medium">
                      <div className="section-number-wrapper margin-bottom margin-medium"></div>
                      <div className="margin-bottom">
                        <a
                          className="cursor-pointer"
                          
                        >
                          <div className="flex items-center justify-start gap-6 sm:mb-6">
                            <div className="relative w-[170px] h-[170px]">
                              <img
                                alt="Molded Case Circuit Breakers (MCCB)"
                                sizes="100vw"
                                // srcSet=""
                                src={image7}
                                decoding="async"
                                data-nimg="fill"
                                className="object-contain"
                                loading="lazy"
                                style={{
                                  position: "absolute",
                                  height: "100%",
                                  width: "100%",
                                  left: 0,
                                  top: 0,
                                  right: 0,
                                  bottom: 0,
                                  color: "transparent",
                                }}
                              />
                            </div>
                            <div className="flex flex-col">
                              <h4 className="m_heading5_bold leading-5 text-elm-white" style={{color:"#5c5c5c"}}>
                              Molded Case Circuit Breakers (MCCB)
                              </h4>
                              <div className="h-[1px] mt-3 mb-3 elm_white_to_transparent"></div>
                              <div className="flex items-center gap-x-4">
                                <div
                                  className="flex flex-col items-start"
                                  style={{ opacity: 1, transform: "none" }}
                                >
                                  <div className="object-position-left">
                                    <img
                                      src={Group33}
                                      width="58"
                                      height="41"
                                      decoding="async"
                                      data-nimg="1"
                                      className="h-5 w-14 svg-filter-blue"
                                      loading="lazy"
                                      style={{
                                        color: "transparent",
                                        objectPosition: "left",
                                        objectFit: "contain",
                                      }}
                                    />
                                  </div>
                                  <p className="font-bold text-[14px] w-full text-elm-white">
                                  Protection
                                  </p>
                                  {/* <p className="font-light text-[12px] text-elm-white leading-3 w-full">
                                  Smart DC metering 
                                  </p> */}
                                  <p className="font-light text-[12px] text-elm-white leading-3 w-full">
                                  Performance and safety across diverse applications
                                  </p>
                                </div>
                                <div
                                  className="flex flex-col items-start"
                                  style={{ opacity: 1, transform: "none" }}
                                >
                                  <div className="object-position-left">
                                    <img
                                      src={Group34}
                                      width="29"
                                      height="37"
                                      decoding="async"
                                      data-nimg="1"
                                      className="h-5 w-14 svg-filter-blue"
                                      loading="lazy"
                                      style={{
                                        color: "transparent",
                                        objectPosition: "left",
                                        objectFit: "contain",
                                      }}
                                    />
                                  </div>
                                  <p className="font-bold text-[14px] w-full text-elm-white">
                                  Circuit Breaker Standards
                                  </p>
                                  <p className="font-light text-[12px] text-elm-white leading-3 w-full" style={{opacity:'0'}}>
                                  Smart DC metering for renewable energy sources
                                  </p>
                                </div>
                                
                              </div>
                            </div>
                            
                          </div>
                        </a>
                      </div>
                    </div>

                   

                    
                  </div>
                  <div className="process-layout_content-right elmeasureproduct">
                    <a
                      className="cursor-pointer"
                     
                    >
                      <div className="flex items-center justify-start gap-6 sm:mb-6">
                        <div className="relative w-[170px] h-[170px]">
                          <img
                            alt="Molded Case Circuit Breakers (MCCB)"
                            sizes="100vw"
                            src={image8}
                            decoding="async"
                            data-nimg="fill"
                            className="object-contain"
                            loading="lazy"
                            style={{
                              position: "absolute",
                              height: "100%",
                              width: "100%",
                              left: 0,
                              top: 0,
                              right: 0,
                              bottom: 0,
                              color: "transparent",
                            }}
                          />
                        </div>
                        <div className="flex flex-col">
                          <h4 className="m_heading5_bold leading-5 text-elm-white" style={{color:"#5c5c5c"}}>
                          Contractor
                          </h4>
                          <div className="h-[1px] mt-3 mb-3 elm_white_to_transparent"></div>
                          <div className="flex items-center gap-x-4">
                            <div
                              className="flex flex-col items-start"
                              style={{ opacity: 1, transform: "none" }}
                            >
                              <div className="object-position-left">
                                <img
                                  src={Group35}
                                  width="38"
                                  height="41"
                                  decoding="async"
                                  data-nimg="1"
                                  className="h-5 w-14 svg-filter-blue"
                                  loading="lazy"
                                  style={{
                                    color: "transparent",
                                    objectPosition: "left",
                                    objectFit: "contain",
                                  }}
                                />
                              </div>
                              <p className="font-bold text-[14px] w-full text-elm-white">
                              Duty applications
                              </p>
                              <p className="font-light text-[12px] text-elm-white leading-3 w-full">
                              High-performance switching for any load applications
                              </p>
                            </div>
                            <div
                              className="flex flex-col items-start"
                              style={{ opacity: 1, transform: "none" }}
                            >
                              <div className="object-position-left">
                                <img
                                  src={Group36}
                                  width="29"
                                  height="37"
                                  decoding="async"
                                  data-nimg="1"
                                  className="h-5 w-14 svg-filter-blue"
                                  loading="lazy"
                                  style={{
                                    color: "transparent",
                                    objectPosition: "left",
                                    objectFit: "contain",
                                  }}
                                />
                              </div>
                              <p className="font-bold text-[14px] w-full text-elm-white">
                              Accuracy
                              </p>
                              <p className="font-light text-[12px] text-elm-white leading-3 w-full" style={{opacity:'0'}}>
                                Circuit Breaker Standards
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
                {/* <a >For more products click here</a> */}
              </div>
            </div>
       
          </div>
       
        </section>
        <div className="white-line not-visible-mobile about-line" style={{backgroundColor:"#5c5c5c"}}></div>
        <section className="section_process">
          <div className="padding-global">
            <div className="container-large _90vw">
            
              <div className="padding-top padding-xhuge">
              <h4 style={{textAlign:'center'}} className="elmeasuresubheadingpr">Source Changeover Switches</h4>
                <div className="w-layout-grid process-layout_component elmeasureallprod">
               
                  <div
                    className="process-layout_content-left"
                    style={{ top: "0", position: "inherit" }}
                  >
                    <div className="margin-bottom margin-medium">
                      <div className="section-number-wrapper margin-bottom margin-medium"></div>
                      <div className="margin-bottom">
                        <a
                          className="cursor-pointer"
                         
                        >
                          <div className="flex items-center justify-start gap-6 sm:mb-6">
                            <div className="relative w-[170px] h-[170px]">
                              <img
                                alt="Molded Case Circuit Breakers (MCCB)"
                                sizes="100vw"
                                // srcSet=""
                                src={image9}
                                decoding="async"
                                data-nimg="fill"
                                className="object-contain"
                                loading="lazy"
                                style={{
                                  position: "absolute",
                                  height: "100%",
                                  width: "100%",
                                  left: 0,
                                  top: 0,
                                  right: 0,
                                  bottom: 0,
                                  color: "transparent",
                                }}
                              />
                            </div>
                            <div className="flex flex-col">
                              <h4 className="m_heading5_bold leading-5 text-elm-white" style={{color:"#5c5c5c"}}>
                              Automatic Transfer Switches (ATS)
                              </h4>
                              <div className="h-[1px] mt-3 mb-3 elm_white_to_transparent"></div>
                              <div className="flex items-center gap-x-4">
                                <div
                                  className="flex flex-col items-start"
                                  style={{ opacity: 1, transform: "none" }}
                                >
                                  <div className="object-position-left">
                                    <img
                                      src={Group37}
                                      width="58"
                                      height="41"
                                      decoding="async"
                                      data-nimg="1"
                                      className="h-5 w-14 svg-filter-blue"
                                      loading="lazy"
                                      style={{
                                        color: "transparent",
                                        objectPosition: "left",
                                        objectFit: "contain",
                                      }}
                                    />
                                  </div>
                                  <p className="font-bold text-[14px] w-full text-elm-white">
                                  Controller
                                  </p>
                                  {/* <p className="font-light text-[12px] text-elm-white leading-3 w-full">
                                  Smart DC metering 
                                  </p> */}
                                  <p className="font-light text-[12px] text-elm-white leading-3 w-full">
                                  Protect your mission-critical facilities from power uncertainties
                                  </p>
                                </div>
                                <div
                                  className="flex flex-col items-start"
                                  style={{ opacity: 1, transform: "none" }}
                                >
                                  <div className="object-position-left">
                                    <img
                                      src={Group37}
                                      width="29"
                                      height="37"
                                      decoding="async"
                                      data-nimg="1"
                                      className="h-5 w-14 svg-filter-blue"
                                      loading="lazy"
                                      style={{
                                        color: "transparent",
                                        objectPosition: "left",
                                        objectFit: "contain",
                                      }}
                                    />
                                  </div>
                                  <p className="font-bold text-[14px] w-full text-elm-white">
                                  TSE standards
                                  </p>
                                  <p className="font-light text-[12px] text-elm-white leading-3 w-full" style={{opacity:'0'}}>
                                  Smart DC metering for renewable energy sources
                                  </p>
                                </div>
                                
                              </div>
                            </div>
                            
                          </div>
                        </a>
                      </div>
                    </div>

                   

                    
                  </div>
                  <div className="process-layout_content-right elmeasureproduct">
                    <a
                      className="cursor-pointer"
                   
                    >
                      <div className="flex items-center justify-start gap-6 sm:mb-6">
                        <div className="relative w-[170px] h-[170px]">
                          <img
                            alt="Molded Case Circuit Breakers (MCCB)"
                            sizes="100vw"
                            src={image10}
                            decoding="async"
                            data-nimg="fill"
                            className="object-contain"
                            loading="lazy"
                            style={{
                              position: "absolute",
                              height: "100%",
                              width: "100%",
                              left: 0,
                              top: 0,
                              right: 0,
                              bottom: 0,
                              color: "transparent",
                            }}
                          />
                        </div>
                        <div className="flex flex-col">
                          <h4 className="m_heading5_bold leading-5 text-elm-white" style={{color:"#5c5c5c"}}>
                          Manual Transfer Switches (MTS)
                          </h4>
                          <div className="h-[1px] mt-3 mb-3 elm_white_to_transparent"></div>
                          <div className="flex items-center gap-x-4">
                            <div
                              className="flex flex-col items-start"
                              style={{ opacity: 1, transform: "none" }}
                            >
                              <div className="object-position-left">
                                <img
                                  src={Group39}
                                  width="38"
                                  height="41"
                                  decoding="async"
                                  data-nimg="1"
                                  className="h-5 w-14 svg-filter-blue"
                                  loading="lazy"
                                  style={{
                                    color: "transparent",
                                    objectPosition: "left",
                                    objectFit: "contain",
                                  }}
                                />
                              </div>
                              <p className="font-bold text-[14px] w-full text-elm-white">
                              industrial design
                              </p>
                              <p className="font-light text-[12px] text-elm-white leading-3 w-full">
                              Highly durable,easy to use and uncompromisingly safe
                              </p>
                            </div>
                            <div
                              className="flex flex-col items-start"
                              style={{ opacity: 1, transform: "none" }}
                            >
                              <div className="object-position-left">
                                <img
                                  src={Group40}
                                  width="29"
                                  height="37"
                                  decoding="async"
                                  data-nimg="1"
                                  className="h-5 w-14 svg-filter-blue"
                                  loading="lazy"
                                  style={{
                                    color: "transparent",
                                    objectPosition: "left",
                                    objectFit: "contain",
                                  }}
                                />
                              </div>
                              <p className="font-bold text-[14px] w-full text-elm-white">
                              of applications
                              </p>
                              <p className="font-light text-[12px] text-elm-white leading-3 w-full" style={{opacity:'0'}}>
                                Circuit Breaker Standards
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
                {/* <a >For more products click here</a> */}
              </div>
            </div>
       
          </div>
       
        </section>
        <div className="white-line not-visible-mobile about-line" style={{backgroundColor:"#5c5c5c"}}></div>
        <section className="section_process">
          <div className="padding-global">
            <div className="container-large _90vw">
            
              <div className="padding-top padding-xhuge">
              <h4 style={{textAlign:'center'}} className="elmeasuresubheadingpr" >Source Changeover Switches</h4>
                <div className="w-layout-grid process-layout_component elmeasureallprod">
               
                  <div
                    className="process-layout_content-left"
                    style={{ top: "0", position: "inherit" }}
                  >
                    <div className="margin-bottom margin-medium">
                      <div className="section-number-wrapper margin-bottom margin-medium"></div>
                      <div className="margin-bottom">
                        <a
                          className="cursor-pointer"
                         
                        >
                          <div className="flex items-center justify-start gap-6 sm:mb-6">
                            <div className="relative w-[170px] h-[170px]">
                              <img
                                alt="Molded Case Circuit Breakers (MCCB)"
                                sizes="100vw"
                                // srcSet=""
                                src={image11}
                                decoding="async"
                                data-nimg="fill"
                                className="object-contain"
                                loading="lazy"
                                style={{
                                  position: "absolute",
                                  height: "100%",
                                  width: "100%",
                                  left: 0,
                                  top: 0,
                                  right: 0,
                                  bottom: 0,
                                  color: "transparent",
                                }}
                              />
                            </div>
                            <div className="flex flex-col">
                              <h4 className="m_heading5_bold leading-5 text-elm-white" style={{color:"#5c5c5c"}}>
                              Transducers and isolators
                              </h4>
                              <div className="h-[1px] mt-3 mb-3 elm_white_to_transparent"></div>
                              <div className="flex items-center gap-x-4">
                                <div
                                  className="flex flex-col items-start"
                                  style={{ opacity: 1, transform: "none" }}
                                >
                                  <div className="object-position-left">
                                    <img
                                      src={Group41}
                                      width="58"
                                      height="41"
                                      decoding="async"
                                      data-nimg="1"
                                      className="h-5 w-14 svg-filter-blue"
                                      loading="lazy"
                                      style={{
                                        color: "transparent",
                                        objectPosition: "left",
                                        objectFit: "contain",
                                      }}
                                    />
                                  </div>
                                  <p className="font-bold text-[14px] w-full text-elm-white">
                                  isolation
                                  </p>
                                  {/* <p className="font-light text-[12px] text-elm-white leading-3 w-full">
                                  Smart DC metering 
                                  </p> */}
                                  <p className="font-light text-[12px] text-elm-white leading-3 w-full">
                                  A precise sensing solution for automation/SCADA systems
                                  </p>
                                </div>
                                <div
                                  className="flex flex-col items-start"
                                  style={{ opacity: 1, transform: "none" }}
                                >
                                  <div className="object-position-left">
                                    <img
                                      src={Group42}
                                      width="29"
                                      height="37"
                                      decoding="async"
                                      data-nimg="1"
                                      className="h-5 w-14 svg-filter-blue"
                                      loading="lazy"
                                      style={{
                                        color: "transparent",
                                        objectPosition: "left",
                                        objectFit: "contain",
                                      }}
                                    />
                                  </div>
                                  <p className="font-bold text-[14px] w-full text-elm-white">
                                  Measurements
                                  </p>
                                  <p className="font-light text-[12px] text-elm-white leading-3 w-full" style={{opacity:'0'}}>
                                  Smart DC metering for renewable energy sources
                                  </p>
                                </div>
                                
                              </div>
                            </div>
                            
                          </div>
                        </a>
                      </div>
                    </div>

                   

                    
                  </div>
                
                </div>
                <p>
                <div className='downlaod-solapnel' >
      
      <a className='havellviewmore' href={Havells} target='_blank' style={{marginTop:'3rem'}}> 
      Download Brochure
</a>

    </div>
                  <a href={Havells}></a><svg width="16" height="8" viewBox="0 0 16 8" fill="none" xmlns="http://www.w3.org/2000/svg">
</svg>
</p>

              </div>
            </div>
       
          </div>
       
        </section>
      </div>
    </div>
  );
}

export default Elmeasure;
