import React from "react";
import panelp from "../../assets/panelp.png";
import "./panel.css";
import padata from "./pdata";
import { Link } from 'react-router-dom';

import pcc from "../../assets/pcc.png";
import Pcards from "./Pcards";
import plc from "../../assets/plc.png";
import mcc2 from "../../assets/mcc2.png";
import db2 from "../../assets/db2.png";
import pccl from "../../assets/pccl.png";
import imcc from "../../assets/imcc.png";
import { useNavigate } from "react-router-dom";
import Fade from 'react-reveal/Fade';
function Panel() {
  const navigate = useNavigate();
  function handleClick(event) {
    event.preventDefault();
    const sectionId = event.target.getAttribute('data-section-id');
    const section = document.getElementById(sectionId);
    section.scrollIntoView({ behavior: 'smooth' });
  }

  const productItems = [
    { title: "pcc (power control center)" },
    { title: "MOTOR CONTROL CENTER (MCC)" },
    { title: "PROGRAMMABLE LOGIC CONTROLLER (PLC)" },
    { title: "distribution board"},
    { title: "imcc (intelligent motor control center)"},
    { title: "vfd (variable frequency drive)" },
  ];

  const panelCardItems = [
    { img: pcc, title: "Power control center (pcc)"},
    { img: plc, title: "Programmable logic controller (plc)" },
    { img: mcc2, title: "Motor control center (mcc)" },
    { img: db2, title: "Distribution Board" },
    { img: imcc, title: "Intelligent Motor Control Center (imcc)" },
    { img: pccl, title: "Vfd (variable frequency drive)" },
  ];
  
  
  return (

    <>
     <div className="panelborares">
        <section className="section_process">
          <div className="padding-global">
            <div className="container-large _90vw">
              <div className="padding-top padding-xhuge">
                <div className="w-layout-grid process-layout_component">
                  <div className="process-layout_content-left" style={{top:'0',position:'inherit'}}>
                    <div className="margin-bottom margin-medium">
                      <div className="section-number-wrapper margin-bottom margin-medium">
                      
                      </div>
                      <div className="margin-bottom">
                      <Fade bottom>
                        <h2
                          text-split=""
                          words-slide-from-right=""
                          className="heading-style-h2 _8vw-title"
                        >
                          Panel boards
                        </h2>
                        </Fade>
                      </div>
                    </div>
                    <Fade bottom>
                    <p
                      style={{ opacity: "1" }}
                      className="text-size-medium max-width-medium text-weight-light"
                    >
                      Panelboards are used to safely distribute electricity throughout
            commercial and industrial facilities. A panelboard is a component of
            an electrical distribution system which divides an electrical power
            feed into branch circuits, while providing a protective circuit
            breaker or fuse for each circuit, in a common enclosure. A
            panelboard services to protect branch circuits from overloads and
            short circuits. Panelboards are designed to meet UL 67 and NEMA
            Standard PB1.
                    </p>
                </Fade>
         
          <div>
          <div className="mainaccp">
  <p style={{ color: '#005E8C' }}>PRODUCT RANGE</p>
  {productItems.map((product, index) => (
    <div className="accordionItem-0-3-239" onClick={() => navigate(product.link)} key={index}>
      <button className="accordionButton-0-3-240 selected">
        {product.title}
        <svg
  className="dropdownIcon-0-3-241"
  width="6"
  height="10"
  viewBox="0 0 6 10"
  fill="none"
>
  <path
    d="M4.99995 5L0 0L-0.699995 0.7L3.59995 5L-0.699995 9.3L0 10L4.99995 5Z"
    fill="currentColor"
  ></path>
</svg>

      </button>
      <div className="optionsList-0-3-242">
        <div className="optionsListInner-0-3-243"></div>
      </div>
    </div>
  ))}
</div>

            
          </div>
        
                    <div className="button-group margin-top margin-large">
                   
                    </div>
                  </div>
                  <div className="process-layout_content-right">
                   
                    <div
                      className="process-layout_content-item content-item-2"
                      style={{ color: "white",border:'none' }}
                    >
                      <div className="margin-bottom margin-xsmall">
                      <img src={panelp} className="insideproductim" ></img>
                      </div>
                      <div className="margin-bottom margin-xsmall">
                        
                      </div>
                     
                    </div>
                 
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="panelcardheonew">
        <section className="panelcardsmainsect">
        <section className="panelcards-01">
  {panelCardItems.map((card, index) => (
    <a href="./tfit/Tfit" key={index}>
              <Link to={card.route} key={index}>

      <article className="panelcard">
        <figure className="panelcard-img">
          <img src={card.img} alt={card.title} />
        </figure>
        <div className="panelcard-body">
          <h2 className="panelcard-title" style={{ textDecoration: 'none', borderBottom: '0' }}>
            {card.title}
          </h2>
        </div>
      </article>
      </Link>
    </a>
  ))}
</section>

        </section>
        </div>
        </div>
      {/* </div> */}
    </>
  );
}

export default Panel;
