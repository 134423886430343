import React from "react";
import "./solar.css";
import el from "../../../assets/el.png";
import unnamed from "../../../assets/unnamed.png";
import solar1 from "../../../assets/solar1.png";
import solar2 from "../../../assets/solar2.png";
import solar3 from "../../../assets/solar3.png";
import solar4 from "../../../assets/solar4.png";
import solar5 from "../../../assets/solar5.png";
import sdata from "./Solardata";
import eso from "../../../assets/pdfs/eso.pdf"
function Solar() {
  const SdisplayData = sdata.map((info) => {
    return (
      <tr>
        <td>{info.name}</td>
        <td>{info.city}</td>
      </tr>
    );
  });

  const PdisplayData = sdata.map((info) => {
    return (
      <tr>
        <td>{info.name}</td>
        <td>{info.city}</td>
      </tr>
    );
  });
  return (
    <>
      <section className="section_process">
        <div className="padding-global">
          <div className="container-large _90vw">
            <div className="padding-top padding-xhuge">
              <div className="w-layout-grid process-layout_component">
                <div
                  className="process-layout_content-left"
                  style={{ top: "0%", position: "inherit" }}
                >
                  <div className="margin-bottom margin-medium">
                    <div className="section-number-wrapper margin-bottom margin-medium"></div>
                    <div className="margin-bottom">
                      <h2
                        text-split=""
                        words-slide-from-right=""
                        className="heading-style-h2 _8vw-title"
                        // style={{ fontSize: "2.8vw" }}
                      >
                        <img src={el} className="elmexlomps"></img>
                        Elmex
                      </h2>
                      <p className="elmexlompsp">SOLAR PRODUCTS</p>
                    </div>
                  </div>

                  {/* <div className="terminalaccordian"> */}
                  {/* <p style={{color:'#005E8C'}}>PRODUCT RANGE</p> */}
                  <div className="mainaccp" >
                    <p style={{ color: "#005E8C" }}>PRODUCT RANGE</p>
                    <div
                      className="accordionItem-0-3-239"
                      // onClick={() => navigate("/elmex/Elmex/SolarProducts")}
                    >
                      <button className="accordionButton-0-3-240 selected">
                        straight connectors
                        <svg
  className="dropdownIcon-0-3-241"
  width="6"
  height="10"
  viewBox="0 0 6 10"
  fill="none"
>
  <path
    d="M4.99995 5L0 0L-0.699995 0.7L3.59995 5L-0.699995 9.3L0 10L4.99995 5Z"
    fill="currentColor"
  ></path>
</svg>

                      </button>
                      <div className="optionsList-0-3-242">
                        <div className="optionsListInner-0-3-243"></div>
                      </div>
                    </div>
                    <div
                      className="accordionItem-0-3-239"
                      // onClick={() => navigate("/elmex/Elmex/TerminalBlocks")}
                    >
                      <button className="accordionButton-0-3-240 selected">
                        panel connectors
                        <svg
  className="dropdownIcon-0-3-241"
  width="6"
  height="10"
  viewBox="0 0 6 10"
  fill="none"
>
  <path
    d="M4.99995 5L0 0L-0.699995 0.7L3.59995 5L-0.699995 9.3L0 10L4.99995 5Z"
    fill="currentColor"
  ></path>
</svg>
                      </button>
                      <div className="optionsList-0-3-242">
                        <div className="optionsListInner-0-3-243"></div>
                      </div>
                    </div>
                    <div
                      className="accordionItem-0-3-239"
                      // onClick={() => navigate("/elmex/Elmex/TerminalBlocks")}
                    >
                      <button className="accordionButton-0-3-240 selected">
                        branch connectors
                        <svg
  className="dropdownIcon-0-3-241"
  width="6"
  height="10"
  viewBox="0 0 6 10"
  fill="none"
>
  <path
    d="M4.99995 5L0 0L-0.699995 0.7L3.59995 5L-0.699995 9.3L0 10L4.99995 5Z"
    fill="currentColor"
  ></path>
</svg>
                      </button>
                      <div className="optionsList-0-3-242">
                        <div className="optionsListInner-0-3-243"></div>
                      </div>
                    </div>{" "}
                    <div
                      className="accordionItem-0-3-239"
                      // onClick={() => navigate("/elmex/Elmex/TerminalBlocks")}
                    >
                      <button className="accordionButton-0-3-240 selected">
                        2-rail junction boxes
                     <svg
  className="dropdownIcon-0-3-241"
  width="6"
  height="10"
  viewBox="0 0 6 10"
  fill="none"
>
  <path
    d="M4.99995 5L0 0L-0.699995 0.7L3.59995 5L-0.699995 9.3L0 10L4.99995 5Z"
    fill="currentColor"
  ></path>
</svg>

                      </button>
                      <div className="optionsList-0-3-242">
                        <div className="optionsListInner-0-3-243"></div>
                      </div>
                    </div>{" "}
                    <div
                      className="accordionItem-0-3-239"
                      // onClick={() => navigate("/elmex/Elmex/TerminalBlocks")}
                    >
                      <button className="accordionButton-0-3-240 selected">
                        4-rail junctions boxes
                     <svg
  className="dropdownIcon-0-3-241"
  width="6"
  height="10"
  viewBox="0 0 6 10"
  fill="none"
>
  <path
    d="M4.99995 5L0 0L-0.699995 0.7L3.59995 5L-0.699995 9.3L0 10L4.99995 5Z"
    fill="currentColor"
  ></path>
</svg>

                      </button>
                      <div className="optionsList-0-3-242">
                        <div className="optionsListInner-0-3-243"></div>
                      </div>
                    </div>{" "}
                    <div
                      className="accordionItem-0-3-239"
                      // onClick={() => navigate("/elmex/Elmex/TerminalBlocks")}
                    >
                      <button className="accordionButton-0-3-240 selected">
                        terminal blocks for pv applications
                     <svg
  className="dropdownIcon-0-3-241"
  width="6"
  height="10"
  viewBox="0 0 6 10"
  fill="none"
>
  <path
    d="M4.99995 5L0 0L-0.699995 0.7L3.59995 5L-0.699995 9.3L0 10L4.99995 5Z"
    fill="currentColor"
  ></path>
</svg>

                      </button>
                      <div className="optionsList-0-3-242">
                        <div className="optionsListInner-0-3-243"></div>
                      </div>
                    </div>{" "}
                    <div
                      className="accordionItem-0-3-239"
                      // onClick={() => navigate("/elmex/Elmex/TerminalBlocks")}
                    >
                      <button className="accordionButton-0-3-240 selected">
                        3-rail junction boxes
                     <svg
  className="dropdownIcon-0-3-241"
  width="6"
  height="10"
  viewBox="0 0 6 10"
  fill="none"
>
  <path
    d="M4.99995 5L0 0L-0.699995 0.7L3.59995 5L-0.699995 9.3L0 10L4.99995 5Z"
    fill="currentColor"
  ></path>
</svg>

                      </button>
                      <div className="optionsList-0-3-242">
                        <div className="optionsListInner-0-3-243"></div>
                      </div>
                    </div>{" "}
                    <div
                      className="accordionItem-0-3-239"
                      // onClick={() => navigate("/elmex/Elmex/TerminalBlocks")}
                    >
                      <button className="accordionButton-0-3-240 selected">
                        fuse terminal blocks-1000v
                     <svg
  className="dropdownIcon-0-3-241"
  width="6"
  height="10"
  viewBox="0 0 6 10"
  fill="none"
>
  <path
    d="M4.99995 5L0 0L-0.699995 0.7L3.59995 5L-0.699995 9.3L0 10L4.99995 5Z"
    fill="currentColor"
  ></path>
</svg>

                      </button>
                      <div className="optionsList-0-3-242">
                        <div className="optionsListInner-0-3-243"></div>
                      </div>
                    </div>{" "}
                    <div
                      className="accordionItem-0-3-239"
                      // onClick={() => navigate("/elmex/Elmex/TerminalBlocks")}
                    >
                      <button className="accordionButton-0-3-240 selected">
                        fuse terminal blocks-1500v
                     <svg
  className="dropdownIcon-0-3-241"
  width="6"
  height="10"
  viewBox="0 0 6 10"
  fill="none"
>
  <path
    d="M4.99995 5L0 0L-0.699995 0.7L3.59995 5L-0.699995 9.3L0 10L4.99995 5Z"
    fill="currentColor"
  ></path>
</svg>

                      </button>
                      <div className="optionsList-0-3-242">
                        <div className="optionsListInner-0-3-243"></div>
                      </div>
                    </div>{" "}
                    <div
                      className="accordionItem-0-3-239"
                      // onClick={() => navigate("/elmex/Elmex/TerminalBlocks")}
                    >
                      <button className="accordionButton-0-3-240 selected">
                        over moulded wire harnesses
                     <svg
  className="dropdownIcon-0-3-241"
  width="6"
  height="10"
  viewBox="0 0 6 10"
  fill="none"
>
  <path
    d="M4.99995 5L0 0L-0.699995 0.7L3.59995 5L-0.699995 9.3L0 10L4.99995 5Z"
    fill="currentColor"
  ></path>
</svg>

                      </button>
                      <div className="optionsList-0-3-242">
                        <div className="optionsListInner-0-3-243"></div>
                      </div>
                    </div>{" "}
                    <div
                      className="accordionItem-0-3-239"
                      // onClick={() => navigate("/elmex/Elmex/TerminalBlocks")}
                    >
                      <button className="accordionButton-0-3-240 selected">
                        branch inline fuse connectors
                     <svg
  className="dropdownIcon-0-3-241"
  width="6"
  height="10"
  viewBox="0 0 6 10"
  fill="none"
>
  <path
    d="M4.99995 5L0 0L-0.699995 0.7L3.59995 5L-0.699995 9.3L0 10L4.99995 5Z"
    fill="currentColor"
  ></path>
</svg>

                      </button>
                      <div className="optionsList-0-3-242">
                        <div className="optionsListInner-0-3-243"></div>
                      </div>
                    </div>{" "}
                    <div
                      className="accordionItem-0-3-239"
                      // onClick={() => navigate("/elmex/Elmex/TerminalBlocks")}
                    >
                      <button className="accordionButton-0-3-240 selected">
                        straight inline fuse conectors-1000v
                     <svg
  className="dropdownIcon-0-3-241"
  width="6"
  height="10"
  viewBox="0 0 6 10"
  fill="none"
>
  <path
    d="M4.99995 5L0 0L-0.699995 0.7L3.59995 5L-0.699995 9.3L0 10L4.99995 5Z"
    fill="currentColor"
  ></path>
</svg>

                      </button>
                      <div className="optionsList-0-3-242">
                        <div className="optionsListInner-0-3-243"></div>
                      </div>
                    </div>{" "}
                    <div
                      className="accordionItem-0-3-239"
                      // onClick={() => navigate("/elmex/Elmex/TerminalBlocks")}
                    >
                      <button className="accordionButton-0-3-240 selected">
                        TERMINAL BLOCKS
                     <svg
  className="dropdownIcon-0-3-241"
  width="6"
  height="10"
  viewBox="0 0 6 10"
  fill="none"
>
  <path
    d="M4.99995 5L0 0L-0.699995 0.7L3.59995 5L-0.699995 9.3L0 10L4.99995 5Z"
    fill="currentColor"
  ></path>
</svg>

                      </button>
                      <div className="optionsList-0-3-242">
                        <div className="optionsListInner-0-3-243"></div>
                      </div>
                    </div>
                    <div
                      className="accordionItem-0-3-239"
                      // onClick={() => navigate("/elmex/Elmex/TerminalBlocks")}
                    >
                      <button className="accordionButton-0-3-240 selected">
                        over moulded wire harnesses with fuse-1000v
                     <svg
  className="dropdownIcon-0-3-241"
  width="6"
  height="10"
  viewBox="0 0 6 10"
  fill="none"
>
  <path
    d="M4.99995 5L0 0L-0.699995 0.7L3.59995 5L-0.699995 9.3L0 10L4.99995 5Z"
    fill="currentColor"
  ></path>
</svg>

                      </button>
                      <div className="optionsList-0-3-242">
                        <div className="optionsListInner-0-3-243"></div>
                      </div>
                    </div>{" "}
                    <div
                      className="accordionItem-0-3-239"
                      // onClick={() => navigate("/elmex/Elmex/TerminalBlocks")}
                    >
                      <button className="accordionButton-0-3-240 selected">
                        over moulded wire harnesses with fuse-1500v
                     <svg
  className="dropdownIcon-0-3-241"
  width="6"
  height="10"
  viewBox="0 0 6 10"
  fill="none"
>
  <path
    d="M4.99995 5L0 0L-0.699995 0.7L3.59995 5L-0.699995 9.3L0 10L4.99995 5Z"
    fill="currentColor"
  ></path>
</svg>

                      </button>
                      <div className="optionsList-0-3-242">
                        <div className="optionsListInner-0-3-243"></div>
                      </div>
                    </div>{" "}
                    <div
                      className="accordionItem-0-3-239"
                      // onClick={() => navigate("/elmex/Elmex/TerminalBlocks")}
                    >
                      <button className="accordionButton-0-3-240 selected">
                        branch line fuse connectors-1500v
                     <svg
  className="dropdownIcon-0-3-241"
  width="6"
  height="10"
  viewBox="0 0 6 10"
  fill="none"
>
  <path
    d="M4.99995 5L0 0L-0.699995 0.7L3.59995 5L-0.699995 9.3L0 10L4.99995 5Z"
    fill="currentColor"
  ></path>
</svg>

                      </button>
                      <div className="optionsList-0-3-242">
                        <div className="optionsListInner-0-3-243"></div>
                      </div>
                    </div>{" "}
                    <div
                      className="accordionItem-0-3-239"
                      // onClick={() => navigate("/elmex/Elmex/TerminalBlocks")}
                    >
                      <button className="accordionButton-0-3-240 selected">
                        straight inline fuse connectors-1500v
                     <svg
  className="dropdownIcon-0-3-241"
  width="6"
  height="10"
  viewBox="0 0 6 10"
  fill="none"
>
  <path
    d="M4.99995 5L0 0L-0.699995 0.7L3.59995 5L-0.699995 9.3L0 10L4.99995 5Z"
    fill="currentColor"
  ></path>
</svg>

                      </button>
                      <div className="optionsList-0-3-242">
                        <div className="optionsListInner-0-3-243"></div>
                      </div>
                    </div>{" "}
                  </div>
                  <div className="button-group margin-top margin-large"></div>
                </div>
                <div className="process-layout_content-right">
                  <div
                    className="process-layout_content-item content-item-2"
                    style={{ color: "white", border: "none" }}
                  >
                    <div className="margin-bottom margin-xsmall">
                      <img src={unnamed} className="insideproductim"></img>
                    </div>
                    <div className="margin-bottom margin-xsmall"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <section className="asolarproducts-deailsa" id="asolarone">
          <h1>straight connectors </h1>
          <div class="image-container">
            <img src={solar1} alt="Solar Product 1"></img>
          </div>
          {/* <img src={solar1}></img> */}
          {/* <br></br> */}
          <p>
            <span>elmex</span> PV Solar Straight connectors EMPV4 and EMPV4N
            with plug and socket design are constructed using flame retardant
            engineering thermoplastic suitable for exposure to UV rays and for
            application in PV power generation system. These connectors
            incorporate a flexible water tight sealing conforming to IP 68 and
            are supplied as ‘male (plug)’ and ‘female (socket)’ types to
            minimize the chance of wrong connections. For proper assembly of
            individual male and female connectors and for proper functioning of
            the mated pair, it is necessary that solar cables used are TUV
            certified as per standard 2Pfg 1169/EN 50618.<span>elmex </span>{" "}
            straight connectors are certified for PV solar cables 2.5, 4.0, 6.0
            mm² size, double insulated (Insulation plus black) and UV protection
            (as UV rays tend to damage the connection).
          </p>
          <div>
            <div className="image-container">
              <table className="table stable-striped">
                <thead>
                  <tr>
                    <th>Description</th>
                    <th>specification</th>
                  </tr>
                </thead>
                <tbody>{SdisplayData}</tbody>
              </table>
            </div>
          </div>
        </section>
        <section className="asolarproducts-deailsa" id="asolarone">
          <h1>panel connectors </h1>
          <div class="image-container">
            <img src={solar2} alt="Solar Product 1"></img>
          </div>
          {/* <img src={solar1}></img> */}
          <p>
            <span>elmex</span>PV Solar Panel connectors EMPVPCM4N and EMPVPCF4N are applicable for panel mounting connection. They are constructed using flame retardant engineering thermoplastic suitable for exposure to UV rays and for application in PV power generation system. elmex panel connectors are designed for use in connection for photovoltaic devices like DC Distribution Block , Inverters , String Combiner Box , etc. These connectors are provided with hexagonal nut for fixing and tightening it on a mounting surface. A silicon rubber o- ring is provided between panel connector surface and the wall of the photovoltaic enclosure ensuring protection against ingress of water & dust. elmex panel connectors have mating compatibility not only with elmex straight connectors but also with straight connectors of leading international makes having similar construction.
          </p>
          <div>
            <div className="image-container">
              <table className="table stable-striped">
                <thead>
                  <tr>
                    <th>Description</th>
                    <th>specification</th>
                  </tr>
                </thead>
                <tbody>{SdisplayData}</tbody>
              </table>
            </div>
          </div>
        </section>
        <section className="asolarproducts-deailsa" id="asolarone">
          <h1>branch connectors </h1>
          <div class="image-container">
            <img src={solar3} alt="Solar Product 1"></img>
          </div>
          {/* <img src={solar1}></img> */}
          <p>
            <span>elmex</span> PV Solar Branch connectors EBPV4M-N and EBPV4F-N are applicable for parallel connection with PV straight male or female connectors depending on on-site application. Branch connectors have 3 branches, 2 for inputs either male or female and 1 for output either male or female. These connectors are constructed using flame retardant thermoplastic suitable for exposure to UV rays and for application in PV power generation system. When mated, they can be disconnected with the use of elmex make open end spanner.elmex branch connectors have mating compatibility not only with elmex straight connectors but also with straight connectors of leading International makes having similar construction.

          </p>
          <div>
            <div className="image-container">
              <table className="table stable-striped">
                <thead>
                  <tr>
                    <th>Description</th>
                    <th>specification</th>
                  </tr>
                </thead>
                <tbody>{SdisplayData}</tbody>
              </table>
            </div>
          </div>
        </section>{" "}
        <section className="asolarproducts-deailsa" id="asolarone">
          <h1>2-Rail Junction Boxes </h1>
          <div class="image-container">
            <img src={solar4} alt="Solar Product 1"></img>
          </div>
          {/* <img src={solar1}></img> */}
          <p>
            <span>elmex</span>PV Solar 2 - Rail Junction Boxes EPVJB3 and EPVJB6 are suitable for Solar Street Light Low Wattage (less than 50W) Panels. They are designed with sliding snap fit locking arrangement, available with 2 - in and 1 - out cable connections.
          </p>
          <div>
            <div className="image-container">
              <table className="table stable-striped">
                <thead>
                  <tr>
                    <th>Description</th>
                    <th>specification</th>
                  </tr>
                </thead>
                <tbody>{SdisplayData}</tbody>
              </table>
            </div>
          </div>
        </section>{" "}
        <section className="asolarproducts-deailsa" id="asolarone">
          <h1>4-Rail Junction Box </h1>
          <div class="image-container">
            <img src={solar1} alt="Solar Product 1"></img>
          </div>
          {/* <img src={solar1}></img> */}
          <p>
            <span>elmex</span>  PV Solar 4 - Rail Junction Box EPVJB-F is suitable for electrical connection from PV Crystalline module as a solution for easy and reliable interconnection from PV module to DC / AC converters. It has simple and cost effective assembly designed with snap fit locking arrangement with IP 65 protection requirement for PV industry. The Junction Box is provided with two cables each of 1-meter length, with elmex make straight male and female EMPV4 / EMPV4N connector for simple on-site wiring. PV Junction Box employs fast switching Schottky Diodes with a peak reverse voltage rating of 45 V. The diodes are soldered with PV rails using lead-free, high melting point solder alloy. PV ribbons from PV module can be connected with junction box rails by soldering process.
          </p>
          <div>
            <div className="image-container">
              <table className="table stable-striped">
                <thead>
                  <tr>
                    <th>Description</th>
                    <th>specification</th>
                  </tr>
                </thead>
                <tbody>{SdisplayData}</tbody>
              </table>
            </div>
          </div>
        </section>
        <section className="asolarproducts-deailsa" id="asolarone">
          <h1>Terminal Blocks for PV Applications</h1>
          <div class="image-container">
            <img src={solar1} alt="Solar Product 1"></img>
          </div>
          {/* <img src={solar1}></img> */}
          <p>
            <span>elmex</span>  PV Solar 4 - Rail Junction Box EPVJB-F is suitable for electrical connection from PV Crystalline module as a solution for easy and reliable interconnection from PV module to DC / AC converters. It has simple and cost effective assembly designed with snap fit locking arrangement with IP 65 protection requirement for PV industry. The Junction Box is provided with two cables each of 1-meter length, with elmex make straight male and female EMPV4 / EMPV4N connector for simple on-site wiring. PV Junction Box employs fast switching Schottky Diodes with a peak reverse voltage rating of 45 V. The diodes are soldered with PV rails using lead-free, high melting point solder alloy. PV ribbons from PV module can be connected with junction box rails by soldering process.



          </p>
          <div>
            <div className="image-container">
              <table className="table stable-striped">
                <thead>
                  <tr>
                    <th>Description</th>
                    <th>specification</th>
                  </tr>
                </thead>
                <tbody>{SdisplayData}</tbody>
              </table>
            </div>
          </div>
        </section>
        <section className="asolarproducts-deailsa" id="asolarone">
          <h1>3-Rail Junction Box</h1>
          <div class="image-container">
            <img src={solar1} alt="Solar Product 1"></img>
          </div>
          {/* <img src={solar1}></img> */}
          <p>
            <span>elmex</span>  PV Solar 3 - Rail Junction Box EPVJB-T is suitable for electrical connection from PV Crystalline module as a solution for easy and reliable interconnection from PV module to DC/AC converters. It has simple and cost effective assembly designed with snap fit locking arrangement with IP 65 protection requirement for PV industry. The Junction Box is provided with two cables each of 1 meter length, with elmex make straight male and female EMPV4 / EMPV4N connectors for simple on-site wiring.





          </p>
          <div>
            <div className="image-container">
              <table className="table stable-striped">
                <thead>
                  <tr>
                    <th>Description</th>
                    <th>specification</th>
                  </tr>
                </thead>
                <tbody>{SdisplayData}</tbody>
              </table>
            </div>
          </div>
          <div className='downlaod-solapnel' >
      
      <a className='havellviewmore' href={eso} target='_blank' style={{marginTop:'1rem',cursor:'pointer'}}> 
      Download Brochure
</a>

    </div>
        </section>
      </section>
   
  
    </>
  );
}

export default Solar;
