import React from "react";
import "./product.css";
import productfit from "../assets/productfit.png";
import productc from "../assets/productc.png";
import producte from "../assets/producte.png";
import productlt from "../assets/productlt.png";
import productpb from "../assets/productpb.png";
import productri from "../assets/productri.png";
import havell from "../assets/havella.png";
import elmeasure from "../assets/elmeasure.png"
import kei from "../assets/kei.png";
import gvksolar from "../assets/gvksolar.png"
import orbit from "../assets/orbit.png";
import { useNavigate } from "react-router-dom";
import { useViewportScroll, motion } from "framer-motion";
function Product() {
  const navigate = useNavigate();

  const scrollToTop = () => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  };

  return (
    <>
    <div className="mainpppageb">

      <section className="section-0-3-224 Layout-section-0-3-33 gutter grid">
        <div className="filters-0-3-225">
        
          <div>
            <div>
            <p className="title-0-3-226" style={{color:'#005E8c'}}>PRODUCTS</p>
              <div className="accordionItem-0-3-239" onClick={() => navigate("/products/SolarPanel")}>
                <button className="accordionButton-0-3-240 selected"  onClick={scrollToTop}>
                  Gvk solar
                  <svg
  className="dropdownIcon-0-3-241"
  width="6"
  height="10"
  viewBox="0 0 6 10"
  fill="none"
>
  <path
    d="M4.99995 5L0 0L-0.699995 0.7L3.59995 5L-0.699995 9.3L0 10L4.99995 5Z"
    fill="currentColor"
  ></path>
</svg>
                  
                </button>
                <div className="optionsList-0-3-242">
                  <div className="optionsListInner-0-3-243"></div>
                </div>
              </div>
              <div className="accordionItem-0-3-239" onClick={() => navigate("/tfit/Tfit")}>
                <button className="accordionButton-0-3-240 selected" onClick={scrollToTop}>
                  T-Fit
                             <svg
  className="dropdownIcon-0-3-241"
  width="6"
  height="10"
  viewBox="0 0 6 10"
  fill="none"
>
  <path
    d="M4.99995 5L0 0L-0.699995 0.7L3.59995 5L-0.699995 9.3L0 10L4.99995 5Z"
    fill="currentColor"
  ></path>
</svg>
                </button>
                <div className="optionsList-0-3-242">
                  <div className="optionsListInner-0-3-243"></div>
                </div>
              </div>
              <div className="accordionItem-0-3-239"  onClick={() => navigate("/panelb/Panel")}>
                <button className="accordionButton-0-3-240 selected" onClick={scrollToTop}>
                  panel boards
                             <svg
  className="dropdownIcon-0-3-241"
  width="6"
  height="10"
  viewBox="0 0 6 10"
  fill="none"
>
  <path
    d="M4.99995 5L0 0L-0.699995 0.7L3.59995 5L-0.699995 9.3L0 10L4.99995 5Z"
    fill="currentColor"
  ></path>
</svg>
                </button>
                <div className="optionsList-0-3-242">
                  <div className="optionsListInner-0-3-243"></div>
                </div>
              </div>
              <div className="accordionItem-0-3-239"   onClick={() => navigate("/crompton/Crompton")}>
                <button className="accordionButton-0-3-240 selected" onClick={scrollToTop}>
                 crompton
                             <svg
  className="dropdownIcon-0-3-241"
  width="6"
  height="10"
  viewBox="0 0 6 10"
  fill="none"
>
  <path
    d="M4.99995 5L0 0L-0.699995 0.7L3.59995 5L-0.699995 9.3L0 10L4.99995 5Z"
    fill="currentColor"
  ></path>
</svg>
                </button>
                <div className="optionsList-0-3-242">
                  <div className="optionsListInner-0-3-243"></div>
                </div>
              </div>
              <div className="accordionItem-0-3-239" onClick={() => navigate("/elmex/Elmex")}>
                <button className="accordionButton-0-3-240 selected" onClick={scrollToTop}> 
                ELMEX
                             <svg
  className="dropdownIcon-0-3-241"
  width="6"
  height="10"
  viewBox="0 0 6 10"
  fill="none"
>
  <path
    d="M4.99995 5L0 0L-0.699995 0.7L3.59995 5L-0.699995 9.3L0 10L4.99995 5Z"
    fill="currentColor"
  ></path>
</svg>
                </button>
                <div className="optionsList-0-3-242">
                  <div className="optionsListInner-0-3-243"></div>
                </div>
              </div>
              <div className="accordionItem-0-3-239" onClick={() => navigate("/Rishab/Rishab")}>
                <button className="accordionButton-0-3-240 selected" onClick={scrollToTop}> 
                RISHAB INSTRUMENTS
                             <svg
  className="dropdownIcon-0-3-241"
  width="6"
  height="10"
  viewBox="0 0 6 10"
  fill="none"
>
  <path
    d="M4.99995 5L0 0L-0.699995 0.7L3.59995 5L-0.699995 9.3L0 10L4.99995 5Z"
    fill="currentColor"
  ></path>
</svg>
                </button>
                <div className="optionsList-0-3-242">
                  <div className="optionsListInner-0-3-243"></div>
                </div>
              </div>
              <div className="accordionItem-0-3-239"  
              onClick={() => navigate("/L&t/Lt")}
              >
                <button className="accordionButton-0-3-240 selected" onClick={scrollToTop}>
                L&T SWITCHGEARS
                             <svg
  className="dropdownIcon-0-3-241"
  width="6"
  height="10"
  viewBox="0 0 6 10"
  fill="none"
>
  <path
    d="M4.99995 5L0 0L-0.699995 0.7L3.59995 5L-0.699995 9.3L0 10L4.99995 5Z"
    fill="currentColor"
  ></path>
</svg>
                </button>
                <div className="optionsList-0-3-242">
                  <div className="optionsListInner-0-3-243"></div>
                </div>
              </div>
               <div className="accordionItem-0-3-239"  onClick={() => navigate("/products/Kei")}>
                <button className="accordionButton-0-3-240 selected" onClick={scrollToTop}>
                kei wires & cables
                             <svg
  className="dropdownIcon-0-3-241"
  width="6"
  height="10"
  viewBox="0 0 6 10"
  fill="none"
>
  <path
    d="M4.99995 5L0 0L-0.699995 0.7L3.59995 5L-0.699995 9.3L0 10L4.99995 5Z"
    fill="currentColor"
  ></path>
</svg>
                </button>
                <div className="optionsList-0-3-242">
                  <div className="optionsListInner-0-3-243"></div>
                </div>
              </div>
              <div className="accordionItem-0-3-239"  onClick={() => navigate("/products/Elmeasure")}>
                <button className="accordionButton-0-3-240 selected " onClick={scrollToTop}>
                elmeasure
                             <svg
  className="dropdownIcon-0-3-241"
  width="6"
  height="10"
  viewBox="0 0 6 10"
  fill="none"
>
  <path
    d="M4.99995 5L0 0L-0.699995 0.7L3.59995 5L-0.699995 9.3L0 10L4.99995 5Z"
    fill="currentColor"
  ></path>
</svg>
                </button>
                <div className="optionsList-0-3-242">
                  <div className="optionsListInner-0-3-243"></div>
                </div>
              </div>
              <div className="accordionItem-0-3-239"  onClick={() => navigate("/products/Orbit")}>
                <button className="accordionButton-0-3-240 selected" onClick={scrollToTop}>
                orbit
                             <svg
  className="dropdownIcon-0-3-241"
  width="6"
  height="10"
  viewBox="0 0 6 10"
  fill="none"
>
  <path
    d="M4.99995 5L0 0L-0.699995 0.7L3.59995 5L-0.699995 9.3L0 10L4.99995 5Z"
    fill="currentColor"
  ></path>
</svg>
                </button>
                <div className="optionsList-0-3-242">
                  <div className="optionsListInner-0-3-243"></div>
                </div>
              </div>
              <div className="accordionItem-0-3-239"  onClick={() => navigate("/products/Havells")}>
                <button className="accordionButton-0-3-240 selected " onClick={scrollToTop}>
                havells
                             <svg
  className="dropdownIcon-0-3-241"
  width="6"
  height="10"
  viewBox="0 0 6 10"
  fill="none"
>
  <path
    d="M4.99995 5L0 0L-0.699995 0.7L3.59995 5L-0.699995 9.3L0 10L4.99995 5Z"
    fill="currentColor"
  ></path>
</svg>
                </button>
                <div className="optionsList-0-3-242">
                  <div className="optionsListInner-0-3-243"></div>
                </div>
              </div>
            </div>
            
          </div>
          {/* <div className="line-0-3-107 vertical-0-3-109 vertical-d78-0-3-248 verticalLine-0-3-227" style={{right:'0'}}></div> */}
        </div>
        <div className="listContainer-0-3-228">
        <div className="mainproductcontainer">
        <div className="card" 
      
      onClick={() => {
        navigate("/products/SolarPanel");
        window.scrollTo(0, 0); // Scroll to the top of the page
      }
      }>
          <div className="imgBx">
            <img src={gvksolar} alt=""></img>
          </div>
          <div className="details">
            <div className="content">
              <h2>
                Gvk solar<br></br>
                <br></br>
                <span style={{textTransform:'capitalize'}}>Design & Implementation</span>
              </h2>
            </div>
          </div>
        </div>
        <div className="card" onClick={() => {
        navigate("/tfit/Tfit");
        window.scrollTo(0, 0); // Scroll to the top of the page
      }}>
          <div className="imgBx">
            <img src={productfit} alt=""></img>
          </div>
          <div className="details">
            <div className="content">
              <h2>
                t-fit<br></br>
                <br></br>
                <span style={{textTransform:'capitalize'}}>Unique Insulation Technology</span>
              </h2>
            </div>
          </div>
        </div>
        <div className="card" onClick={() => {
        navigate("/panelb/Panel");
        window.scrollTo(0, 0); // Scroll to the top of the page
      }}>          <div className="imgBx">
            <img src={productpb} alt=""></img>
          </div>
          <div className="details">
            <div className="content">
              <h2>
                panel boards <br></br><br></br>
                <span style={{textTransform:'capitalize'}}>100% quality boards </span>
              </h2>
            </div>
          </div>
        </div>
        <div className="card" onClick={() => {
  navigate("/crompton/Crompton");
  window.scrollTo(0, 0); // Scroll to the top of the page
}}>
          <div className="imgBx">
            <img src={productc} alt=""></img>
          </div>
          <div className="details">
            <div className="content">
              <h2>
                crompton<br></br><br></br>
                <span style={{textTransform:'capitalize'}}>Discover Our Range</span>
              </h2>
            </div>
          </div>
        </div>
        <div className="card" onClick={() => {
        navigate("/elmex/Elmex");
        window.scrollTo(0, 0); // Scroll to the top of the page
      }}>          <div className="imgBx">
            <img src={producte} alt=""></img>
          </div>
          <div className="details">
            <div className="content">
              <h2>
                elmex<br></br><br></br>
                <span style={{textTransform:'capitalize'}}>quality and safety assured</span>
              </h2>
            </div>
          </div>
        </div>
        <div className="card" onClick={() => {
        navigate("/Rishab/Rishab");
        window.scrollTo(0, 0); // Scroll to the top of the page
      }}>          <div className="imgBx">
            <img src={productri} alt=""></img>
          </div>
          <div className="details">
            <div className="content">
              <h2>
                rishab instruments<br></br><br></br>
                <span style={{textTransform:'capitalize'}}> technology from GEFRAN (Italy)</span>
              </h2>
            </div>
          </div>
        </div>
        <div className="card" onClick={() => {
        navigate("/L&t/Lt");
        window.scrollTo(0, 0); // Scroll to the top of the page
      }}>          <div className="imgBx">
            <img src={productlt} alt=""></img>
          </div>
          <div className="details">
            <div className="content">
              <h2>
                l&t switchgears<br></br><br></br>
                <span style={{textTransform:'capitalize'}}>
                  {" "}
                  controlling, protecting, and isolating power systems
                </span>
              </h2>
            </div>
          </div>
        </div>
        <div className="card" onClick={() => {
        navigate("/products/Kei");
        window.scrollTo(0, 0); // Scroll to the top of the page
      }}>          <div className="imgBx">
            <img src={kei} alt=""></img>
          </div>
          <div className="details">
            <div className="content">
              <h2>
              KEI Wires & Cables<br></br><br></br>
                <span style={{textTransform:'capitalize'}}>
                  {" "}
                  A LEADING WIRES & CABLES MANUFACTURER SINCE 1968.
                </span>
              </h2>
            </div>
          </div>
        </div>
        <div className="card" onClick={() => {
        navigate("/products/Elmeasure");
        window.scrollTo(0, 0); // Scroll to the top of the page
      }}>          <div className="imgBx">
            <img src={elmeasure} alt=""></img>
          </div>
          <div className="details">
            <div className="content">
              <h2>
              Elmeasure<br></br><br></br>
                <span style={{textTransform:'capitalize'}}>
                  {" "}
                  PRODUCTS FOR RELIABLE & EFFICIENT POWER MANAGEMENT.
                </span>
              </h2>
            </div>
          </div>
        </div>
        <div className="card" onClick={() => {
        navigate("/products/Orbit");
        window.scrollTo(0, 0); // Scroll to the top of the page
      }}>          <div className="imgBx">
            <img src={orbit} alt=""></img>
          </div>
          <div className="details">
            <div className="content">
              <h2>
              orbit <br></br><br></br>
                <span style={{textTransform:'capitalize'}}>
                  {" "}
                  wires & cables
                </span>
              </h2>
            </div>
          </div>
        </div>
        <div className="card" onClick={() => {
        navigate("/products/Havells");
        window.scrollTo(0, 0); // Scroll to the top of the page
      }}>          <div className="imgBx">
            <img src={havell} alt=""></img>
          </div>
          <div className="details">
            <div className="content">
              <h2>
              havells<br></br><br></br>
                <span style={{textTransform:'capitalize'}}>
                  {" "}
                  LT/HT Power & Control Cables
                </span>
              </h2>
            </div>
          </div>
        </div>
      </div>
        </div>
      </section>
    </div>
    </>
  );
}

export default Product;
