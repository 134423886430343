import React from "react";
import "./newhome.css";
import { Pagination, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { useState, useRef } from "react";
import p1 from "../assets/p1.png";
import p2 from "../assets/p2.png";
import p3 from "../assets/p3.png";
import p4 from "../assets/p4.png";
import p5 from "../assets/p5.png";
import panel from "../assets/panel1.jpg";
import tfit from "../assets/tfit1.png";
import elmex from "../assets/elmex2.png";
import ri from "../assets/rishab21.png";
import cromp from "../assets/cromp.jpg";
import lt from "../assets/hlt.png";
import ho from "../assets/ho.png";
import { useNavigate } from "react-router-dom";
import Fade from "react-reveal/Fade";
import Banner from "./Banner";
import ml from "../assets/ml.png";
import ml1 from "../assets/ml1.png";
import ml2 from "../assets/ml2.png";
import ml3 from "../assets/ml3.png";
import ml4 from "../assets/ml4.png";
import ml5 from "../assets/ml5.png";
import ml6 from "../assets/ml6.png";
import tfitml from "../assets/tfitml.png"
import panelml from "../assets/panelml.png"
import solarhome from "../assets/solarhome.png";
import rinewhome from "../assets/rinewhome.jpg";
import lthome from "../assets/lthome.jpg";
import dfdf from "../assets/dfdf.png";
import mhpl from "../assets/home/mhpl.png";
import nal from "../assets/home/nal.png";
import ncg from "../assets/home/ncg.png";
import ncp from "../assets/home/ncp.png";
import nde from "../assets/home/nde.png";
import nelx from "../assets/home/nelx.png";
import nemv from "../assets/home/nemv.png";
import nez from "../assets/home/nez.png";
import nhs from "../assets/home/nhs.png";
import nkei from "../assets/home/nkei.png";
import nlt from "../assets/home/nlt.png";
import not from "../assets/home/not.png";
import nri from "../assets/home/nri.png";
import ntfit from "../assets/home/ntfit.png";
import nelm from "../assets/home/nelm.png";
import ngrw from "../assets/home/ngrw.png";
import srt from "../assets/srt.png";
import kei from "../assets/keihome.png"
import elmhome from "../assets/elmhome.png"
import orbithome from "../assets/orbithome.jpg"
import havellshome from "../assets/havellshome.png"
import homea from "../assets/homea.png"
function NewHome() {
  const handleSlideChange = (swiper) => {
    // Get the active index of the Swiper and update the state
    setActiveIndex(swiper.activeIndex);
  };
  // const navigate = useNavigate();

  const navigateToAboutPage = () => {
    navigate('/about', { state: { scrollPosition: 0 } });
    window.scrollTo(0, 0); 
  };
  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState(0);
  const images = [panel, cromp, elmex, tfit, rinewhome, lthome,srt, kei, elmhome, orbithome, havellshome];
  const texts = [
    "PANEL BOARDS",
    "CROMPTON",
    "ELMEX",
    "T-Fit",
    "RISHAB INSTRUMENTS ",
    "L&T SWITCHGEARS ",
    "GVK SOLAR",
   "KEI WIRES AND CABLES",
    "ELMEASURE",
    "ORBIT",
    "HAVELLS"
  ];
  const subtexts = [
    "A Panelboard Services To Protect Branch Circuits From Overloads And Short Circuits.",
    "Crompton Greaves Experts In Professional Lights ",
    "Elmex Is a Pioneer In The Field Of Electrical Wire Termination Technology And a Leading Manufacturer Of Terminal Blocks In India.",
    "The Best High Quality, High-Performance, Long Lasting Insulation Solution",
    "IIOT enabled RADIUS Inverters by Rishabh, with technology from GEFRAN (Italy), are 100% manufactured in India.",
    "L&T is India's largest manufacturer of low voltage switchgear and controlgear.",
    "Solar Engineering,Procurement & commissioning(EPC)",
    "PROVIDING ALL TYPES OF ELECTRICAL CABLES AND WIRES",
    "PRODUCTS FOR RELIABLE & EFFICIENT POWER MANAGEMENT",
    "ORBIT THE SMART CHOICE FOR YOUR HOME",
    " INDUSTRIAL CABLES FOR MULTIPLE APPLICATIONS AND SPECIFICATIONS"
  ];

  const handlePrevClick = () => {
    setActiveIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };
  
 
  const handleNextClick = () => {
    setActiveIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };
  const projectsData = [
    {
      title: 'solar Power design & implementation.',
      description: 'design and implementation company for residential, commercial and industrial at varies sector. GVK Solar is one of brand raised to install Solar Energy on a roof top & land base installations in the Renewable energy market with the intention to respond to the demand for energy efficient for business, residential and industrial sector. ',
      image: tfit,
    },
    {
      title: 'Manufactures of Electrical & Automation Panels',
      description: 'Design and manufacturing of a complete range of electrical panels undertaken in our state-of-the-art manufacturing facility as per customer’s requirement.',
      image: tfitml,
    },
    {
      title: 'T-FIT Insulation.',
      description: 'Pan India Distributor for T-FIT unique insulation solution for Dairy, Food & Beverage industries..',
      image: panel,
    },
  ];
  const ProjectCard = ({ title, description, imageSrc }) => (
    <div className="layout_card w-inline-block">
      <div className="layout_card-content">
        <div className="layout_card-content-top">
          <div className="margin-bottom margin-xsmall horizontal-align">
            <h3 className="heading-style-h5 text-style-allcaps text-weight-medium" style={{ color: "#005E8C", textTransform: "capitalize" }}>
              {title}
              <br />
            </h3>
          </div>
          <div className="margin-bottom align-horizontal">
            <div className="text-size-small text-style-allcaps text-colour-grey">
              {description}
            </div>
          </div>
        </div>
      </div>
      <img
        src={imageSrc}
        loading="lazy"
        sizes="(max-width: 479px) 100vw, (max-width: 767px) 89vw, (max-width: 991px) 45vw, 48vw"
        srcSet={imageSrc}
        alt="Project"
        className="layout_image"
        style={{ borderRadius: '15px' }}
      />
    </div>
  );
  
  return (
    <div>
      <main className="main-wrapper">
        <header className="section_hero-about">
          <div className="padding-global">
            <div className="container-large _90vw">
              <div className="header_content-copy">
                <div className="text-align-left">
                  <div className="max-width-full margin-bottom margin-large">
                    <div className="margin-bottom margin-small z overflow-hidden">
                      <Fade bottom>
                        <h1
                          text-split=""
                          letters-slide-down=""
                          className="heading-style-h2 about-title"
                        >
                          gvk power & <br></br>
                          engineering solutions
                        </h1>
                      </Fade>
                      {/* <Banner/> */}
                    </div>
                    <div className="about-hero-info-wrapper margin-top margin-large max-width-custom">
                      <Fade bottom>
                        <p
                         
                          className="text-size-medium max-width-xxsmall text-style-allcaps text-colour-grey text-weight-medium responsive-full-width margin-right margin-large"
                        >
                              "Innovative, sustainable, and interconnected electrical systems powering a smarter, greener, and electrified future for all."
                        </p>
                      </Fade>
                      <Fade bottom>
                        <p
                          //   style="opacity: 1"
                          className="text-size-medium max-width-medium text-weight-light full-width-respomsive"
                        >
                          BUILT TO SPEC
                        </p>
                      </Fade>
                      <Fade bottom>
                        <p
                          //   style="opacity: 1"
                          className="text-size-medium max-width-medium text-weight-light full-width-respomsive"
                        >
                          SINCE. 2018
                        </p>
                      </Fade>
                    </div>
                  
                  </div>
                  <div className="white-line not-visible-mobile about-line"></div>
                </div>
              </div>
           
           
            </div>
          </div>
        </header>
        <div>
        <section className="section_process home" style={{marginBottom:'2.5rem',marginTop:'-5rem'}}>
          <div className="padding-global">
            <div className="container-large _90vw">
              <div className="padding-top padding-xhuge">
                <div className="w-layout-grid process-layout_component">
                  <div className="process-layout_content-left">
                    <div className="margin-bottom margin-medium">
                      <div className="section-number-wrapper margin-bottom margin-medium">
                        {/* <div className="number-circle">
                                            <div className="text-color-black text-size-regular font-satoshi">3</div>
                                        </div> */}
                      </div>
                      <div className="margin-bottom">
                        <Fade bottom>
                          <h2
                            text-split=""
                            words-slide-from-right=""
                            className="heading-style-h2 _8vw-title"
                            style={{marginTop:'-3rem'}}
                          >
                            <img src={homea} style={{borderRadius:'15px'}}></img>
                          </h2>
                        </Fade>
                      </div>
                    </div>
                    <Fade bottom>
                    
                    </Fade>
                
                  </div>
                  <div className="newaboust">
                  <div className="process-layout_content-right">
                   
                  
                
                  
                    <div
                      className="process-layout_content-item content-item-4"
                      style={{ color: "white" ,border:'none',top:'10%'}}
                    >
                     
                      <div className="margin-bottom margin-xsmall">
                        <h3
                          style={{ color: "#005E8C" }}
                          text-split=""
                          words-slide-from-right=""
                          className="heading-style-h5 text-style-allcaps text-weight-medium"
                        >
                          About
                        </h3>
                      </div>
                      <p
                        style={{
                          opacity: "1",
                          color: "rgba(92, 92, 92, 0.70)",
                        }}
                        className="text-size-regular"
                      >
                      
                       We GVK Power & Engineering Solutions Bengaluru, professionally managed, are authorised distributors of all electrical cables, wires, switchgears, lighting, etc.., and serving various industrial & commercial projects at very competitive prices by ensuring timely, flawless delivery at client’s sites which carved a niche in business.
                      </p>
                      <button className="homeaboutb" onClick={navigateToAboutPage} >Know More</button>
                    </div>
                   
                  </div>
                  </div>
                  {/*  */}
                </div>
              </div>
            </div>
          </div>
        </section>
        </div>
        <section className="section_process home" style={{marginBottom:'2.5rem',marginTop:'-5rem'}}>
          <div className="padding-global">
            <div className="container-large _90vw">
              <div className="padding-top padding-xhuge">
                <div className="w-layout-grid process-layout_component">
                  {/* <div className="tradinginm"> */}
                  <div className="process-layout_content-left" style={{top:"18%"}}>
                    <div className="margin-bottom margin-medium">
                      <div className="section-number-wrapper margin-bottom margin-medium">
                        {/* <div className="number-circle">
                                            <div className="text-color-black text-size-regular font-satoshi">3</div>
                                        </div> */}
                      </div>
                      <div className="margin-bottom">
                        <Fade bottom>
                          <h2
                            text-split=""
                            words-slide-from-right=""
                            className="heading-style-h2 _8vw-title"
                            style={{marginTop:'-3rem'}}
                          >
                            Trading in
                          </h2>
                        </Fade>
                      </div>
                    </div>
                    <Fade bottom>
                      <p
                        style={{ opacity: "1" ,color:" rgba(92, 92, 92, 0.7)",fontWeight:'400'}}
                        className="text-size-medium max-width-medium text-weight-light tradinginm"
                      >
                        Electrical Products -HT, LT and other Industrial Cables, Wires, LT Switchgears and Lighting.                       <br></br>
                       <br></br>
                      </p>
                    </Fade>
                    {/* <div className="button-group margin-top margin-large">
                    
                    </div> */}
                  </div>
                  {/* </div> */}
                  <div className="process-layout_content-right"style={{top:"18%"}}>
                   
                  
                     
                  
                    <div
                      className="process-layout_content-item content-item-1"
                      style={{ color: "white" ,top:"15%"}}
                    >
                      <div className="margin-bottom margin-xsmall">
                        <div
                          style={{ opacity: "1" }}
                          className="icon-embed-medium w-embed"
                        >
                          <img src={p5} className="iconify iconify--fe"></img>
                        </div>
                      </div>
                      <div className="margin-bottom margin-xsmall">
                        <h3
                          style={{ color: "#005E8C" }}
                          text-split=""
                          words-slide-from-right=""
                          className="heading-style-h5 text-style-allcaps text-weight-medium"
                        >
                          Authorised distributor for 
                        </h3>
                      </div>
                      <p
                        style={{
                          opacity: "1",
                          color: "rgba(92, 92, 92, 0.70)",
                        }}
                        className="text-size-regular"
                      >
                        . Havells, KEI, orbit wires & cables
                        <br></br>. Crompton greaves LED lighting 
                        <br></br>. Elmex connectors & for solar  plants
                        <br></br>. Rishab instruments 
                        <br></br>. HPL power switcgears 
                        <br></br>. Elmeasure instruments & switchgears
                        <br></br>. L&t eA switchgear & associated items
                      </p>
                    </div>
                     <div
                      className="process-layout_content-item content-item-4"
                      style={{ color: "white" }}
                    >
                      <div className="margin-bottom margin-xsmall">
                        <div
                          style={{ opacity: "1" }}
                          className="icon-embed-medium w-embed"
                        >
                          <img src={p4} className="iconify iconify--fe"></img>
                        </div>
                      </div>
                      <div className="margin-bottom margin-xsmall">
                        <h3
                          style={{ color: "#005E8C" }}
                          text-split=""
                          words-slide-from-right=""
                          className="heading-style-h5 text-style-allcaps text-weight-medium"
                        >
                         Turnkey solutions for industrial electricals.
                          <br />
                        </h3>
                      </div>
                      <p
                        style={{
                          opacity: "1",
                          color: "rgba(92, 92, 92, 0.70)",
                        }}
                        className="text-size-regular"
                      >
                        we also under take complete industrial electrical works on turnkey basis from site inspection till commissioning includes preparation of drawings, estimation, LT/HT panel works with cabling works, street lighting, DG sets etc., in complete compliment.
                      </p>
                    </div>  
                  </div>
                  {/*  */}
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <br></br> */}
        <div class="image-grid">
  <img src={nhs} alt="Image 1"/>
  <img src={nkei} alt="Image 2"/>
  <img src={not} alt="Image 3"/>
 
  {/* <img src={nal} alt="Image 5"/> */}
  {/* <img src={nde} alt="Image 1"/> */}
  <img src={nelx} alt="Image 2"/>
  <img src={mhpl} alt="Image 3"/>
  <img src={ncp} alt="Image 4"/>
  <img src={nri} alt="Image 5"/>
  {/* <img src={nez} alt="Image 1"/> */}
  <img src={nlt} alt="Image 2"/>
  <img src={ntfit} alt="Image 2"/>
  <img src={nelm} alt="Image 2"/>
 
</div>
<div className="white-line not-visible-mobile about-line" style={{marginTop:'4rem'}}></div>
{/* <h4 className="homewealso" style={{marginTop:'1rem'}}>We also sell</h4>
<div class="image-grid" style={{marginTop:'1.5rem'}}>
  <img src={nde} alt="Image 1"/>
  <img src={nez} alt="Image 2"/>
  <img src={ngrw} alt="Image 2"/>

  <img src={nemv} alt="Image 4"/>
  <img src={nal} alt="Image 3"/>
  </div> */}
        {/* <div className="white-line not-visible-mobile about-line"style={{marginTop:'3rem'}}></div> */}
        <div className="solarkontl">
        <section class="section_projects">
          <div class="padding-global">
            <div class="container-large _100--vw">
              <div class="padding-bottom padding-xhuge">
                {/* <div class="margin-bottom margin-xxlarge">
                  <div class="text-align-center">
                    <div class="max-width-full text-align-left horizontal-align">
                      <div class="margin-bottom">
                        <div
                          data-w-id="b714c474-4cce-e22c-8ec6-3952ed4cb27d"
                          style={{ opacity: "0" }}
                          class="section-number-wrapper margin-bottom margin-medium"
                        >
                          <div class="number-circle">
                            <div class="text-color-black text-size-regular font-satoshi">
                              1
                            </div>
                          </div>
                          <div class="section-name-wrapper">
                            <div class="text-size-small text-colour-grey">
                              Other projects
                            </div>
                          </div>
                        </div>
                      </div>
                    
                    </div>
                  </div>
                </div> */}
                <div class="w-layout-grid layout_component">
                  <div
                    id="w-node-b714c474-4cce-e22c-8ec6-3952ed4cb28a-1f6964f4"
                    data-w-id="b714c474-4cce-e22c-8ec6-3952ed4cb28a"
                    style={{ width: "0%" }}
                    class="white-line scroll-into"
                  ></div>
                  <div class="w-layout-grid layout_row home">
                    <Fade bottom>
                      <a href="#" class="layout_card w-inline-block">
                      
                        <div class="layout_card-content">
                          <div class="layout_card-content-top">
                            <div
                              style={{ opacity: "1" }}
                              class="margin-bottom margin-xsmall horizontal-align"
                            >
                              <h3
                                text-split=""
                                words-slide-from-right=""
                                class="heading-style-h5 text-style-allcaps text-weight-medium"
                                style={{
                                  color: "#005E8C",
                                  textTransform: "capitalize",
                                }}
                              >
                          solar Power design & implementation.
                                <br />
                              </h3>

                            
                            </div>
                            <div class="margin-bottom align-horizontal">
                              <div class="text-size-small text-style-allcaps text-colour-grey">
                              Design and implementation company for residential, commercial and industrial at varies sector. GVK Solar is one of brand raised to install Solar Energy on a roof top & land base installations in the Renewable energy market with the intention to respond to the demand for energy efficient for business, residential and industrial sector. 
                              </div>
                           
                            </div>
                          </div>
                        </div>
                      </a>
                    </Fade>
                    <div style={{ height: "0%" }} class="vertical-line komefi"></div>
                  
                      <a href="#" class="layout_card w-inline-block">
                        <div
                          id="w-node-b714c474-4cce-e22c-8ec6-3952ed4cb2a4-1f6964f4"
                          data-w-id="b714c474-4cce-e22c-8ec6-3952ed4cb2a4"
                          class="layout_image-wrapper"
                        >
                          <img
                            src={solarhome}
                            loading="lazy"
                            data-w-id="b714c474-4cce-e22c-8ec6-3952ed4cb2a5"
                            sizes="(max-width: 479px) 100vw, (max-width: 767px) 89vw, (max-width: 991px) 45vw, 48vw"
                            srcset={solarhome}
                            alt="Bucătarie minimalistă"
                            class="layout_image home"
                            style={{borderRadius:'15px'}}
                          />
                          <div
                            style={{ height: "100%" }}
                            class="image-cover"
                          ></div>
                        </div>
                    
                      </a>
              
                  </div>
                  <div
                    data-w-id="b714c474-4cce-e22c-8ec6-3952ed4cb2b9"
                    style={{ width: "0%" }}
                    class="white-line scroll-into"
                  ></div>
                </div>
                <div className="white-line not-visible-mobile about-line"style={{marginTop:'-1rem'}}></div>
                <div class="w-layout-grid layout_component">
                  <div
                    id="w-node-b714c474-4cce-e22c-8ec6-3952ed4cb28a-1f6964f4"
                    data-w-id="b714c474-4cce-e22c-8ec6-3952ed4cb28a"
                    style={{ width: "0%" }}
                    class="white-line scroll-into"
                  ></div>
                  <div class="w-layout-grid layout_row home">
                    <Fade bottom>
                      <a href="#" class="layout_card w-inline-block">
                      
                        <div class="layout_card-content">
                          <div class="layout_card-content-top">
                            <div
                              style={{ opacity: "1" }}
                              class="margin-bottom margin-xsmall horizontal-align"
                            >
                              <h3
                                text-split=""
                                words-slide-from-right=""
                                class="heading-style-h5 text-style-allcaps text-weight-medium"
                                style={{
                                  color: "#005E8C",
                                  textTransform: "capitalize",
                                }}
                              >
                             manufactures of electrical & automation panels.
                                <br />
                              </h3>

                            
                            </div>
                            <div class="margin-bottom align-horizontal">
                              <div class="text-size-small text-style-allcaps text-colour-grey">
                              Design and manufacturing of complete range of electrical panels undertaken in our state-of-the-art manufacturing facility as per customer’s requirement.
                              </div>
                           
                            </div>
                          </div>
                        </div>
                      </a>
                    </Fade>
                    <div style={{ height: "0%" }} class="vertical-line"></div>
                  
                      <a href="#" class="layout_card w-inline-block">
                        <div
                          id="w-node-b714c474-4cce-e22c-8ec6-3952ed4cb2a4-1f6964f4"
                          data-w-id="b714c474-4cce-e22c-8ec6-3952ed4cb2a4"
                          class="layout_image-wrapper"
                        >
                          <img
                            src={tfitml}
                            loading="lazy"
                            data-w-id="b714c474-4cce-e22c-8ec6-3952ed4cb2a5"
                            sizes="(max-width: 479px) 100vw, (max-width: 767px) 89vw, (max-width: 991px) 45vw, 48vw"
                            srcset={tfitml}
                            alt="Bucătarie minimalistă"
                            class="layout_image home"
                            style={{borderRadius:'15px'}}
                          />
                          <div
                            style={{ height: "100%" }}
                            class="image-cover"
                          ></div>
                        </div>
                    
                      </a>
              
                  </div>
                  <div
                    data-w-id="b714c474-4cce-e22c-8ec6-3952ed4cb2b9"
                    style={{ width: "0%" }}
                    class="white-line scroll-into"
                  ></div>
                </div>
                <div className="white-line not-visible-mobile about-line"style={{marginTop:'-1rem'}}></div>
                <div class="w-layout-grid layout_component">
                  <div
                    id="w-node-b714c474-4cce-e22c-8ec6-3952ed4cb28a-1f6964f4"
                    data-w-id="b714c474-4cce-e22c-8ec6-3952ed4cb28a"
                    style={{ width: "0%" }}
                    class="white-line scroll-into"
                  ></div>
                  <div class="w-layout-grid layout_row home">
                    <Fade bottom>
                      <a href="#" class="layout_card w-inline-block">
                      
                        <div class="layout_card-content">
                          <div class="layout_card-content-top">
                            <div
                              style={{ opacity: "1" }}
                              class="margin-bottom margin-xsmall horizontal-align"
                            >
                              <h3
                                text-split=""
                                words-slide-from-right=""
                                class="heading-style-h5 text-style-allcaps text-weight-medium"
                                style={{
                                  color: "#005E8C",
                                  textTransform: "capitalize",
                                }}
                              >
                               T-FIT Insulation.
                                <br />
                              </h3>

                            
                            </div>
                            <div class="margin-bottom align-horizontal">
                              <div class="text-size-small text-style-allcaps text-colour-grey">
                              Pan India Distributor for T-FIT unique insulation solution for Dairy, Food & Beverage industries.
                              </div>
                           
                            </div>
                          </div>
                        </div>
                      </a>
                    </Fade>
                    <div style={{ height: "0%" }} class="vertical-line"></div>
                  
                      <a href="#" class="layout_card w-inline-block">
                        <div
                          id="w-node-b714c474-4cce-e22c-8ec6-3952ed4cb2a4-1f6964f4"
                          data-w-id="b714c474-4cce-e22c-8ec6-3952ed4cb2a4"
                          class="layout_image-wrapper"
                        >
                          <img
                            src={dfdf}
                            loading="lazy"
                            data-w-id="b714c474-4cce-e22c-8ec6-3952ed4cb2a5"
                            sizes="(max-width: 479px) 100vw, (max-width: 767px) 89vw, (max-width: 991px) 45vw, 48vw"
                            srcset={dfdf}
                            alt="Bucătarie minimalistă"
                            class="layout_image home"
                            style={{borderRadius:'15px'}}
                          />
                          <div
                            style={{ height: "100%" }}
                            class="image-cover"
                          ></div>
                        </div>
                    
                      </a>
              
                  </div>
                  <div
                    data-w-id="b714c474-4cce-e22c-8ec6-3952ed4cb2b9"
                    style={{ width: "0%" }}
                    class="white-line scroll-into"
                  ></div>
                </div>
              </div>
            </div>
          </div>
          
        </section>
        </div>
        
  <div className="sectionsliderrhpp"> 
                <div className="line-0-3-107 horizontal-0-3-108 horizontal-d177-0-3-389"></div>
                </div>
        
        <section>
          <div className="sectionsliderhp">
          <div class="projects-0-3-251">
            <div class="project-0-3-252 Layout-section-0-3-33 grid">
              <div class="content-0-3-253">
                <div class="titleField-0-3-257">
                  <label class="caption-0-3-237 label-0-3-266">Products</label>
                  <span class="text-0-3-265 text-d0-0-3-267">
                    <a
                      class="title-0-3-258 link-0-3-268"
                      // href="/projects/180-george-street-plaza-building/"
                      style={{ fontWeight: "900" }}
                    >
                      <p style={{ color: "#005E8C" }}>{texts[activeIndex]}</p>
                    </a>
                    <br></br>
                    <a
                      class="title-0-3-258 link-0-3-268 new"
                      // href="/projects/180-george-street-plaza-building/"
                    >
                      <p style={{ color: "rgba(92, 92, 92, 0.70)" }}>
                        {subtexts[activeIndex]}
                      </p>
                    </a>
                  </span>
                </div>
                <div class="detailsContainer-0-3-259">
                  <div class="onlyDesktop-0-3-256">
                    
                    <span class="text-0-3-265 text-d3-0-3-271">
                      <div>
                        <button
                          class="paginationButton-0-3-272 button-0-3-39"
                          disabled=""
                          onClick={handlePrevClick}
                        >
                          <span class="iconContainer-0-3-43 backArrow">
                            <svg
                              class="icon-0-3-42 backArrow"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 18 8"
                            >
                              <polyline
                                vector-effect="non-scaling-stroke"
                                points="12.8,0.6 16.1,4 12.8,7.3"
                                stroke="currentColor"
                                fill="transparent"
                              ></polyline>
                              <line
                                vector-effect="non-scaling-stroke"
                                x1="0"
                                y1="4"
                                x2="16.1"
                                y2="4"
                                stroke="currentColor"
                              ></line>
                            </svg>
                          </span>
                          <div class="clone-0-3-40" aria-hidden="true">
                            <span class="cloneContent-0-3-41">
                              <span class="iconContainer-0-3-43 backArrow">
                                <svg
                                  class="icon-0-3-42 backArrow"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 18 8"
                                >
                                  <polyline
                                    vector-effect="non-scaling-stroke"
                                    points="12.8,0.6 16.1,4 12.8,7.3"
                                    stroke="currentColor"
                                    fill="transparent"
                                  ></polyline>
                                  <line
                                    vector-effect="non-scaling-stroke"
                                    x1="0"
                                    y1="4"
                                    x2="16.1"
                                    y2="4"
                                    stroke="currentColor"
                                  ></line>
                                </svg>
                              </span>
                            </span>
                          </div>
                        </button>
                        <button
                          class="paginationButton-0-3-272 button-0-3-39"
                          onClick={handleNextClick}
                        >
                          <span class="iconContainer-0-3-43">
                            <svg
                              class="icon-0-3-42"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 18 8"
                            >
                              <polyline
                                vector-effect="non-scaling-stroke"
                                points="12.8,0.6 16.1,4 12.8,7.3"
                                stroke="currentColor"
                                fill="transparent"
                              ></polyline>
                              <line
                                vector-effect="non-scaling-stroke"
                                x1="0"
                                y1="4"
                                x2="16.1"
                                y2="4"
                                stroke="currentColor"
                              ></line>
                            </svg>
                          </span>
                          <div class="clone-0-3-40" aria-hidden="true">
                            <span class="cloneContent-0-3-41">
                              <span class="iconContainer-0-3-43">
                                <svg
                                  class="icon-0-3-42"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 18 8"
                                >
                                  <polyline
                                    vector-effect="non-scaling-stroke"
                                    points="12.8,0.6 16.1,4 12.8,7.3"
                                    stroke="currentColor"
                                    fill="transparent"
                                  ></polyline>
                                  <line
                                    vector-effect="non-scaling-stroke"
                                    x1="0"
                                    y1="4"
                                    x2="16.1"
                                    y2="4"
                                    stroke="currentColor"
                                  ></line>
                                </svg>
                              </span>
                            </span>
                          </div>
                        </button>
                      </div>
                    </span>
                  </div>
                </div>
                <div
                  class="line-0-3-107 vertical-0-3-109 vertical-d82-0-3-274 verticalLine-0-3-255"
                  // style="right: 0"
                  style={{ right: "0" }}
                ></div>
              </div>
              <div class="images-0-3-254">
                <div class="container-0-3-277">
                  <div>
                    <div class="Slider-flickity-0-3-278">
                      <div class="image-0-3-279 image-d0-0-3-280 imageContainer-0-3-92 imageContainer-d6-0-3-281">
                       
                        <picture class="picture-0-3-93">
                         
                          <Swiper
                          onSlideChange={handleSlideChange}
                          // navigation
                          pagination={{ clickable: true }}
                          effect="fade">
                            {images.map((imageUrl, index) => (
                              <SwiperSlide key={index}>
                                <img src={images[activeIndex]}></img>
                              </SwiperSlide>
                            ))}
                          </Swiper>
                        </picture>
                        <div class="curtain-0-3-283">
                          <div class="leftCurtain-0-3-284 topDown"></div>
                          <div class="rightCurtain-0-3-285 topDown"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>
          <div className="homelastmoclin">
          <div className="line-0-3-107 horizontal-0-3-108 horizontal-d177-0-3-389"></div>
          </div>
        </section>
      </main>
    </div>
  );
}

export default NewHome;
