import React from 'react';
import Contact from "./components/contact/Contact";
import Project from "./components/projects/Project";
import Product from "./components/products/Product";
import Tfit from "./components/products/tfit/Tfit";
import Panel from "./components/products/panelb/Panel";
import Elmex from "./components/products/elmex/Elmex";
import Rishab from "./components/products/Rishab/Rishab";
import Terminal from "./components/products/elmex/TerminalBlock/Terminal";
import About from "./components/About/About";
import Solar from "./components/products/elmex/solar/Solar";
import Crompton from "./components/products/crompton/Crompton";
import Paneldesc from "./components/products/panelb/Paneldesc";
import Lt from "./components/products/lt/Lt";
import Panelpcc from "./components/products/panelb/Panelpcc";
import Panelpl from './components/products/panelb/Panelpl';
// import Keic from './components/products/Kei/Keic';
import Home from "./Home/Home";
import Loader from "./components/Loader/Loader";
import { SmoothProvider } from 'react-smooth-scrolling'
import {AnimatePresence} from 'framer-motion'
import MyComponent from './MyComponent';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    Routes,
    useLocation
  } from "react-router-dom";
import NewHome from './components/NewHomepage/NewHome';
import Keic from './components/products/Kei/Keic';
import Havell from './components/products/Havells/Havell';
import Orbit from "./components/products/orbit/Orbit";
import Elmeasure from "./components/products/Elmeasure/Elmeasure"
import Newproject from './components/Newproject/Newproject';
import Solarpanel from './components/products/Solarpanel/Solarpanel';
import Career from './components/Careers/Career';
import Panelmcc from './components/products/panelb/Panelmcc';
import Paneldb from './components/products/panelb/Paneldb';
import Panelimcc from './components/products/panelb/Panelimcc';
import Panelvfd from './components/products/panelb/Panelvfd';
import { useEffect ,useState} from 'react';
import {  motion } from "framer-motion";
// import Partner from './components/Partner/Partner';
import Partners from './components/Partner/Partners';

function AnimatedRoutes() {

    const location =useLocation();
    function ScrollToTop() {
      const { pathname } = useLocation();
      const [shouldScrollToTop, setShouldScrollToTop] = useState(true);
    
      useEffect(() => {
        if (shouldScrollToTop) {
          // window.scrollTo(0, 0);
          setShouldScrollToTop(false);
        }
      }, [pathname, shouldScrollToTop]);
    
      return null;
    }
    const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);
  const scrollToTop = () => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  };
  const [colorTheme, setColorTheme] = useState("theme-white");

  useEffect(() => {
    const currentThemeColor = localStorage.getItem("theme-color");

    if (currentThemeColor) {
      setColorTheme(currentThemeColor);
    }
  }, []);
  return (
    // <AnimatePresence>
    // <ScrollToTop /> 

        
     
    // </AnimatePresence>
      <div className="content-box">
      {loading ? (
        <MyComponent />
        
      ) : (
        <>
          {/* Your content */}
          <AnimatePresence >
            <motion.div
              key={location.pathname}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              {/* <Routes location={location}> */}
              <Routes location={location} key={location.pathname}>
            <Route exact path="/" element={<NewHome />} />
            <Route exact path="/about" element={<About />} />
            <Route exact path="/contact" element={<Contact />} />
            <Route exact path="/projects" element={<Newproject />} />
            <Route exact path="/products" element={<Product />} />
            <Route exact path="/tfit/Tfit" element={<Tfit />} />
            <Route exact path="/panelb/Panel" element={<Panel />} />
            <Route exact path="/Rishab/Rishab" element={<Rishab />} />
            <Route exact path="/elmex/Elmex" element={<Elmex />} onClick={scrollToTop}></Route>
            <Route exact path="/products/Kei" element={<Keic />} onClick={scrollToTop}></Route>
            <Route exact path="/products/Havells" element={<Havell />} onClick={scrollToTop}></Route>
            <Route exact path="/products/Orbit" element={<Orbit />} onClick={scrollToTop}></Route>
            <Route exact path="/products/Elmeasure" element={<Elmeasure />}></Route>
            <Route exact path="/products/SolarPanel" element={<Solarpanel />}></Route>
            <Route exact path="/careers" element={<Career />}></Route>
            <Route exact path="/panel/Panelpcc" element={<Panelpcc />}></Route>
            <Route exact path="/panel/Panelplc" element={<Panelpl />}></Route>
            <Route exact path="/panel/Panelmcc" element={<Panelmcc />}></Route>
            <Route exact path="/panel/Paneldb" element={<Paneldb />}></Route>
            <Route exact path="/panel/Panelimcc" element={<Panelimcc />}></Route>
            <Route exact path="/panel/Panelvfd" element={<Panelvfd />}></Route>
            <Route exact path="/partners" element={<Partners />}></Route>
            <Route
              exact
              path="/elmex/Elmex/TerminalBlocks"
              element={<Terminal />}
            ></Route>
            <Route
              exact
              path="/elmex/Elmex/SolarProducts"
              element={<Solar />}
            ></Route>
            <Route
              exact
              path="/crompton/Crompton"
              element={<Crompton />}
            ></Route>
            <Route
              exact
              path="/panelb/tfit/Tfit"
              element={<Paneldesc />}
            ></Route>
            <Route exact path="/L&t/Lt" element={<Lt />}></Route>
          </Routes>
              {/* </Routes> */}
            </motion.div>
          </AnimatePresence>
        </>
      )}
      {/* <Footer /> */}
    </div>
  );
}
  


export default AnimatedRoutes