import React from 'react'
import pcc from "../../assets/plc.png";


function Panelpl() {
  return (
    <div>
          <div className='gvksolarpanel'> 
    <div class="sideline"></div>
       <section class="section_projects">
          <div class="padding-global">
            <div class="container-large _100--vw">
              <div class="padding-bottom padding-xhuge">
             
                <div class="w-layout-grid layout_component">
                  <div
                    id="w-node-b714c474-4cce-e22c-8ec6-3952ed4cb28a-1f6964f4"
                    data-w-id="b714c474-4cce-e22c-8ec6-3952ed4cb28a"
                    style={{ width: "0%" }}
                    class="white-line scroll-into"
                  ></div>
                  <div class="w-layout-grid layout_row home">
                    {/* <Fade bottom> */}
                      <a href="#" class="layout_card w-inline-block">
                      
                        <div class="layout_card-content">
                          <div class="layout_card-content-top">
                            <div
                              style={{ opacity: "1" }}
                              class="margin-bottom margin-xsmall horizontal-align"
                            >
                              <h1
                                text-split=""
                                words-slide-from-right=""
                                class="heading-style-h5 text-style-allcaps text-weight-medium"
                                style={{
                                  color: "#005E8C",
                                  // textTransform: "capitalize",
                                }}
                              >
                          Programmable logic controller(plc)
                                <br />
                              </h1>

                            
                            </div>
                            <div class="margin-bottom align-horizontal">
                              <div class="text-size-small text-style-allcaps text-colour-grey">
                                <p>
                                Rugged and designed to withstand vibrations, temperature, humidity and noise.PLC has a lot of contacts and low cost and safe.It has a very faster scan time, it has a fast operating time.
<br></br><br></br>
A wide range of control application.It has capable to communicate with a computer in the plant.It has great computational capabilities. 


</p>
</div>                           
                           
                           <br></br>
                           
                            </div>
                          </div>
                        </div>
                      </a>
                    
                    <div style={{ height: "0%" }} class="vertical-line"></div>
                  
                      <a href="#" class="layout_card w-inline-block">
                        <div
                          id="w-node-b714c474-4cce-e22c-8ec6-3952ed4cb2a4-1f6964f4"
                          data-w-id="b714c474-4cce-e22c-8ec6-3952ed4cb2a4"
                          class="layout_image-wrapper"

                        >
                          <img
                            src={pcc}
                            loading="lazy"
                            data-w-id="b714c474-4cce-e22c-8ec6-3952ed4cb2a5"
                            sizes="(max-width: 479px) 100vw, (max-width: 767px) 89vw, (max-width: 991px) 45vw, 48vw"
                            srcset={pcc}
                            alt="Bucătarie minimalistă"
                            class="layout_image home"
                            // style={{borderRadius:'15px'}}
                          />
                          <div
                            style={{ height: "100%" }}
                            class="image-cover"
                          ></div>
                        </div>
                    
                      </a>
              
                  </div>
                  <div
                    data-w-id="b714c474-4cce-e22c-8ec6-3952ed4cb2b9"
                    style={{ width: "0%" }}
                    class="white-line scroll-into"
                  ></div>
                </div>
                
                
              </div>
            </div>
          </div>
          
        </section>
    </div>
    </div>
  )
}

export default Panelpl