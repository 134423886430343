import React from "react";
import "./footer.css";
import flogo from "../assets/logo.jpeg";
import { Link } from "react-router-dom";
import useOnScreen from "../../utilities/useOnScreen";
import { useRef, useState } from "react";
import emailjs from '@emailjs/browser';
import Swal from 'sweetalert2';

function Footer() {
  // const aref = useRef();

  // const onScreen = useOnScreen(aref, "100px");

  // const templateParams = {
  //   to_name: to_name,
  //   from_name: from_name,
  //   message: message,
  // };
  
  // const SendEmail = () =>{
  //   emailjs.send('service_9ek2ucf','template_tciscm9', templateParams, 'QUfsF5iCLx4UBvZy_')
	// .then((response) => {
	//    console.log('SUCCESS!', response.status, response.text);
	// }, (err) => {
	//    console.log('FAILED...', err);
	// });
  // }

  // const form = useRef();

  // const sendEmail = (e) => {
  //   e.preventDefault();

  //   emailjs.sendForm('service_9ek2ucf', 'template_tciscm9', form.current, 'QUfsF5iCLx4UBvZy_')
  //     .then((result) => {
  //         console.log(result.text);
  //         form.current.reset();
  //     }, (error) => {
  //         console.log(error.text);
  //     });
  // };

  const aref = useRef();
  const [formErrors, setFormErrors] = useState({});
  const [formSubmitted, setFormSubmitted] = useState(false);

  const onScreen = useOnScreen(aref, "100px");

  const form = useRef();

  const validateForm = () => {
    const errors = {};
    let hasErrors = false;

    // Validate first name
    if (!form.current.from_name.value.trim()) {
      errors.from_name = "First name is required";
      hasErrors = true;
    }

    // Validate last name
    if (!form.current.user_name.value.trim()) {
      errors.user_name = "Last name is required";
      hasErrors = true;
    }

    // Validate email
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!form.current.user_email.value.trim() || !emailPattern.test(form.current.user_email.value.trim())) {
      errors.user_email = "Valid email is required";
      hasErrors = true;
    }

    // Validate phone number
    const phoneNumberPattern = /^\d{10}$/;
    if (form.current.user_phone.value.trim() && !phoneNumberPattern.test(form.current.user_phone.value.trim())) {
      errors.user_phone = "Invalid phone number (10 digits)";
      hasErrors = true;
    }

    setFormErrors(errors);
    return !hasErrors;
  };

  const sendEmail = (e) => {
    e.preventDefault();

    if (validateForm()) {
      emailjs.sendForm("service_9ek2ucf", "template_tciscm9", form.current, "QUfsF5iCLx4UBvZy_")
        .then((result) => {
          console.log(result.text);
          form.current.reset();
          setFormSubmitted(true);
        })
        .catch((error) => {
          console.error("Error sending email:", error);
        });
    }
  };
  const scrollToTop = () => {
    window.scrollTo(0, 0); 
  };
  const SERVICE_ID = "service_v1n30na";
  const TEMPLATE_ID = "template_r38l7fq";
  const PUBLIC_KEY = "S0x_nHU6wLPfW9BfK";

    const handleOnSubmit = (e) => {
      e.preventDefault();
      emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, e.target, PUBLIC_KEY)
        .then((result) => {
          console.log(result.text);
          Swal.fire({
            icon: 'success',
            title: 'Message Sent Successfully'
          })
        }, (error) => {
          console.log(error.text);
          Swal.fire({
            icon: 'error',
            title: 'Ooops, something went wrong',
            text: error.text,
          })
        });
      e.target.reset()
    };
  return (
    <div>
      <footer className="footer-0-3-367"
      //  onClick={scrollToTop}
      >
        <div
          ref={aref}
          style={
            {
              // height: "100vh",
            }
          }
        >
          {onScreen ? (
            <div>
              <section className="section-0-3-368 Layout-section-0-3-33 gutter grid">
                <div className="firstLine-0-3-384 verticalLineContainer-0-3-383"></div>
              </section>
            </div>
          ) : (
            <></>
          )}
        </div>
        <div
          className="line-0-3-107 horizontal-0-3-108 horizontal-d177-0-3-389"
          style={{
            color: onScreen ? "white" : "black",
            animation: " line 1.25s ease forwards",
          }}
          ref={aref}
        ></div>
        <section className="section-0-3-368 Layout-section-0-3-33 gutter grid">
          <div
            className="firstLine-0-3-384 verticalLineContainer-0-3-383"
            ref={aref}
          >
            <div
              className="line-0-3-107 vertical-0-3-109 vertical-d182-0-3-394"
              ref={aref}
            ></div>
          </div>
          <div className="secondLine-0-3-385 verticalLineContainer-0-3-383">
            <div className="line-0-3-107 vertical-0-3-109 vertical-d186-0-3-398"></div>
          </div>
          <div className="firstMobileLine-0-3-387 horizontalLineContainer-0-3-386">
            <div className="line-0-3-107 horizontal-0-3-108 horizontal-d189-0-3-401"></div>
          </div>
          <div className="secondMobileLine-0-3-388 horizontalLineContainer-0-3-386">
            <div className="line-0-3-107 horizontal-0-3-108 horizontal-d193-0-3-405"></div>
          </div>
          <div className="logoNewsletterContainer-0-3-369">
            <img src={flogo} className="logo-0-3-377"></img>
            <h1 style={{marginBottom:'1rem',textTransform:'capitalize'}} className="fooenquirform">Enquiry Form</h1>
            <form 
            id="contact" 
            ref={form} 
            // onSubmit={sendEmail}
            onSubmit={handleOnSubmit}
            >
            <fieldset>
              <input
                placeholder="First name*"
                type="text"
                tabIndex="1"
                required
                autoFocus
                className={`input-0-3-346 ${formErrors.from_name ? "error" : ""}`}
                name='user_name'
              />
              {formErrors.from_name && (
                <span className="error-message">{formErrors.from_name}</span>
              )}
            </fieldset>
            <fieldset>
              <input
                placeholder="Last name*"
                type="text"
                tabIndex="1"
                required
                autoFocus
                className={`styled-input ${formErrors.user_name ? "error" : ""}`}
                name='user_lastname'
              />
              {formErrors.user_name && (
                <span className="error-message">{formErrors.user_name}</span>
              )}
            </fieldset>
            <fieldset>
              <input
                placeholder="Company (if applicable)"
                type="text"
                tabIndex="1"
                required
                autoFocus
                name='user_company'
              />
            </fieldset>
            <fieldset>
              
              <input
                placeholder="Email*"
                type="email"
                tabIndex="2"
                required
                className={`styled-input ${formErrors.user_email ? "error" : ""}`}
                name='user_email'
              />
              {formErrors.user_email && (
                <span className="error-message">{formErrors.user_email}</span>
              )}
            </fieldset>
            <fieldset>
              <input
                placeholder="Phone*"
                type="tel"
                tabIndex="3"
                name='user_phone'
                className={`styled-input ${formErrors.user_phone ? "error" : ""}`}
                // font-family: Satoshi,sans-serif;
              />
              {formErrors.user_phone && (
                <span className="error-message">{formErrors.user_phone}</span>
              )}
            </fieldset>
          
            <fieldset>
              <input
                placeholder="Message"
                type="text"
                tabIndex="3"
                name='user_message'
                className={`styled-input ${formErrors.user_message ? "error" : ""}`}
                // font-family: Satoshi,sans-serif;
              />
              {formErrors.user_message && (
                <span className="error-message">{formErrors.user_message}</span>
              )}
            </fieldset>
            <fieldset>
              <button
                name="submit"
                type="submit"
                id="contact-submit"
                data-submit="...Sending"
              >
                {formSubmitted ? "Sent" : "Submit"}
              </button>
              
            </fieldset>
          </form>
          
          </div>

{/* SOCIAL */}
          <nav className="socialLinksContainer-0-3-299">
            <span className="caption-0-3-231 caption-0-3-307">
              {" "}
              <a
                className="link-0-3-306"
                href="/"
                style={{
                  textTransform: "capitalize",
                  textDecoration: "underline",
                  fontWeight: "600",
                  color:'#005E8C'
                }}
              >
                SOCIAL
              </a>
            </span>
            <ul className="linkList-0-3-310">
              <li>
              <div className="fotermobujer">
                <a
                  href=""
                  target="_blank"
                  rel="noreferrer"
                  className="link-0-3-306"
                  style={{margin:"0px -15px"}}
                >
                <a href="https://www.facebook.com/GVKPowerEngineeringSolutions/"  target="_blank" class="fa fa-facebook"></a>
                <a href="https://www.linkedin.com/company/gvk-power-and-engineering-solutions/" target="_blank" class="fa fa-linkedin"></a>
                <a href="https://www.instagram.com/gvk_power_and_engineering/?igshid=MmVlMjlkMTBhMg%3D%3D&utm_source=qr" target="_blank" class="fa fa-instagram"></a>
                </a>
                </div>
              </li>
          
            </ul>
            
            <div className="copyrightContainer-0-3-300">
            <div className="gvkkkcontactrespon">
            <div className="contactsContainer-0-3-303">
              <span className="caption-0-3-231 caption-0-3-307">
                <a
                  className="link-0-3-306"
                  style={{
                    textTransform: "capitalize",
                    textDecoration: "underline",
                    fontWeight: "600",
                    color:'#005E8C'
                  }}
                >
                  OUR OFFICE 
                </a>
              </span>
              <a
                href=""
                className="link-0-3-306 "
                target="_blank"
                // style={{opacity:'0.5'}}
              >
                GVK HEADQUATERS <br></br>
                <p style={{fontWeight:'400'}}>
                Email: sales@gvkengg.com
                </p>
              </a>
              <br></br>
              <a
                href="#"
                className="link-0-3-306 "
                target="_blank"
                // style={{opacity:'0.5'}}
              >
                #1138, 20th Cross Rd, A Block, Sahakar Nagar, A - Block,
                Bengaluru, Karnataka 560092
              </a>
            </div>
            <br></br>
            <span className="caption-0-3-231 caption-0-3-307">
              {" "}
              <a
                className="link-0-3-306 kkokk"
               
                style={{
                  textTransform: "capitalize",
                  textDecoration: "underline",
                  fontWeight: "600",
                  alignItems:"flex-start",
                  margin:"0px -3px",
                  color:'#005E8C'

                }}
              >
               CONTACT
              </a>
            </span>
            {/* <a className="responsivefooterkan">  */}
          <a
                      href="tel:77605 65553/98445 94600"
              className="link-0-3-306 responsivefooterkan"
              // style={{opacity:'0.5'}}
            >
              <p className="responsivefooterkan">
        +91 77605 65553/+91 98445 94600
        </p>
            </a>
            {/* </a> */}
            <a
              href="mailto:sales@gvkengg.com"
              className="link-0-3-306 responsivefooterkan"
              target="_blank"
              rel="noreferrer"
              // style={{opacity:'0.5'}}
            >
               <p style={{textTransform:'lowercase'}}>
             sales@gvkengg.com
             </p>
            </a>
            </div>
          
          </div>
            <div className="res2023m">
            <ul className="linkList-0-3-310">
              {/* <li>
                <Link className="link-0-3-306" to="/products"></Link>
              </li>
              <li>
                <Link className="link-0-3-306" to="/projects"></Link>
              </li>
              <li>
                <Link className="link-0-3-306" to="/about"></Link>
              </li> */}
              <li>
                {/* <Link className="link-0-3-306" to="/contact" >
                  <a className="yyygvk">
                  © 2023 GVK Engg Sol Pvt Ltd.
                Website by Helica
                  </a>
                </Link> */}
                <Link className="link-0-3-306" to="http://www.helica.in/" target="_blank" >
                <a className="yyygvk">
                  © 2023 GVK POWER
ENGINEERING SOLUTIONS Pvt Ltd.
                  <br></br> Website by Helica
                  </a>
                
                </Link>
              </li>
            </ul>
            </div>
          </nav>
          <div className="copyrightContainer-0-3-300">
            <div className="gvkkkcontactrespon">
            {/* <div className="contactsContainer-0-3-303">
              <span className="caption-0-3-231 caption-0-3-307">
                <a
                  className="link-0-3-306"
                  style={{
                    textTransform: "capitalize",
                    textDecoration: "underline",
                    fontWeight: "600",
                  }}
                >
                  MAPS
                </a>
              </span>
           
              <br></br>
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d31092.199315227754!2d77.5847782!3d13.065884400000002!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae19fd939e52b9%3A0x1ebc7af1761e77ce!2sGVK%20POWER%20AND%20ENGINEERING%20SOLUTIONS!5e0!3m2!1sen!2sin!4v1701151589384!5m2!1sen!2sin" width="100%" height="300"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div> */}
            
            </div>
          
          </div>
          <a
        href="https://wa.me/9591365553"
        class="whatsapp_float"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i class="fa fa-whatsapp whatsapp-icon"></i>
      </a>

          <nav className="productsContainer-0-3-301">
            <span className="caption-0-3-231 caption-0-3-307">
              {/*  */}
            </span>
            <ul className="linkList-0-3-310">
            <div className="copyrightContainer-0-3-300">
            <div className="gvkkkcontactrespon">
            <div className="contactsContainer-0-3-303">
              <span className="caption-0-3-231 caption-0-3-307">
                <a
                  className="link-0-3-306"
                  style={{
                    textTransform: "capitalize",
                    textDecoration: "underline",
                    fontWeight: "600",
                    color:'#005E8C'
                  }}
                >
                  MAPS
                </a>
              </span>
              {/* <a
                href=""
                className="link-0-3-306 "
                target="_blank"
             
              >
                GVK HEADQUATERS <br></br>
                <p style={{fontWeight:'500',textTransform:'lowercase'}}>
                Email: sales@gvkengg.com
                </p>
              </a> */}
              <br></br>
              <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d62184.38160323479!2d77.584686!3d13.065952!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae19fd939e52b9%3A0x1ebc7af1761e77ce!2sGVK%20POWER%20AND%20ENGINEERING%20SOLUTIONS!5e0!3m2!1sen!2sin!4v1701342659304!5m2!1sen!2sin" width="800" height="400" style={{border:'0'}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
            <nav className="resourcesContainer-0-3-304">
            <ul className="linkList-0-3-310">
             
              <li >
                <Link className="link-0-3-306" to="http://www.helica.in/" target="_blank" >
                 <a className="gvkhefootter">
                  © 2023 GVK POWER &
ENGNEERING SOLUTIONS Pvt Ltd.
                  <br></br>
                   Website by 
                   <span style={{color:'black',fontWeight:'500',paddingLeft:'0.2rem'}}>Helica</span>
                  </a> 
                </Link>
              </li>
            </ul>
          </nav>
            </div>
          
          </div>
              
          
            </ul>
          </nav>
          {/* <nav className="projectsContainer-0-3-302">
            <span className="caption-0-3-231 caption-0-3-307">
              <a
                className="link-0-3-306"
                style={{
                  textTransform: "capitalize",
                  textDecoration: "underline",
                  fontWeight: "600",
                }}
              >
                PROJECTS
              </a>
            </span>
            <ul className="linkList-0-3-310">
              <li>
                <Link
                  className="link-0-3-306"
                  to="/projects"
                  onClick={scrollToTop}
                  // style={{opacity:'0.5'}}
                >
                  T-fit
                </Link>
              </li>
              <li>
                <Link
                  className="link-0-3-306"
                  to="/projects"
                  onClick={scrollToTop}
                  // style={{opacity:'0.5'}}
                >
                  Panel Boards
                </Link>
              </li>
              <li>
                <Link
                  className="link-0-3-306"
                  to="/projects"
                  onClick={scrollToTop}
                  // style={{opacity:'0.5'}}
                >
                  Crompton
                </Link>
              </li>
              <li>
                <Link
                  className="link-0-3-306"
                  //  style={{opacity:'0.5'}}
                  to="/projects"
                  onClick={scrollToTop}
                >
                  Elmex
                </Link>
              </li>
              <li>
                <Link
                  className="link-0-3-306"
                  // style={{opacity:'0.5'}}
                  to="/projects"
                  onClick={scrollToTop}
                >
                  Rishab Instruments
                </Link>
              </li>
              <li>
                <Link
                  className="link-0-3-306"
                  //  style={{opacity:'0.5'}}
                  to="/projects"
                  onClick={scrollToTop}
                >
                  L&T Switchgears
                </Link>
              </li>
            </ul>
          </nav> */}
          <div className="contactsContainer-0-3-303">
          
           
            <br></br>
            <div className="gvkmconta">
            <div className="copyrightContainer-0-3-300">
            <div className="contactsContainer-0-3-303">
              <span className="caption-0-3-231 caption-0-3-307">
                <a
                  className="link-0-3-306"
                  style={{
                    textTransform: "capitalize",
                    textDecoration: "underline",
                    fontWeight: "600",
                    color:'#005E8C'
                  }}
                >
                 OUR OFFICE
                </a>
              </span>
              <a
                href=""
                className="link-0-3-306 "
                target="_blank"
                // style={{opacity:'0.5'}}
              >
                GVK HEADQUATERS <br></br>
             
              </a>
             
              <a
                href="#"
                className="link-0-3-306 "
                target="_blank"
                // style={{opacity:'0.5'}}
              >
                #1138, 20th Cross Rd, A Block, Sahakar Nagar, A - Block,
                Bengaluru, Karnataka 560092
              </a>
              <br></br>
              <span className="caption-0-3-231 caption-0-3-307">
              {" "}
              <a
                className="link-0-3-306 kkokk"
               
                style={{
                  textTransform: "capitalize",
                  textDecoration: "underline",
                  fontWeight: "600",
                  alignItems:"flex-start",
                  margin:"0px -3px",
                  color:'#005E8C'

                }}
              >
               CONTACT
              </a>
            </span>
            {/* <a className="responsivefooterkan">  */}
          <a
                      href="tel:77605 65553/98445 94600"
              className="link-0-3-306 responsivefooterkan"
              // style={{opacity:'0.5'}}
            >
              <p className="responsivefooterkan">
        +91 77605 65553/+91 98445 94600
        </p>
            </a>
            {/* </a> */}
            <a
              href="mailto:sales@gvkengg.com"
              className="link-0-3-306 responsivefooterkan"
              target="_blank"
              rel="noreferrer"
              // style={{opacity:'0.5'}}
            >
               <p style={{textTransform:'lowercase'}}>
             sales@gvkengg.com
             </p>
            </a>
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d31092.199315227754!2d77.5847782!3d13.065884400000002!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae19fd939e52b9%3A0x1ebc7af1761e77ce!2sGVK%20POWER%20AND%20ENGINEERING%20SOLUTIONS!5e0!3m2!1sen!2sin!4v1701151589384!5m2!1sen!2sin" width="100%" height="300"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" style={{marginTop:'1rem'}}></iframe>
            </div>
          </div>
          </div>
          <div></div>
          <br></br>
         
          </div>
     
        </section>
        
      </footer>
      {/* <div className="newaddedfooterwhatspap">
      <div
          className="line-0-3-107 horizontal-0-3-108 horizontal-d177-0-3-389"
          style={{
          
            animation: " line 1.25s ease forwards",
            marginTop:'-1.6rem'
          }}
         
        ></div>
        <div style={{height:'5rem'}}>

        </div>
        </div> */}
    </div>
  );
}

export default Footer;
