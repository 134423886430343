import React, { useRef ,useState, useEffect} from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import SwiperCore, { Pagination } from "swiper/core";
SwiperCore.use([Pagination]);

const Stepm = () => {
  const [activeStep, setActiveStep] = useState(0);
  const circleRefs = useRef([]);
  

  useEffect(() => {
    const handleScroll = () => {
      const stepWidth = window.innerWidth;
      const scrollPosition = window.pageXOffset;
      const newActiveStep = Math.floor(scrollPosition / stepWidth);
      setActiveStep(newActiveStep);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  

  const handleCircleClick = (index) => {
    setActiveStep(index);
    circleRefs.current[index].scrollIntoView({ behavior: "smooth" });
  };
  const pagination = {
    clickable: true,
    renderBullet: function (index, className) {
      return '<span class="' + className + '">' + (index + 1) + "</span>";
    },
  };
  return (
    <div>
      {/* Your eight divs */}
      <h3 class="mtitle-0-3-266">GVK 360</h3>
      <div class="mobileSteps-0-3-269 steps-0-3-268">
      <svg id="my-svg" width="300" height="300">
              <circle
                cx="150"
                cy="150"
                r="100"
                fill="#3a3a1f"
                stroke="#cfcfb0"
                strokeWidth="1"
              />
              {Array(8)
                .fill()
                .map((_, index) => (
                  <g key={index}>
                    <circle
                      cx={150 + 100 * Math.sin(((index + 1) * Math.PI) / 4)}
                      cy={150 - 100 * Math.cos(((index + 1) * Math.PI) / 4)}
                      r="10"
                      fill="#3a3a1f"
                      stroke="#cfcfb0"
                      strokeWidth="2"
                      style={{
                        fillOpacity: activeStep === 0 ? 1 : 0.5,
                        strokeOpacity: activeStep === 0 ? 1 : 0.5
                      }}
                    />
                    {activeStep === 0 && (
                      <text
                        x={150 + 100 * Math.sin(((index + 1) * Math.PI) / 4)}
                        y={150 - 100 * Math.cos(((index + 1) * Math.PI) / 4)}
                        textAnchor="middle"
                        dominantBaseline="central"
                        style={{
                          fontSize: "10px",
                          // fontWeight: "bold",
                          fill: "#fff"
                        }}
                      >
                        {index + 1}
                        
                      </text>
                    )}
                  </g>
                ))}
            </svg>
        <div>
        <Swiper
        // pagination={{ clickable: true }}
        // onSlideChange={(swiper) => setActiveStep(swiper.realIndex)}
        slidesPerView={1.2}
      >
       
            <div class="Slider-flickity-0-3-280">
              
              <SwiperSlide>
                <div class="step-0-3-271 selected" style={{ width: "100%" }}>
                  <h4 class="stepTitle-0-3-272">1. Design &amp; Development</h4>
                  <div class="stepCopy-0-3-273 RichText-container-0-3-249">
                    <p>
                      Our process includes custom project drawings, free shop
                      drawings, access to our R&amp;D department, prototypes,
                      concept drawings, fixing recommendations and assistance in
                      finding specialist installers.
                    </p>
                  </div>
                  <div class="line-0-3-107 horizontal-0-3-108 horizontal-d93-0-3-281 stepLine-0-3-274"></div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div class="step-0-3-271" style={{ width: "100%" }}>
                  <h4 class="stepTitle-0-3-272">2. Value Engineering</h4>
                  <div class="stepCopy-0-3-273 RichText-container-0-3-249">
                    <p>
                      We provide detailed technical elaboration of the walls and
                      ceiling concept and installation drawings too. We also
                      provide technical planning documentation for support,
                      clear transparent proposals and investigation and
                      evaluation of technical alternatives.
                    </p>
                  </div>
                  <div class="line-0-3-107 horizontal-0-3-108 horizontal-d97-0-3-285 stepLine-0-3-274"></div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div class="step-0-3-271" style={{ width: "100%" }}>
                  <h4 class="stepTitle-0-3-272">4. Certification</h4>
                  <div class="stepCopy-0-3-273 RichText-container-0-3-249">
                    <p>
                      We invest heavily in testing our products and provide the
                      following certifications and documentation; Acoustic
                      tests, Fire tests, Safety data sheets, 99-year guarantee,
                      Care &amp; maintenance documentation, Project completion
                      certification, Green star certification and fixing systems
                      come complete with engineering certificates.
                    </p>
                  </div>
                  <div class="line-0-3-107 horizontal-0-3-108 horizontal-d101-0-3-289 stepLine-0-3-274"></div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div class="step-0-3-271" style={{ width: "100%" }}>
                  <h4 class="stepTitle-0-3-272">3. Samples</h4>
                  <div class="stepCopy-0-3-273 RichText-container-0-3-249">
                    <p>
                      We have a selection of samples on hand and ready to
                      dispatch. But we also offer a custom sample service so
                      that you can see exactly how your specification will look.
                    </p>
                  </div>
                  <div class="line-0-3-107 horizontal-0-3-108 horizontal-d105-0-3-293 stepLine-0-3-274"></div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div class="step-0-3-271" style={{ width: "100%" }}>
                  <h4 class="stepTitle-0-3-272">5. Local On-Site Support</h4>
                  <div class="stepCopy-0-3-273 RichText-container-0-3-249">
                    <p>
                      The sum of the parts is greater than the whole rings true
                   
                      in the business. Backed up by dedicated R&amp;D,
                      Estimating and Production teams, we strive to be the best
                      on-field every day.
                    </p>
                  </div>
                  <div class="line-0-3-107 horizontal-0-3-108 horizontal-d109-0-3-297 stepLine-0-3-274"></div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div class="step-0-3-271" style={{ width: "100%" }}>
                  <h4 class="stepTitle-0-3-272">6. Execution &amp; Delivery</h4>
                  <div class="stepCopy-0-3-273 RichText-container-0-3-249">
                    <div>
                      <p>
                        We don’t check out once an order is placed. We support
                        you right through until installation and beyond. We
                        provide site-specific delivery options and a high degree
                        of prefabrication. Our customer care team provide
                        support and assistance to ensure that the delivery
                        process is seamless.
                      </p>
                      <p>
                        We have the extensive technical know-how and on-site
                        support as well as custom project drawings that can be
                        supplied with your delivery. Your panels come fully
                        sealed and protected to ensure they arrive in the best
                        condition.
                      </p>
                    </div>
                  </div>
                  <div class="line-0-3-107 horizontal-0-3-108 horizontal-d113-0-3-301 stepLine-0-3-274"></div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div class="step-0-3-271" style={{ width: "100%" }}>
                  <h4 class="stepTitle-0-3-272">
                    7. Exceeding expectations at speed
                  </h4>
                  <div class="stepCopy-0-3-273 RichText-container-0-3-249">
                    <div>
                      <p>
                        We pride ourselves on customer service and aim to exceed
                        expectations on every project and at every stage.
                      </p>
                      <p>
                        <br />
                      </p>
                    </div>
                  </div>
                  <div class="line-0-3-107 horizontal-0-3-108 horizontal-d117-0-3-305 stepLine-0-3-274"></div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div class="step-0-3-271" style={{ width: "100%" }}>
                  <h4 class="stepTitle-0-3-272">8. Celebrate &amp; Review</h4>
                  <div class="stepCopy-0-3-273 RichText-container-0-3-249">
                    <p>
                      We believe in celebrating the wins so we take a moment at
                      the end of each and every project to review, analyse and
                      celebrate.
                    </p>
                  </div>
                  <div class="line-0-3-107 horizontal-0-3-108 horizontal-d121-0-3-309 stepLine-0-3-274"></div>
                </div>
              </SwiperSlide>
            
            </div>
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default Stepm;
