import React from "react";
import "./paneldesc.css";
import Pdesc from "./Pdesc";
import pcc from "../../assets/pcc.png";

function Paneldesc() {
  return (
    <>
      <div className="panelfmain">
        {/* <label className="paneldescline01"></label> */}
        {Pdesc.map((value, index) => (
          <div className="paneldd01">
            <h1>{value.hm}</h1>

            <div className="panelddflex-container">
              <div className="panelddflex-item-left">
                <p>{value.p1}</p>
                <p>{value.p2}</p>
                <p>{value.p3}</p>
                <p>{value.p4}</p>
                <p>{value.p5}</p>
                <p>{value.p6}</p>
              </div>
              <div className="panelddflex-item-right">
                <img src={value.img}></img>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

export default Paneldesc;
