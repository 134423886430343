


import React, { useEffect, useState } from 'react';
import "./Timeline.css"

const Timeline = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const items = [
    {
      year: '1881',
      imageUrl: 'http://i.cdn.ensonhaber.com/resimler/diger/ataturk_3473.jpg',
      h1:' 1.Design & Development',
      description:
        " The electrical industry continuously evolves, driven by innovation and the demand for more efficient and sustainable solutions. Design and development teams must stay up-to-date with the latest technological advancements, such as renewable energy integration, smart grids, and electric vehicles.",
    },
    {
        year: '1881',
        imageUrl: 'http://i.cdn.ensonhaber.com/resimler/diger/ataturk_3473.jpg',
        h1:' 2. Value Engineering',
        description:
          "  In the electrical industry, VE often begins during the early stages of project development. Electrical engineers and experts collaborate to analyze various design options, components, and technologies to identify opportunities for cost savings without compromising quality or safety.",
      },{
        year: '1881',
        imageUrl: 'http://i.cdn.ensonhaber.com/resimler/diger/ataturk_3473.jpg',
        h1:' 3. Samples',
        description:
          " In the manufacturing of electrical components, samples are frequently used for quality control purposes. Samples of circuit boards, semiconductors, and electronic components are rigorously tested for defects, functionality, and adherence to specifications.",
      },{
      year: '1881',
      imageUrl: 'http://i.cdn.ensonhaber.com/resimler/diger/ataturk_3473.jpg',
      h1:' 4. Certification',
      description:
        " Certification holds significant importance in the electrical industry as it serves as a vital mechanism to ensure competency, safety, and compliance with industry standards.",
    },{
        year: '1881',
        imageUrl: 'http://i.cdn.ensonhaber.com/resimler/diger/ataturk_3473.jpg',
        h1:' 5. Local On-Site Support',
        description:
          "Local on-site support is a critical service within the electrical industry, serving as a lifeline for businesses and individuals when electrical issues or emergencies arise.",
      },{
      year: '1881',
      imageUrl: 'http://i.cdn.ensonhaber.com/resimler/diger/ataturk_3473.jpg',
      h1:' 6. Execution & Delivery',
      description:
        "Execution and delivery in the electrical industry are the final stages of a project where careful planning and precision are crucial to ensuring successful outcomes. Once the design and development phases are complete, it's time to bring the electrical project to life. ",
    },
    {
      year: '1881',
      imageUrl: 'http://i.cdn.ensonhaber.com/resimler/diger/ataturk_3473.jpg',
      h1:' 7. Exceeding expectations at speed ',
      description:
        "In the electrical industry, the processes of celebrating and reviewing are integral to fostering continuous improvement, recognizing achievements, and ensuring the highest standards of quality and safety.",
    },
    {
      year: '1881',
      imageUrl: 'http://i.cdn.ensonhaber.com/resimler/diger/ataturk_3473.jpg',
      h1:' 8. Celebrate & Review',
      description:
        "We believe in celebrating the wins so we take a moment at the end of each and every project to review, analyse and celebrate.",
    },
    // Add other items here
  ];

  useEffect(() => {
    const handleScroll = () => {
      const itemElements = document.querySelectorAll('.timeline-item');
      const windowHeight = window.innerHeight;

      itemElements.forEach((item, index) => {
        const { top, bottom } = item.getBoundingClientRect();
        if (top < windowHeight / 2 && bottom > windowHeight / 2) {
          setActiveIndex(index);
        }
      });
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="timeline-container" id="timeline-1">
      {/* Timeline header */}
      {/* ... */}

      <div className="timeline">
        {items.map((item, index) => (
          <div
            key={index}
            className={`timeline-item ${
              index === activeIndex ? 'timeline-item--active' : ''
            }`}
          >
            <div className="timeline__content">
              {/* <img className="timeline__img" src={item.imageUrl} alt={`Timeline item ${index}`} /> */}
              <h2 className="timeline__content-title">{item.h1}</h2>
              <p className="timeline__content-desc">{item.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Timeline;