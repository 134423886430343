import React, { useEffect } from "react";
import project1 from "../assets/project1.png";
import project2 from "../assets/project2.png";
import project3 from "../assets/project3.png";
import svn from "../assets/svn.jpeg";
import shsrt from "../assets/shsrt.jpeg";
import svv from "../assets/svv.jpeg";
import solarhsr from "../assets/solarhsr.jpeg";
import scvj from "../assets/scvj.jpeg";
import schsr from "../assets/schsr.jpeg";
import haverp from "../assets/haverp.jpeg";
import cs from "../assets/cs.png";
// import project1 from "../assets/project1.png"
// import project2 from "../assets/project2.png"
import project5 from "../assets/project5.png";
function FilteredItems() {
  useEffect(() => {
    initFilters();

    function initFilters() {
      const filterButtons = document.querySelectorAll(
        ".filters-controls button"
      );
      if (filterButtons.length > 0) {
        const filtersPanel = document.querySelector(".filters-panel");
        const filterItems = document.querySelectorAll(".filters-panel .item");

        // Handle Filter Buttons
        handleFiltersButtons(filterItems, filtersPanel);
      }

      function handleFiltersButtons(items, panel) {
        filterButtons.forEach((button) => {
          button.addEventListener("click", (e) => {
            // Handle Filter Button
            const activeItem = document.querySelector(
              '.filters-controls button[aria-selected="true"]'
            );
            activeItem.setAttribute("aria-selected", false);
            button.setAttribute("aria-selected", true);

            handleFiltersPanel(items, button, panel);
          });
        });
      }

      function handleFiltersPanel(filterItems, button, filtersPanel) {
        if (filtersPanel) {
          // First Promise: Hide Panel
          return new Promise((resolve, reject) => {
            // Disable Buttons
            filterButtons.forEach((btn) => {
              btn.classList.add("pointer-none");
            });

            // Step 1: Add class 'fadeOutDown'
            filtersPanel.classList.add("fadeOutDown");

            // Step 2: onAnimationEnd Resolve
            filtersPanel.addEventListener(
              "animationend",
              () => {
                resolve("Done!");
              },
              { once: true }
            );
          })

            .then(() => {
              // Second Promise: Show Panel
              return new Promise((resolve, reject) => {
                // Update Filtered Items
                executeFilterCondition(filtersPanel, filterItems, button);

                // Find delay if it exists
                let delay = filtersPanel.dataset.animationDelay;
                delay
                  ? (delay = filtersPanel.dataset.animationDelay)
                  : (delay = 0);

                // Step 1: Add class 'fadeInUp'
                setTimeout(() => {
                  filtersPanel.classList.add("fadeInUp");
                }, delay);

                // Step 2: onAnimationEnd Resolve
                filtersPanel.addEventListener(
                  "animationend",
                  () => {
                    resolve("Done!");
                  },
                  { once: true }
                );
              }).then(() => {
                // Remove classes
                filtersPanel.classList.remove("fadeOutDown");
                filtersPanel.classList.remove("fadeInUp");

                // Enable Buttons
                filterButtons.forEach((btn) => {
                  btn.classList.remove("pointer-none");
                });
              });
            })

            .finally(() => {
              console.log("Promise Ended");
            });
        }
      }

      function executeFilterCondition(filtersPanel, filterItems, button) {
        const currFilter = button.dataset.filter;
        const currItems = filterItems;
        const filteredItems = [];
        const otherItems = [];

        separateFilterItemsByCategory(
          currItems,
          filteredItems,
          otherItems,
          currFilter
        );
        handleFilterItemsResults(filteredItems, otherItems);
        handlePanelResultItems(filtersPanel, filteredItems, otherItems);

        // Recalculate the height of the page for correct ScrollTrigger animations
        // ScrollTrigger.refresh();

        function resetFilterItemsOrderAttributes(item) {
          item.removeAttribute("data-filter-order");
          item.removeAttribute("data-other-order");
        }

        function separateFilterItemsByCategory(
          currItems,
          filteredItems,
          otherItems,
          filterItems
        ) {
          // Function: Separating Items By Selected Category

          currItems.forEach((item) => {
            resetFilterItemsOrderAttributes(item);

            if (currFilter === "all") {
              filteredItems.push(item);
            } else {
              const itemCategories = item.dataset.filterCategories;
              itemCategories.includes(currFilter)
                ? filteredItems.push(item)
                : otherItems.push(item);
            }
          });
        }

        function handleFilterItemsResults(filteredItems, otherItems) {
          // Function: Execute Results

          // Handle Filtered Items
          filteredItems.forEach((item, idx) => {
            item.setAttribute("data-filter-order", idx + 1);
          });

          // Get the length of Filtered Items for starting Other Items Index
          let filteredItemsLength = filteredItems.length;

          // Handle Other Items
          otherItems.forEach((item, idx) => {
            filteredItemsLength += 1;
            item.setAttribute("data-other-order", filteredItemsLength);
          });
        }

        function handlePanelResultItems(
          filtersPanel,
          filteredItems,
          otherItems
        ) {
          /* Function:
           * Recreate Filter Panel with Items Ordered by Selected Category
           * Reorder DOM Items, for styling. [ex: .item:nth-child(3)]
           */

          const fragment = document.createDocumentFragment();
          filteredItems.forEach((item) => {
            fragment.appendChild(item.cloneNode(true));
          });
          otherItems.forEach((item) => {
            fragment.appendChild(item.cloneNode(true));
          });

          filtersPanel.innerHTML = null;
          filtersPanel.appendChild(fragment);
        }
      }
    }
  }, []);

  return (
    <div className="cp_wrapper">
      {/* <h1>Filter Items</h1> */}
      <div className="filters">
        <div className="newfprohect">
          <div class="cp_wrapper">
            {/* <h1>Filter Items</h1> */}
            <div class="filters">
              <div class="filters-controls">
                <button
                  type="button"
                  class="filter-control"
                  data-filter="all"
                  aria-selected="true"
                >
                  All
                </button>
                <button
                  type="button"
                  class="filter-control"
                  data-filter="winter"
                  aria-selected="false"
                >
                  PanelBoard
                </button>
                <button
                  type="button"
                  class="filter-control"
                  data-filter="summer"
                  aria-selected="false"
                >
                  GVK Solar
                </button>
                <button
                  type="button"
                  class="filter-control"
                  data-filter="spring"
                  aria-selected="false"
                >
                  T-Fit
                </button>
                <button
                  type="button"
                  class="filter-control"
                  data-filter="autumn"
                  aria-selected="false"
                >
                  Electrical Works
                </button>
                <button
                  type="button"
                  class="filter-control"
                  data-filter="autumne"
                  aria-selected="false"
                >
                  Elmex
                </button>
                <button
                  type="button"
                  class="filter-control"
                  data-filter="autumnri"
                  aria-selected="false"
                >
                  Rishabh Instruments
                </button>
                <button
                  type="button"
                  class="filter-control"
                  data-filter="autumnlt"
                  aria-selected="false"
                >
                  L&T Switchgear
                </button>
                <button
                  type="button"
                  class="filter-control"
                  data-filter="autumnhwc"
                  aria-selected="false"
                >
                  Havells Wires & cables
                </button>
                <button
                  type="button"
                  class="filter-control"
                  data-filter="autumnkwc"
                  aria-selected="false"
                >
                  Kei Wires & cables
                </button>
                <button
                  type="button"
                  class="filter-control"
                  data-filter="autumnelm"
                  aria-selected="false"
                >
                  Elmeasure
                </button>
                <button
                  type="button"
                  class="filter-control"
                  data-filter="autumnorb"
                  aria-selected="false"
                >
                  Orbit
                </button>
                <button
                  type="button"
                  class="filter-control"
                  data-filter="autumncr"
                  aria-selected="false"
                >
                  Crompton
                </button>
              </div>

              <div
                class="filters-panel grid gap"
                data-columns="4"
                data-animation-delay="250"
              >
                {/* <!-- Filter Item --> */}
                <div class="item" data-filter-categories="summer">
                  <a href="#" class="filteredcard">
                    <div class="item__title"></div>

                    <div class="item__thumbnail">
                      <img src={schsr} alt="Image" />
                    </div>
                    <div class="layout_card-content">
                      <div class="layout_card-content-top">
                        <div
                          style={{ opacity: "1" }}
                          class="margin-bottom margin-xsmall horizontal-align"
                        >
                          <h3
                            text-split=""
                            words-slide-from-right=""
                            class="heading-style-h5 text-style-allcaps text-weight-medium"
                          >
                            5KW ON grid connected Solar Roof Top PV plants, HSR
                            Layout, Bangalore. (Under Net-metering arrangement)
                            <br />
                          </h3>
                          <div class="text-size-small text-style-allcaps"></div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
                <div class="item" data-filter-categories="summer">
                  <a href="#" class="filteredcard">
                    <div class="item__title"></div>

                    <div class="item__thumbnail">
                      <img src={scvj} alt="Image" />
                    </div>
                    <div class="layout_card-content">
                      <div class="layout_card-content-top">
                        <div
                          style={{ opacity: "1" }}
                          class="margin-bottom margin-xsmall horizontal-align"
                        >
                          <h3
                            text-split=""
                            words-slide-from-right=""
                            class="heading-style-h5 text-style-allcaps text-weight-medium"
                          >
                            2.75KW ON grid connected Solar Roof Top PV plants,
                            Vijayanagar, Bangalore. (Under Net-metering
                            arrangement)
                            <br />
                          </h3>
                          <div class="text-size-small text-style-allcaps"></div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>

                <div class="item" data-filter-categories="autumn">
                  <a href="#" class="filteredcard">
                    {/* <div class="item__title">Winter</div> */}
                    <div class="item__thumbnail">
                      <img src={project1} alt="Image" />
                    </div>
                    <div class="layout_card-content">
                      <div class="layout_card-content-top">
                        <div
                          style={{ opacity: "1" }}
                          class="margin-bottom margin-xsmall horizontal-align"
                        >
                          <h3
                            text-split=""
                            words-slide-from-right=""
                            class="heading-style-h5 text-style-allcaps text-weight-medium"
                          >
                            Pan india distributor for T-FIT INSULATION. <br />
                          </h3>
                          <div class="text-size-small text-style-allcaps"></div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>

                <div class="item" data-filter-categories="autumn">
                  <a href="#" class="filteredcard">
                    {/* <div class="item__title">Winter</div> */}
                    <div class="item__thumbnail">
                      <img src={haverp} alt="Image" />
                    </div>
                    <div class="layout_card-content">
                      <div class="layout_card-content-top">
                        <div
                          style={{ opacity: "1" }}
                          class="margin-bottom margin-xsmall horizontal-align"
                        >
                          <h3
                            text-split=""
                            words-slide-from-right=""
                            class="heading-style-h5 text-style-allcaps text-weight-medium"
                          >
                            500KVA x 2 Nos DG Set Installation & Commissioning.{" "}
                            <br />
                          </h3>
                          <div class="text-size-small text-style-allcaps"></div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>

                <div class="item" data-filter-categories="autumn">
                  <a href="#" class="filteredcard">
                    {/* <div class="item__title">Winter</div> */}
                    <div class="item__thumbnail">
                      <img src={project2} alt="Image" />
                    </div>
                    <div class="layout_card-content">
                      <div class="layout_card-content-top">
                        <div
                          style={{ opacity: "1" }}
                          class="margin-bottom margin-xsmall horizontal-align"
                        >
                          <h3
                            text-split=""
                            words-slide-from-right=""
                            class="heading-style-h5 text-style-allcaps text-weight-medium"
                          >
                            Manufactures of electrical & automation panels.{" "}
                            <br />
                          </h3>
                          <div class="text-size-small text-style-allcaps"></div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>

                <div class="item" data-filter-categories="autumn">
                  <a href="#" class="filteredcard">
                    {/* <div class="item__title">Winter</div> */}
                    <div class="item__thumbnail">
                      <img src={project5} alt="Image" />
                    </div>
                    <div class="layout_card-content">
                      <div class="layout_card-content-top">
                        <div
                          style={{ opacity: "1" }}
                          class="margin-bottom margin-xsmall horizontal-align"
                        >
                          <h3
                            text-split=""
                            words-slide-from-right=""
                            class="heading-style-h5 text-style-allcaps text-weight-medium"
                          >
                            Jns neopack india pvt ltd. <br />
                          </h3>
                          <div class="text-size-small text-style-allcaps"></div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>

              

                {/* <!-- Filter Item --> */}
              
             
              
             
                {/* <!-- Filter Item --> */}
             
                {/* <!-- Filter Item --> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FilteredItems;
