import React, { useState, useEffect } from "react";
import "./style.css";
import Home from "./Home/Home";
import styled from "styled-components";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Routes,
  NavLink,
  useLocation
} from "react-router-dom";

import Footer from "./components/Footer/Footer";
// import Navbar from "./components/navbar/Navbar";
import logo from "./components/assets/logo.jpeg";
import "./App.css";
import "./sitcher.css";
import GVKPower from "../src/Home/GVKPower"

import Contact from "./components/contact/Contact";
import Project from "./components/projects/Project";
import Product from "./components/products/Product";
import Tfit from "./components/products/tfit/Tfit";
import Panel from "./components/products/panelb/Panel";
import Elmex from "./components/products/elmex/Elmex";
import Rishab from "./components/products/Rishab/Rishab";
import Terminal from "./components/products/elmex/TerminalBlock/Terminal";
import About from "./components/About/About";
import Solar from "./components/products/elmex/solar/Solar";
import Crompton from "./components/products/crompton/Crompton";
import Paneldesc from "./components/products/panelb/Paneldesc";
import Lt from "./components/products/lt/Lt";
import Loader from "./components/Loader/Loader";
import Sticky from "./components/Sticky/Sticky";
import AnimatedRoutes from "./AnimatedRoutes";
import Nav from "./Nav"
import MyComponent from "./MyComponent";
import ReactGA from 'react-ga';

ReactGA.initialize('G-3WS66ZHFKN');

function App() {
 
  const [isOpen, setIsOpen] = useState(false);



  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);
  const scrollToTop = () => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  };
  const [colorTheme, setColorTheme] = useState("theme-white");

  useEffect(() => {
    const currentThemeColor = localStorage.getItem("theme-color");

    if (currentThemeColor) {
      setColorTheme(currentThemeColor);
    }
  }, []);


  const [ashowNavbar, setaShowNavbar] = useState(true);
  const [prevScrollPos, setPrevScrollPos] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      if (prevScrollPos > currentScrollPos) {
        setaShowNavbar(true);
      } else {
        setaShowNavbar(false);
      }
      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [prevScrollPos]);

  const handleClick = (theme) => {
    setColorTheme(theme);
    localStorage.setItem("theme-color", theme);
    window.scrollTo({top: 0, behavior: 'smooth'});
  };
  const Nav = styled.nav`
  padding: 0 20px;
  top:0;
  position:fixed;
  width:100%;
  min-height: 9vh;
  
  z-index:9;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Logo = styled.h1`
  font-size: 25px;
  color: black;
`;

const Menu = styled.ul`
  list-style: none;
  display: flex;
  margin-top:12px;
  font-size:12px;
  ${'' /* color:red; */}
  cursor:pointer;
  li:nth-child(1) {
    margin: 0px 100px;
  }
  li:nth-child(2) {
    margin: 0px 100px;
  }
  li:nth-child(3) {
    margin: 0px 100px;
  }
  li:nth-child(4) {
    margin: 0px 100px;
  }
  li:nth-child(5) {
    margin: 0px 100px;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;


  const [click, setClick] = React.useState(false);

  const ahandleClick = () => setClick(!click);
  const Close = () => setClick(false);

  const [scrolled, setScrolled] = useState(false);
  const handleLinkClick = () => {
    setClick(false);
  };
  useEffect(() => {
    function handleScroll() {
      if (window.scrollY >= 500) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    }

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  
  const ascrollToTop = () => {
    window.scrollTo(0, 0); // Scrolls to the top of the page
  };
 

useEffect(() => {
  ReactGA.pageview(window.location.pathname + window.location.search);
}, [window.location.pathname, window.location.search]);

  return (
    <div className={`App ${colorTheme}`}>
      <Router scrollRestoration="auto">
    
           <div>
           <header class="header-0-3-3">
     <div className={click ? "main-container" : ""}  onClick={()=>Close()} />
     {/* {ashowNavbar && ( */}
      <nav class="nav-0-3-5"  onClick={e => e.stopPropagation()}>
        <div className="nav-container">
          <NavLink exact to="/" className="nav-logo" onClick={ascrollToTop}>
            <img className="nav-logo-image" src={logo}></img>
            
          </NavLink>
          <ul className={click ? "nav-menu active" : "nav-menu"}>
            {/* <li className="responmenuiov">MENU</li> */}
            {/* <p>MENU</p> */}
          <li className="nav-item">
              <NavLink
                exact
                to="/about"
                activeClassName="active"
               
                id="theme-green"
                 
                    onClick={() => {
                      handleClick();
                      handleLinkClick();
                    }}
                    className={`${
                      colorTheme === "theme-green" ? "active" : ""
                    }`}
              >
                ABOUT
              </NavLink>
            </li>
            
            <li className="nav-item">
              <NavLink
                exact
                to="/products"
                activeClassName="active"
                
                id="theme-blue"
                    
                    onClick={() => {
                      handleClick();
                      handleLinkClick();
                    }}
                    className={`${colorTheme === "theme-blue" ? "active" : ""}`}
              >
                PRODUCTS
              </NavLink>
              
            </li>
           
            <li className="nav-item">
              <NavLink
                exact
                to="/projects"
                activeClassName="active"
                
               id="theme-orange"
                  
                    onClick={() => {
                      handleClick();
                      handleLinkClick();
                    }}
                    className={`${
                      colorTheme === "theme-orange" ? "active" : ""
                    }`}
              >
                PROJECTS
              </NavLink>
            </li>
            {/* <li className="nav-item">
              <NavLink
                exact
                to="/projects"
                activeClassName="active"
                
               id="theme-orange"
                  
                    onClick={() => {
                      handleClick();
                      handleLinkClick();
                    }}
                    className={`${
                      colorTheme === "theme-orange" ? "active" : ""
                    }`}
              >
                PARTNERS
              </NavLink>
            </li> */}
          
            <li className="nav-item">
              <NavLink
                exact
                to="/careers"
                activeClassName="active"
                
                id="theme-blue"
                    
                    onClick={() => {
                      handleClick();
                      handleLinkClick();
                    }}
                    className={`${colorTheme === "theme-blue" ? "active" : ""}`}
              >
                CAREERS
              </NavLink>
              
            </li>
            
            <li className="nav-item">
              <Link
                exact
                to="contact"
                activeClassName="active"
              
               id="theme-black"
                 
                    onClick={() => {
                      handleClick();
                      handleLinkClick();
                    }}
                    className={`${
                      colorTheme === "theme-black" ? "active" : ""
                    }`}
              >
                CONTACT
              </Link>
            </li>
            
            <li className="nav-item last">
          <a
            href="mailto:sales@gvkengg.com"
            className="nav-link"
          >
            Email : sales@gvkengg.com
          </a>
        </li>
        <li className="nav-item last">
          <a
            href="tel:77605 65553/98445 94600"
            className="nav-link"
          >
            Phone : +91 77605 65553/+91 98445 94600
          </a>
        </li>

          </ul>
          <div className="nav-icon" onClick={ahandleClick}>
          <i className={click ? "CLOSE" : "MENU"}>{click ? "CLOSE" : "MENU"}</i>
          </div>
        </div>
      </nav>
      {/* )} */}
      <div class="line-0-3-4"></div>
      </header>
    
    </ div>
    

        <div className="content-box"
        //  onClick={scrollToTop}
         >
          {loading ? (
            <MyComponent />
          ) : (
          <> 
          {/* <Navbar/> */}
          <AnimatedRoutes />
          </>
          )} 
<Sticky />
          <Footer />
        </div>
      </Router>
    </div>
  );
}

export default App;
