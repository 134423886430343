import React from "react";
import "./newproject.css";
import project1 from "../assets/project1.png";
import project2 from "../assets/project2.png";
import project3 from "../assets/project3.png";
import project4 from "../assets/project4.png";
import project5 from "../assets/project5.png";
import project6 from "../assets/project6.png";
import Fade from "react-reveal/Fade";
import FilteredItems from "./FilteredItems";
function Newproject() {
  return (
    <>
      <section class="section_projects">
        <div class="padding-global">
          <div class="container-large _100--vw">
            <div class="padding-bottom padding-xhuge">
              <div class="margin-bottom margin-xxlarge">
                <div class="text-align-center">
                  <div class="max-width-full text-align-left horizontal-align">
                    <div class="margin-bottom">
                      <div
                        data-w-id="b714c474-4cce-e22c-8ec6-3952ed4cb27d"
                        style={{ opacity: "0" }}
                        class="section-number-wrapper margin-bottom margin-medium"
                      >
                        <div class="number-circle">
                          <div class="text-color-black text-size-regular font-satoshi">
                            1
                          </div>
                        </div>
                        <div class="section-name-wrapper">
                          <div class="text-size-small text-colour-grey">
                            Other projects
                          </div>
                        </div>
                      </div>
                      <div class="margin-bottom">
                      <Fade bottom>
                        <h2
                          text-split=""
                          words-slide-from-right=""
                          class="heading-style-h2 _8vw-title"
                          style={{ marginTop: "6rem" }}
                        >
                          PROJECTS
                        </h2>
                        </Fade>
                        {/* <p
                          data-w-id="b714c474-4cce-e22c-8ec6-3952ed4cb287"
                          style={{ opacity: "1", marginTop: "2rem" }}
                          class="text-size-medium max-width-medium text-weight-light"
                        >
                          
                        </p> */}
                      </div>
                    </div>
                    {/* <p
                      data-w-id="b714c474-4cce-e22c-8ec6-3952ed4cb287"
                    style={{opacity:"1"}}
                      class="text-size-medium max-width-medium text-weight-light"
                    >
                      Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been.Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
                    </p> */}
                  </div>
                </div>
              </div>
            <FilteredItems/>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Newproject;
