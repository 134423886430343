import React from "react";
import "./partner.css";
import Swal from "sweetalert2";
import Fade from "react-reveal/Fade";
import emailjs from "emailjs-com";
function Partners() {
  const SERVICE_ID = "service_3rltttk";
  const TEMPLATE_ID = "template_f69a0kh";
  const PUBLIC_KEY = "S0x_nHU6wLPfW9BfK";

  const ahandleOnSubmit = (e) => {
    e.preventDefault();
    emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, e.target, PUBLIC_KEY).then(
      (result) => {
        console.log(result.text);
        Swal.fire({
          icon: "success",
          title: "Message Sent Successfully",
        });
      },
      (error) => {
        console.log(error.text);
        Swal.fire({
          icon: "error",
          title: "Ooops, something went wrong",
          text: error.text,
        });
      }
    );
    e.target.reset();
  };
  return (
    <div className="partnerwithusp">
      <body>
        <section class="sub_header_banner partner-header pr">
          <div class="head_title">
            <div class="container pr">
              <Fade bottom>
                <h1>Partner with us</h1>
              </Fade>
            </div>
          </div>
        </section>

        <section class="partner_with">
          <div class="partner_with_innner">
            <div class="partner_txt">
              <div class="container pr">
                <div class="about_page_row" style={{ marginTop: "1rem" }}>
                  <p>
                    {/* At GVK, we revere and recognise people who are dedicated to
                    their call of duty. If you resonate with our challenger
                    spirit, we welcome you with open arms to join our innovative
                    team */}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="contact_form_partner">
          <div class="container pr">
            <div class="title-bar title-center">
              <h2>
                Fill in the form and share your details with us.<br></br>
                {/* <span>We will get back to you soon!</span> */}
              </h2>
              <div class="dots-line">
                <span></span> <span></span> <span></span>
              </div>
            </div>

            {/* <body class="page"> */}
            {/* <main class="main page__main"> */}
            <form    method="POST"
                  onSubmit={ahandleOnSubmit}>
              <div class="zcwf_row">
                <div class="zcwf_col_lab">
                  <label for="NAME">
                    {" "}
                    Name<span style={{color:'red'}}>*</span>
                  </label>
                </div>
                <div class="zcwf_col_fld">
                  <input
                    type="text"
                    id="NAME"
                    name="puser_name"
                    class="fieldStyle"
                  />
                  <div class="zcwf_col_help"></div>
                </div>
              </div>
              <div class="zcwf_row">
                <div class="zcwf_col_lab">
                  <label >Company</label>
                </div>
                <div class="zcwf_col_fld">
                  <input
                    type="text"
                    id="COBJ1CF15"
                    name="puser_company"
                    class="fieldStyle"
                  />
                  <div class="zcwf_col_help"></div>
                </div>
              </div>
              <div class="zcwf_row">
                <div class="zcwf_col_lab">
                  <label for="Email">Email</label>
                </div>
                <div class="zcwf_col_fld">
                  <input
                    type="text"
                    ftype="email"
                    id="Email"
                    name="puser_email"
                    class="fieldStyle"
                  />
                  <div class="zcwf_col_help"></div>
                </div>
              </div>

              <div class="zcwf_row">
                <div class="zcwf_col_lab">
                  <label >
                    Mobile<span style={{color:'red'}}>*</span>
                  </label>
                </div>
                <div class="zcwf_col_fld">
                  <input
                    type="text"
                    id="COBJ1CF12"
                    name="puser_phone"
                    class="fieldStyle partner_mobile"
                    maxlength="10"
                  />
                  <div class="zcwf_col_help"></div>
                </div>
              </div>

              <div class="zcwf_row">
                <div class="zcwf_col_lab">
                  <label >City</label>
                </div>
                <div class="zcwf_col_fld">
                  <input
                    type="text"
                    id="COBJ1CF8"
                    name="puser_city"
                    class="fieldStyle"
                  />
                  <div class="zcwf_col_help"></div>
                </div>
              </div>
              <div class="zcwf_row">
                <div class="zcwf_col_lab">
                  <label >Segment preference</label>
                </div>
                <div class="zcwf_col_fld">
                  <select
                    class="zcwf_col_fld_slt fieldStyle"
                    id="COBJ1CF16"
                    name="puser_seg"
                  >
                    <option value="-None-">-None-</option>
                    <option value="Residential">Residential</option>
                    <option value="C&amp;I">C&amp;I</option>
                  </select>
                  <div class="zcwf_col_help"></div>
                </div>
              </div>
              <div class="zcwf_row">
                <div class="zcwf_col_lab">
                  <label >Area of interest</label>
                </div>
                <div class="zcwf_col_fld">
                  <select
                    class="zcwf_col_fld_slt fieldStyle"
                    id="COBJ1CF18"
                    name="puser_area"
                  >
                    <option value="-None-">-None-</option>
                    <option value="I&amp;C&#x20;channel&#x20;partner">
                      I&amp;C channel partner
                    </option>
                    <option value="Distributer">Distributer</option>
                    <option value="Business&#x20;Development&#x20;Associate">
                      Business Development Associate
                    </option>
                    <option value="channel&#x20;partner">
                      channel partner
                    </option>
                    <option value="master&#x20;distributor">
                      master distributor
                    </option>
                    <option value="Rooftop&#x20;EPC&#x20;&amp;&#x20;Product">
                      Rooftop EPC &amp; Product
                    </option>
                    <option value="Other">Other</option>
                  </select>
                  <div class="zcwf_col_help"></div>
                </div>
              </div>
              <div class="zcwf_row">
                <div class="zcwf_col_lab">
                  <label >
                    Overall experience 
                  </label>
                </div>
                <div class="zcwf_col_fld">
                  <input
                    type="text"
                    id="COBJ1CF17"
                    name="puser_exp"
                    class="fieldStyle"
                  ></input>
                  <div class="zcwf_col_help"></div>
                </div>
              </div>

             

              <div class="zcwf_row zcwf_row_full zcwf_row_btn">
                <div class="zcwf_col_lab"></div>
                <div class="zcwf_col_fld">
                  <button class="primary-btn form__btn" type="submit">
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </section>
      </body>
    </div>
  );
}

export default Partners;
