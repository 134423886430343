import ntfitc from "../../assets/ntfitc.png";
// import Pcards from "./Pcards";
import plc from "../../assets/plc.png";
import mcc2 from "../../assets/mcc2.png";
import db2 from "../../assets/db2.png";
import pccl from "../../assets/pccl.png";
import imcc from "../../assets/imcc.png";
const Pdesc =[
    {
        id:"test1",
        img:ntfitc,
        hm:"Power control center (pcc)",
        p1:"PCC (Power Control Centre) Panels are used to supervise and control the voltage and power of the power system.",
        p2:"These panels are made of high-quality raw material and are extremely sturdy in fabrication.",
        p3:"A reputed pcc panel manufacturer read all field data received for electrical power panels are designed.",
        p4:"PCC panel and lt panels are made from 14/16 SWG CRCA material semi bolted structure firmly supported.",
        p5:"Ensures desired breaking capacities, temperature rise & IP protection.",
        p6:"Enough Space for incoming & outgoing Cable termination. Outgoing terminals are stud type as per IEC standard."


    }, {
        id:"test2",
        img:plc,
        hm:"motor control center (mcc)",
        p1:"Intelligent communication with PLC, DCS and BMS.      ",
        p2:"Reduced space, commissioning and start up time.",
        p3:"Superior motor protection.",
        p4:"Cost effective approach in saving time.",
        p5:"Optimized productivity.",
        p6:"Intelligent communication with PLC, DCS and BMS ."


    }
    , {
        id:"test3",
        img:mcc2,
        hm:"Programmable logic controller (plc)",
        p1:"Rugged and designed to withstand vibrations, temperature, humidity and noise.        ",
        p2:"PLC has a lot of contacts and low cost and safe.",
        p3:"It has a very faster scan time, it has a fast operating time.",
        p4:"A wide range of control application.",
        p5:"It has capable to communicate with a computer in the plant",
        p6:"It has great computational capabilities ."


    }
    , {
        img:db2,
        hm:"PDistribution Board",
        p1:"Distribution boards are common place in most industrial installations and commercial or residential buildings.       ",
        p2:"Most consist of a panel or enclosure supplied with a single incoming electrical feed cable.",
        p3:"A distribution board is the main electrical supply system for any commercial or residential entity.",
        p4:"The entire supply of electrical power comes from the network to the building via the main feeding cable.",
        p5:"It ensures that electrical supply is distributed in the building.",
        p6:"User-Friendly Human Machine Interface ."


    }
    , {
        img:imcc,
        hm:"Intelligent Motor Control Center(imcc)",
        p1:"(IMCC) is a smart MCC panel to control some or many electric motors in a central location.      ",
        p2:"Imcc have consisted of multiple feeders that have a common power busbar.",
        p3:"Enhanced workability by using a communication capable motor management device (SIMOCODE).",
        p4:"It is the Flexible, Modular motor management system.",
        p5:"Easily and directly connected to the automation system via PROFIBUS or PROFINET.",
        p6:"IMCC combines in just a compact system with all required protection, monitoring, safety, and control functions.."


    }
    , {
        img:pccl,
        hm:"Variable frequency drive (vfd)",
        p1:"Provides smooth motion for applications such as elevators and escalators .     ",
        p2:"Self-diagnostics and communications.        ",
        p3:"Controlled starting, stopping, and acceleration.",
        p4:"Reduces power when not required.        ",
        p5:"Reduces Peak Energy Demand.        ",
        p6:"Advanced overload protection        ."


    }

]

export default Pdesc