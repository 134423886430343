import React from "react";
import "./about.css";
import { useRef, useState, useEffect } from "react";
import Step from "./Step";
import Circle from "./Circle";
import Stepm from "./Stepm";
import { Translate } from "@mui/icons-material";
import Timeline from "./Timeline";
import abb from "../assets/abb.png";
import Fade from 'react-reveal/Fade';
import ab2 from "../assets/ab2.png";
function About() {
  const ref = useRef(null);
  const circleRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const [isCircleFixed, setIsCircleFixed] = useState(false);
  

  const [showText, setShowText] = useState([true, false, false, false, false, false, false, false]);
  const [scale, setScale] = useState(1);
  const [strokeWidth, setStrokeWidth] = useState(2);
  const [activeIndex, setActiveIndex] = useState(null);
  const cref = useRef(null);
  const [cisVisible, setcIsVisible] = useState(false);
  const [activeStep, setActiveStep] = useState(1);
  const [currentSection, setCurrentSection] = useState(1);
  // const circleRef = useRef(null);
  

  const circleStyles = {
    fill: "transparent",
    strokeWidth: strokeWidth,
    stroke: "#cfcfb0",
    strokeWidth: "1%",

  };

  const activeCircleStyles = {
    // fill: "red",
        fill: activeIndex === 0 ? "red" : "black",
    strokeDasharray:"8",
    Stroke:activeIndex === 0? 'blue':'yellow',

    // stroke: "red",
    // stroke
  };

  const filledStrokeStyles = {
    strokeWidth: strokeWidth,
    fill: "#cfcfb0",
    // stroke: "red",
    // stroke:activeIndex === 0? 'blue':'yellow',

  };

  const svgContainerStyles = {
    position: activeIndex === 2 ? "fixed" : "static",
    top: activeIndex === 2 ? "50%" : "auto",
    left: activeIndex === 2 ? "50%" : "auto",
    transform: activeIndex === 2 ? "translate(-50%, -50%)" : "none",
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setcIsVisible(entry.isIntersecting);
      },
      { rootMargin: "1800px 0px 0px 0px" }
    );
    if (cref.current) {
      observer.observe(cref.current);
    }
    return () => {
      if (cref.current) {
        observer.unobserve(cref.current);
      }
    };
  }, []);

  
  return (
    <div>
      <div className="mainaboutpagees">
      <main class="Layout-main-0-3-1 aboutmainpaggge">
        <div class="Layout-inner-0-3-2">
          <div class="tl-edges">
            <div class="tl-wrapper tl-wrapper--mount tl-wrapper-status--entered">
              <div>
                <section class="section-0-3-224 noImage">
                  <section class="Layout-section-0-3-33 gutter grid">
                    <div class="captions-0-3-225"></div>
                    
                    <h1 class="title-0-3-226">   <Fade bottom>SOLUTIONS FOR TOMORROW</Fade></h1>
                    <div class="verticalLine-0-3-227">
                      <div class="line-0-3-107 vertical-0-3-109 vertical-d78-0-3-232"></div>
                    </div>
                    <div class="line-0-3-107 horizontal-0-3-108 horizontal-d81-0-3-235 extend"></div>
                  </section>
                  
                  <div class="imageContainer-0-3-92 imageContainer-d3-0-3-239">
                    <img
                      src={abb}
                      class="image-0-3-94 image-d5-0-3-240"
                    />
                    <picture class="picture-0-3-93">
                      <source data-srcset={abb} />
                      {/* <img
                        data-sizes="auto"
                        class="lazyload image-0-3-94 image-d5-0-3-240"
                      /> */}
                    </picture>
                    <div class="curtain-0-3-241">
                      <div class="leftCurtain-0-3-242 topDown"></div>
                      <div class="rightCurtain-0-3-243 topDown"></div>
                    </div>
                    
                  </div>
                  
                </section>
                
                
                <section id="approach">
                  
                  <div class="section-0-3-244 Layout-section-0-3-33 gutter bottomMargin grid">
                    
                    <h3 class="title-0-3-245">   <Fade bottom>A CONSIDERED APPROACH </Fade></h3>
                    <div class="copy-0-3-246 RichText-container-0-3-249">
                      <div className="aboutdetailsedtext">
                      <Fade bottom>
                        <p>
                         
                        <span style={{color:'#005E8C'}}>Our Story:</span>
                        <br></br> <br></br>
Founded with a passion for illuminating possibilities, GVK Power and Engineering Solutions has been a shining presence in the electrical services industry since 2018. What started as a spark of an idea has grown into a full-fledged powerhouse of electrical solutions. Over the years, we have wired our way into the hearts of our clients, building a reputation for reliability, integrity, and unmatched technical proficiency.
                         <br />
                          <br />
                          GVK Power and Engineering Solutions, your trusted partner for all your electrical needs. We are a dedicated team of experienced and licensed electricians who take pride in delivering high-quality electrical services to Residential, Commercial and Industrial Sectors.
                          <br></br> <br></br>
                          With a commitment to quality, safety, and innovation, we bring a jolt of expertise to every project we undertake.
                        </p>
                       </Fade>
                      </div>
                    </div>
                    
                    <div class="verticalLine-0-3-247">
                      <div class="line-0-3-107 vertical-0-3-109 vertical-d86-0-3-257"></div>
                    </div>
                    <div class="line-0-3-107 horizontal-0-3-108 horizontal-d89-0-3-260 extend" style={{bottom:'0px',transform:'translate(10px, 0px)'}}></div>
                    
                  </div>
                  <Fade bottom>
                  <h2
                          text-split=""
                          words-slide-from-right=""
                          // className="heading-style-h2 _8vw-title"
                          style={{textAlign:'center'}}
                        >
                          our process
                        </h2>
                        </Fade>
            <Timeline/>
            
                </section>
                {/* <div class="line-0-3-107 horizontal-0-3-108 horizontal-d165-0-3-361 extend"></div> */}
             
                <div className="aboutteresdd">
                 <section
                  class="section-0-3-349 Layout-section-0-3-33 gutter bottomMargin grid"
                  id="guarantee"
                >
                     <Fade bottom>
                  <h2 class="title-0-3-350">team</h2>
                  </Fade>
                  <div class="text-0-3-351 page">
                    <div class="RichText-container-0-3-249">
                    <Fade bottom>
                      <p>
                      Our team consists of skilled and certified electricians with years of experience. We are up-to-date with the latest industry standards and technology.
<br></br><br></br>
We prioritize your satisfaction. Our team listens to your needs, offers tailored solutions, and communicates transparently throughout the process.
                      </p>
                      </Fade>
                    </div>
                  </div>
                  {/* <div class="line-0-3-107 horizontal-0-3-108 horizontal-d165-0-3-361 extend"></div> */}
                </section>
                </div>
                <div class="imageContainer-0-3-92 imageContainer-d3-0-3-239" style={{marginTop:'-7rem'}} >
                    <img
                      src={ab2}
                      class="image-0-3-94 image-d5-0-3-240"
                    />
                    <picture class="picture-0-3-93">
                      <source data-srcset={ab2} />
                      {/* <img
                        data-sizes="auto"
                        class="lazyload image-0-3-94 image-d5-0-3-240"
                      /> */}
                    </picture>
                    {/* <div class="curtain-0-3-241">
                      <div class="leftCurtain-0-3-242 topDown"></div>
                      <div class="rightCurtain-0-3-243 topDown"></div>
                    </div> */}
                  </div>
                  
                {/* <section
                  class="section-0-3-349 Layout-section-0-3-33 gutter bottomMargin grid"
                  id="guarantee"
                
                >
                  
                  <h2 class="title-0-3-350"></h2>
                 
                  <div class="line-0-3-107 horizontal-0-3-108 horizontal-d165-0-3-361 extend"></div>
                </section> */}
            

              

                {/* <section class="section-0-3-349 Layout-section-0-3-33 gutter bottomMargin grid">
                  <h2 class="title-0-3-350">EXCEEDING INDUSTRY STANDARDS</h2>
                  <div class="text-0-3-351 page">
                    <div class="RichText-container-0-3-249">
                      <div>
                        <p>
                          We’re proud to be an accredited ‘Australian Trusted
                          Trader’. This internationally recognised, Australian
                          Government accreditation, independently benchmarks
                          Australian businesses for compliant trade practices
                          and secure supply chains against World Customs
                          Organization standards.
                        </p>
                        <p>
                          <br />
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="line-0-3-107 horizontal-0-3-108 horizontal-d237-0-3-447 extend"></div>
                </section> */}
             
              </div>
            </div>
          </div>
        </div>
      </main>
      </div>
    </div>
  );
}

export default About;
