import React, { useEffect } from 'react';
import "./mycom.css"

function MyComponent() {
  useEffect(() => {
    // Execute code when the component is mounted
    setTimeout(() => {
      const loadingElement = document.getElementById('loading');
      if (loadingElement) {
        loadingElement.className = 'slideDown';
      }
    }, 3800);

    setTimeout(() => {
      const loadingCenterElement = document.getElementById('loading-center');
      if (loadingCenterElement) {
        loadingCenterElement.className = 'zoomOut';
      }
    }, 3200);

    setTimeout(() => {
      const block1Element = document.getElementById('block-1');
      if (block1Element) {
        block1Element.className = 'slideInUp';
      }
    }, 3800);
  }, []);

  return (
    <>
    <div className="res-container">
      <div className="res-row">
        <div id="block-1 b3">
          <div id="loader-wrap">
            <div id="loading">
              <div id="loading-center">
                <div id="loading-center-absolute">
                  <div id="object"></div>
                  <div id="loader"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="res-container">
      <div className="res-row">
        <div id="block-1">
          <div id="block-2"></div>
        </div>
      </div>
    </div>
    </>
  );
}

export default MyComponent;
