import React from "react";
import "./lt.css";
import ltlogo from "../../assets/ltlogo.png";
import lt3 from "../../assets/lt3.jpeg";
import lv from "../../assets/lv.png";
import relay from "../../assets/relay.png";
import em from "../../assets/em.png";
import Ldata from "./Ldata";
import { useState } from "react";
import ltd from "../../assets/ltd.png"
// import lt2 from "../../assets/lt2.png"
import contractors from "../../assets/lt/contractors.png";
import emim from "../../assets/lt/emim.png"
import enegy from "../../assets/lt/enegy.png"
import energyma from "../../assets/lt/energyma.png"
import fdp from "../../assets/lt/fdp.png"
import lchangeover from "../../assets/lt/lchangeover.png"
import lta from "../../assets/lt/lta.png"
import mccb from "../../assets/lt/mccb.png"
import pa from "../../assets/lt/pa.png"
import r from "../../assets/lt/r.png"
import relays from "../../assets/lt/relays.png"
import lt from "../../assets/pdfs/lt.pdf"

import ltim from "../../assets/ltim.png";
function Lt() {
  const [show, setShow] = useState(false);
  const [eshow, setEshow] = useState(false);
  const [rshow, setRshow] = useState(false)

  const [lcontainer, setLcontainer] = useState(false);

  const changeBacgroundcolor = () => {
    if (window.scrollY >= 1000) {
      setLcontainer(true);
    } else {
      setLcontainer(false);
    }
  };

  window.addEventListener("scroll", changeBacgroundcolor);

  const productsData = [
    {
      id: "lowVoltageProducts",
      name: "Low Voltage Products",
      description: (
        <>
          Electrical & Automation (E&A), leaders in the low voltage switchgear
          category, is one of the key business portfolios of Larsen & Toubro
          Limited, a major Indian multinational in technology, engineering,
          construction, manufacturing, and financial services, with global
          operations.
          <br />
          <br />
          L&T Electrical & Automation (E&A) is a market leader in the low
          voltage switchgear category. The E&A business comprises low and medium
          voltage switchgear, electrical systems, electrical and automation
          systems integration, energy meters & relays. Its products and
          solutions cater to industries, utilities, infrastructure, building,
          and agriculture segments.
          <br />
          <br />
          The manufacturing operations of E&A’s business are located in and
          around Mumbai, Ahmednagar, Vadodara, Coimbatore and Mysore in India as
          well as in Saudi Arabia, Jebel Ali (Dubai), Kuwait, Malaysia,
          Indonesia and Australia. A major strength of E&A is its in-house
          design and development centre for switchgear as well as a tooling
          facility that designs and manufactures a wide range of high precision
          tools, a pre-requisite for high quality products. E&A continuously
          promotes a culture of experimentation that facilitates innovation and
          creation of intellectual property.
          <br />
          <br />
          E&A promotes good electrical practices in the industry. Its Switchgear
          Training Centres (STCs) at Pune, Lucknow, Coonoor, Delhi, Kolkata and
          Vadodara conduct courses for technicians, customers, engineers,
          professionals and even students for enhancing their knowledge and
          skill on switchgear products.
          <br />
          <br />
          All functions of E&A’s business units across manufacturing locations
          comply with the Information Security Standard - ISO 27001:2005, the
          only international auditable standard for Information Security
          Management Systems (ISMS). It involves stringent audits by Bureau
          Veritas that is globally recognized.
        </>
      ),
      image: ltim,
      productRange: [
        {
          name: "Power Quality Solutions",
          image:
            ltd,
        },
        {
          name: "Air Circuit Breakers",
          image:
          lta,
        },
        {
          name: "Changeover Switch",
          image:
            lchangeover
        },
        {
          name: "Contactors",
          image:
            contractors,
        },
        {
          name: "Final Distribution Products (MCB,RCCB,RCBO,DBs)",
          image:
          fdp,
        },
        {
          name: "Moulded Case Circuit Breakers",
          image:
          mccb,
        },
        {
          name: "Panel Accessories",
          image:
         pa,
        },
        // Add more product range items here
      ],
    },
    {
      id: 2,
      name: "Relays",
      description: (
        <>
          L&T Electrical & Automation (E&A) offers a wide range of Protection, Control and Monitoring Relays. These are Numerical relays suitable for Low Voltage & Medium Voltage power distribution system. These are manufactured at our Mysore Works equipped with modern infrastructure and employing latest testing equipment.
          <br />
          <br />
          We offer relay solution for feeder, motor, generator, and transformer. Additionally, we offer application-specific solutions i.e., power factor correction, differential protection, REF protection, Voltage & Frequency protection, Arc flash protection.
          <br />
          <br />
We have also introduced new state-of-art “The COMP Series” – a complete industrial package for Protection, Control, Metering, and Monitoring Fault data of Air, Vacuum or Gas-Insulated Circuit breaker operated feeders. The COMP Series combines conventional and advanced protection functions, annunciation, metering, monitoring and communication into one, easy-to-configure module.
        </>
      ),
      image: relays,
      productRange: [
        {
          name: "APFC Relays",
          image: r,
        },

        // Add more product range items here
      ],
    },
    {
      id: 3,
      name: "Energy Meters",
      description: (
        <>
       L&T Electrical & Automation (E&A) is a leading player in the electricity metering segment in India. Having entered the market in the early 90s on the strength of its indigenously designed single-phase and poly-phase meters, it has long years of experience in design, manufacturing and supply of electronic meters to Indian utilities. A strong emphasis on design and testing, coupled with the ability to meet utilities’ express and implied needs, has resulted in the development of meters that are accurate, easy to read and more importantly reliable on the Indian distribution network. Our meters and metering solutions have found wide acceptance in India from all major customers including industries, private utilities, electricity boards, transmission companies, and generation companies.
          <br />
          <br />
      
        </>
      ),
      image: enegy,
      productRange: [
        {
          name: "Energy Management Solutions",
          image:
            energyma,
        },
        {
          name: "Industrial Meters",
          image:
            emim,
        },
      ],
    },
  ];
  function scrollToSection(sectionId) {
    const section = document.getElementById(sectionId);

    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  }
  return (
    <>
  
      <section className="section_process">
        <div className="padding-global">
          <div className="container-large _90vw">
            <div className="padding-top padding-xhuge">
              <div className="w-layout-grid process-layout_component">
                <div
                  className="process-layout_content-left"
                  style={{ top: "0%", position: "inherit" }}
                >
                  <div className="margin-bottom margin-medium">
                    <div className="section-number-wrapper margin-bottom margin-medium"></div>
                    <div className="margin-bottom">
                      <h2
                        text-split=""
                        words-slide-from-right=""
                        className="heading-style-h2 _8vw-title"
                        style={{ fontSize: "2.8vw" }}
                      >
                        <img src={ltlogo} 
                        // style={{ width: "4rem" }}
                         className="ltlogomp"></img>
                      </h2>
                      <p style={{marginTop:'1rem',color:'#005E8C',fontWeight:'400'} } className="ltpagedes">LET’S SHAPE A HAPPIER TOMORROW</p>
                    </div>
                  </div>
                  <p
                    style={{ opacity: "1" }}
                    className="text-size-medium max-width-medium text-weight-light"
                  >
                    Larsen & Toubro is a major technology engineering
                    construction manufacturing and financial services
                    conglomerate with global operations. The company is one of
                    the largest and most respected companies in India's private
                    sector. The company operates in three segments Engineering &
                    Construction Segment Electrical & Electronics segment
                    Machinery & Industrial Products and others.
                    <br></br>
                    <br></br>
                    With its extensive range of standard Switchgear products,
                    L&T meets complex and varying needs of customers. L&T
                    believes in delighting customers with its high and
                    consistent quality and service standards.
                    <br></br>
                    <br></br>
                    L&T is India's largest manufacturer of low voltage
                    switchgear and controlgear. Its quality management system
                    for design, development, production, marketing and servicing
                    has received ISO 9001 certification from BVQI, UK.
                  </p>

                  <div className="mainaccp" >
                    <p style={{ color: "#005E8C" }}>PRODUCT RANGE</p>
                    <div className="accordionItem-0-3-239">
                      <button className="accordionButton-0-3-240 selected" onClick={() => scrollToSection('#lowVoltageProducts')}>
                        Low Voltage Products
                        <svg
  className="dropdownIcon-0-3-241"
  width="6"
  height="10"
  viewBox="0 0 6 10"
  fill="none"
>
  <path
    d="M4.99995 5L0 0L-0.699995 0.7L3.59995 5L-0.699995 9.3L0 10L4.99995 5Z"
    fill="currentColor"
  ></path>
</svg>

                      </button>
                      <div className="optionsList-0-3-242">
                        <div className="optionsListInner-0-3-243"></div>
                      </div>
                    </div>
                    <div className="accordionItem-0-3-239">
                      <button className="accordionButton-0-3-240 selected">
                        Energy Meters
                        <svg
  className="dropdownIcon-0-3-241"
  width="6"
  height="10"
  viewBox="0 0 6 10"
  fill="none"
>
  <path
    d="M4.99995 5L0 0L-0.699995 0.7L3.59995 5L-0.699995 9.3L0 10L4.99995 5Z"
    fill="currentColor"
  ></path>
</svg>
                      </button>
                      <div className="optionsList-0-3-242">
                        <div className="optionsListInner-0-3-243"></div>
                      </div>
                    </div>
                    <div className="accordionItem-0-3-239">
                      <button className="accordionButton-0-3-240 selected">
                        Relays
                        <svg
  className="dropdownIcon-0-3-241"
  width="6"
  height="10"
  viewBox="0 0 6 10"
  fill="none"
>
  <path
    d="M4.99995 5L0 0L-0.699995 0.7L3.59995 5L-0.699995 9.3L0 10L4.99995 5Z"
    fill="currentColor"
  ></path>
</svg>
                      </button>
                      <div className="optionsList-0-3-242">
                        <div className="optionsListInner-0-3-243"></div>
                      </div>
                    </div>
                  </div>
                  <div className="button-group margin-top margin-large"></div>
                </div>
                <div className="process-layout_content-right">
                  <div
                    className="process-layout_content-item content-item-2"
                    style={{ color: "white", border: "none" }}
                  >
                    <div className="margin-bottom margin-xsmall">
                      <img src={lt3} className="insideproductim"></img>
                    </div>
                    <div className="margin-bottom margin-xsmall"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="ltmianb">
        <div className="tfitccontainer">
          <div className="card">
            <div className="card-header">
              <img src={lv} alt="rover" />
            </div>
            <div className="card-body" id="ltone">
              <div
                className="accordionItem-0-3-239"
                style={{ border: "none" }}
                onClick={() => setShow(!show)}
              >
                <button className="accordionButton-0-3-240 " style={{color:"#005E8CE5"}}>
                  Low Voltage Products
                  <svg
                    className="dropdownIcon-0-3-241"
                    width="10"
                    height="6"
                    viewBox="0 0 10 6"
                    fill="none"
                    style={{ transform: show ? 'rotate(180deg)' : 'rotate(0deg)' }}

                  >
                    <path
                      d="M5 -4.86374e-05L0 4.99995L0.7 5.69995L5 1.39995L9.3 5.69995L10 4.99995L5 -4.86374e-05Z"
                      fill="currentColor"
                    ></path>
                  </svg>
                </button>
                <div className="optionsList-0-3-242">
                  <div className="optionsListInner-0-3-243"></div>
                </div>
              </div>
              {show && (
                <ul className="moretransform">
                  <h3>
                    {" "}
                    <span>
                      <i
                        className="fa fa-square-o"
                        style={{ fontSize: "14px", paddingRight: "0.5rem" ,color:"#005E8CE5"}}
                      ></i>
                      Power Quality Solutions
                    </span>
                  </h3>
                  <li>etaCON APFC Controller</li>
                  <li>etaPRO Software</li>
                  <li>Capacitor Switching Solutions</li>
                  <li>Harmonic Filtering Solutions</li>
                  <li>etaSYS APFC Panels</li>
                  <li>Power Factor Correction Capacitors</li>
                  <h3>
                    {" "}
                    <span>
                      <i
                        className="fa fa-square-o"
                        style={{ fontSize: "14px", paddingRight: "0.5rem" }}
                      ></i>
                      Air Circuit Breakers
                    </span>
                  </h3>
                  <li>Air Circuit Breakers: OMEGA</li>
                  <li>Air Circuit Breakers: C-Power</li>
                  <h3>
                    {" "}
                    <span>
                      {" "}
                      <i
                        className="fa fa-square-o"
                        style={{ fontSize: "14px", paddingRight: "0.5rem" }}
                      ></i>
                      Changeover Switch
                    </span>
                  </h3>
                  <li>C-line Manual Changeover Switch</li>
                  <li>C-line Motorised Changeover Switch</li>
                  <li>Enclosed ATS</li>
                  <h3>
                    {" "}
                    <span>
                      {" "}
                      <i
                        className="fa fa-square-o"
                        style={{ fontSize: "14px", paddingRight: "0.5rem" }}
                      ></i>
                      Contactors
                    </span>
                  </h3>
                  <li>POWER CONTACTORS</li>
                  <li>CONTROL CONTACTORS</li>
                  <li>Special Purpose Contactor</li>
                  <h3>
                    {" "}
                    <span>
                      <i
                        className="fa fa-square-o"
                        style={{ fontSize: "14px", paddingRight: "0.5rem" }}
                      ></i>
                      Panel Accessories
                    </span>
                  </h3>
                  <li>Cable Ducts</li>
                  <li>LED Indicators, Push Button Actuators & Stations</li>
                  <li>Rotary & Load Break Switches</li>
                  <li>Timers, Time Switches, Supply Monitors & Counters</li>
                  <h3>
                    {" "}
                    <span>
                      <i
                        className="fa fa-square-o"
                        style={{ fontSize: "14px", paddingRight: "0.5rem" }}
                      ></i>
                      Final Distribution Products (MCB, RCCB, RCBO, DBs)
                    </span>
                  </h3>
                  <li>Distribution Board Range</li>
                  <li>Exora</li>
                  <li>Tripper</li>
                  <li>AU Solutions</li>
                  <h3>
                    {" "}
                    <span>
                      <i
                        className="fa fa-square-o"
                        style={{ fontSize: "14px", paddingRight: "0.5rem" }}
                      ></i>
                      Moulded Case Circuit Breakers
                    </span>
                  </h3>
                  <li>Moulded Case Circuit Breakers - dsine</li>
                  <li>DU DY MCCBs</li>
                  <li>SS Enclosures for MCCB</li>
                </ul>
              )}
              <div className="user"></div>
            </div>
          </div>
          <div className="card">
            <div className="card-header">
              <img src={em} alt="ballons" />
            </div>
            <div className="card-body" id="lttwo">
              <div
                className="accordionItem-0-3-239"
                onClick={() => setEshow(!eshow)}
                style={{ cursor: "pointer", border: "none" }}
              >
                <button className="accordionButton-0-3-240 selected" style={{color:"#005E8CE5"}}>
                  enegry meters
                  <svg
                    className="dropdownIcon-0-3-241"
                    width="10"
                    height="6"
                    viewBox="0 0 10 6"
                    fill="none"
                    style={{ transform: eshow ? 'rotate(180deg)' : 'rotate(0deg)' }}

                  >
                    <path
                      d="M5 -4.86374e-05L0 4.99995L0.7 5.69995L5 1.39995L9.3 5.69995L10 4.99995L5 -4.86374e-05Z"
                      fill="currentColor"
                    ></path>
                  </svg>
                </button>
                <div className="optionsList-0-3-242">
                  <div className="optionsListInner-0-3-243"></div>
                </div>
              </div>
              {eshow && (
                <ul className="moretransform">
                  <h3>
                    {" "}
                    <span>
                      <i
                        className="fa fa-square-o"
                        style={{ fontSize: "14px", paddingRight: "0.5rem" }}
                      ></i>
                      Energy Management Solutions
                    </span>
                  </h3>
                  <li>SmartComm EMS</li>
                  <li>etaPRO Software</li>
                  <li>Advanced Multifunction Meter</li>
                  <li>Maximum Demand Controller</li>
                  <li>Multifunction Meter</li>
                  <li>Basic Multifunction Meter</li>

                  <li>Dual Source Meter</li>
                  <li>Energy Meter</li>

                  <li>Energy Meter Counter Type</li>
                  <li>Energy Meter DIN Type</li>
                  <li>Enclosed ATS</li>

                  <li>VAF Meter</li>
                  <li>Single Function Meter</li>
                </ul>
              )}
              <div className="user"></div>
            </div>
          </div>
          <div className="card">
            <div className="card-header">
              {/* <img src={em} alt="ballons" /> */}
              <img src={relay} alt="city" />
            </div>
            <div className="card-body" id="lttwo">
              <div
                className="accordionItem-0-3-239"
                onClick={() => setRshow(!rshow)}
                style={{ cursor: "pointer", border: "none" }}
              >
                <button className="accordionButton-0-3-240 selected" style={{color:"#005E8CE5"}}>
                Relays
                  <svg
                    className="dropdownIcon-0-3-241"
                    width="10"
                    height="6"
                    viewBox="0 0 10 6"
                    fill="none"
                    style={{ transform: rshow ? 'rotate(180deg)' : 'rotate(0deg)' }}

                  >
                    <path
                      d="M5 -4.86374e-05L0 4.99995L0.7 5.69995L5 1.39995L9.3 5.69995L10 4.99995L5 -4.86374e-05Z"
                      fill="currentColor"
                    ></path>
                  </svg>
                </button>
                <div className="optionsList-0-3-242">
                  <div className="optionsListInner-0-3-243"></div>
                </div>
              </div>
              {rshow && (
                <ul className="moretransform">
                  {/* <h3>
                    {" "}
                    <span>
                      <i
                        className="fa fa-square-o"
                        style={{ fontSize: "14px", paddingRight: "0.5rem" }}
                      ></i>
                      
                    </span>
                  </h3> */}
                  <li> APFC Relays</li>
                 
                </ul>
              )}
              <div className="user"></div>
            </div>
          </div>
          {/* <div className="card">
            <div className="card-header">
              <img src={relay} alt="city" />
            </div>
            <div className="card-body" id="ltthree">
              <div
                className="accordionItem-0-3-239"
                style={{ cursor: "pointer", border: "none" }}
              >
                <button className="accordionButton-0-3-240 selected">
                  Relays
                  <svg
                    className="dropdownIcon-0-3-241"
                    width="10"
                    height="6"
                    viewBox="0 0 10 6"
                    fill="none"
                  >
                    <path
                      d="M5 -4.86374e-05L0 4.99995L0.7 5.69995L5 1.39995L9.3 5.69995L10 4.99995L5 -4.86374e-05Z"
                      fill="currentColor"
                    ></path>
                  </svg>
                </button>
                <div className="optionsList-0-3-242">
                  <div className="optionsListInner-0-3-243"></div>
                </div>
              </div>

              <li style={{ textDecoration: "none" }}>
                <i
                  className="fa fa-square-o"
                  style={{ fontSize: "14px", paddingRight: "0.5rem" }}
                ></i>
                APFC Relays
              </li>

              <div className="user"></div>
            </div>
          </div> */}
        </div>

        {/* {Ldata.map((value, index) => ( */}
        <>
          <div id="lowVoltageProducts">
            {productsData.map((product) => (
              <div key={product.id}>
                <div className="ltdetailedproducts">
                  <div className="ltdheadline">{product.name}</div>
                  <img
                    src={product.image}
                    className="ltdimg"
                    alt={product.name}
                  />
                  <p className="ltdh1">{product.description}</p>
                </div>
                <div className="latcontainer">
                  <h1>Product Range</h1>
                  <main className="latgrid">
                    {product.productRange.map((range) => (
                      <article key={range.name}>
                        <img src={range.image} alt={range.name} />
                        <div className="lattext">
                          <h3>{range.name}</h3>
                        </div>
                      </article>
                    ))}
                  </main>
                </div>
              </div>
            ))}
          </div>
          <div className='downlaod-solapnel' >
      
      <a className='havellviewmore' href={lt} target='_blank' style={{cursor:'pointer'}}> 
      Download Brochure
</a>

    </div>
        </>
        {/* ))} */}
      </div>
    
    </>
  );
}

export default Lt;
