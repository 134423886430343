import React from 'react'

// import "./havell.css";
import "../Havells/havell.css";
import orbitlogo from "../../assets/orbitlogo.png"
import orbitm from "../../assets/orbitm.png"
import Havells from "../../assets/pdfs/Orbit.pdf";

import havelllogo from "../../assets/havelllogo.png";
import { useNavigate } from "react-router-dom";
function Orbit() {
    const navigate = useNavigate();
    function handleClick(event) {
      event.preventDefault();
      const sectionId = event.target.getAttribute('data-section-id');
      const section = document.getElementById(sectionId);
      section.scrollIntoView({ behavior: 'smooth' });
    }
    const productItems = [
      
      { title: "HT POWER CABLES", link: "" },
      { title: "LT POWER CABLES", link: "" },
      { title: "LT CONTROL CABLES", link: "" },
      { title: "FIRE SURVIVAL CABLES", link: "" },
     
    ];
  
    
    
  return (
    <div>
          <div className='orbitlomp'>
         <section className="section_process">
          <div className="padding-global">
            <div className="container-large _90vw">
              <div className="padding-top padding-xhuge">
                <div className="w-layout-grid process-layout_component">
                  <div className="process-layout_content-left" style={{top:'0',position:'inherit'}}>
                    <div className="margin-bottom margin-medium">
                      <div className="section-number-wrapper margin-bottom margin-medium">
                      
                      </div>
                      <div className="margin-bottom">
                      <div className="havellcenter-container">
  <h2
    text-split=""
    words-slide-from-right=""
    className='havellogo'
  >
    <img src={orbitlogo} alt="Havell Logo" />
  </h2>
</div>

                        <h2
                          text-split=""
                          words-slide-from-right=""
                          className="heading-style-h2 _8vw-title orbitheading"
                        >
                  Orbit The Smart Choice for Your Home
                        </h2>
                     
                      </div>
                    </div>
                    <p
                      style={{ opacity: "1" ,marginTop:'0.6rem'}}
                      className="text-size-medium max-width-medium text-weight-light"
                    >
            We fabricate and sell a miscellaneous range of wires and cables and our key product in the wires and cables fragment are power cables, control cables, instrumentation cables, solar cables, building wires, flexible cables, flexible single multi core cables, communication cables, and others including welding cables, submersible flat and round cables, rubber cables, etc. other than wires and cables we also deal with other merchandises such as CCTV camera, PVC pipes, switches.
                    </p>
                   
                    <a className='orbitviewmore' href={Havells} target='_blank'> <svg width="27" height="16" viewBox="0 0 37 16" fill="none" xmlns="http://www.w3.org/2000/svg">
{/* <path d="M1 7C0.447715 7 0 7.44772 0 8C0 8.55228 0.447715 9 1 9V7ZM36.7071 8.70711C37.0976 8.31658 37.0976 7.68342 36.7071 7.29289L30.3431 0.928932C29.9526 0.538408 29.3195 0.538408 28.9289 0.928932C28.5384 1.31946 28.5384 1.95262 28.9289 2.34315L34.5858 8L28.9289 13.6569C28.5384 14.0474 28.5384 14.6805 28.9289 15.0711C29.3195 15.4616 29.9526 15.4616 30.3431 15.0711L36.7071 8.70711ZM1 9H36V7H1V9Z" fill="#5C5C5C" fill-opacity="0.8"/> */}
</svg></a>

          <div>
    

            
          </div>
          
                    <div className="button-group margin-top margin-large">
                   
                    </div>
                  </div>
                  <div className="process-layout_content-right">
                   
                    <div
                      className="process-layout_content-item content-item-2"
                      style={{ color: "white",border:'none' }}
                    >
                      <div className="margin-bottom margin-xsmall">
                      <img src={orbitm} className="orbitimagesi" ></img>
                      </div>
                      <div className="margin-bottom margin-xsmall">
                        
                      </div>
                     
                    </div>
                 
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="panelcardsmainsect">
    
        <div className='downlaod-solapnel' >
      
      <a className='havellviewmore' href={Havells} target='_blank' style={{marginTop:'3rem',cursor:'pointer'}}> 
      Download Brochure
</a>

    </div><div className='downlaod-solapnel' >
      
      <a className='havellviewmore' href={Havells} target='_blank' style={{marginTop:'3rem',cursor:'pointer',opacity:'0'}}> 
      Download Brochure
</a>

    </div>
     
        </section>
    </div>
    </div>
  )
}

export default Orbit