import React from "react";
import "./terminal.css";
import el from "../../../assets/el.png";
import producte from "../../../assets/producte.png";
import Tdata from "./Tdata";
import w1 from "../../../assets/terminal/w1.jpg";
import w2 from "../../../assets/terminal/w2.jpg";
import w3 from "../../../assets/terminal/w3.jpg";
import wb1 from "../../../assets/terminal/wb1.png";
import wb3 from "../../../assets/terminal/wb3.png";
import wnb1 from "../../../assets/terminal/wnb1.png";
import wnb2 from "../../../assets/terminal/wnb2.png";
import wnb3 from "../../../assets/terminal/wnb3.png";
import ws1 from "../../../assets/terminal/ws1.png";
import ws2 from "../../../assets/terminal/ws2.png";
import ws3 from "../../../assets/terminal/ws3.png";
import wsh1 from "../../../assets/terminal/wsh1.png";
import wsh2 from "../../../assets/terminal/wsh2.png";
import wsn1 from "../../../assets/terminal/wsn1.png";
import wsn2 from "../../../assets/terminal/wsn2.png";
import wsn3 from "../../../assets/terminal/wsn3.png";
import wss1 from "../../../assets/terminal/wss1.png";
import wss2 from "../../../assets/terminal/wss2.png";
import wss3 from "../../../assets/terminal/wss3.png";
import et from "../../../assets/pdfs/et.pdf"

function Terminal() {
  return (
    <>
        <section className="section_process">
          <div className="padding-global">
            <div className="container-large _90vw">
              <div className="padding-top padding-xhuge">
                <div className="w-layout-grid process-layout_component">
                  <div className="process-layout_content-left" style={{top:'0%',position:'inherit'}}>
                    <div className="margin-bottom margin-medium">
                      <div className="section-number-wrapper margin-bottom margin-medium">
                      
                      </div>
                      <div className="margin-bottom">
                        
                        <h2
                          text-split=""
                          words-slide-from-right=""
                          className="heading-style-h2 _8vw-title"
                         style={{fontSize:'2.5rem'}}
                        >
                          <img src={el}  className="elmexlomps"></img>
                        Elmex
                        </h2>
                        <p className="elmexlompsp">TERMINAL BLOCKS </p>
                        
                      </div>
                    </div>
                    <p
                      style={{ opacity: "1" }}
                      className="text-size-medium max-width-medium text-weight-light"
                    >
                   Terminal blocks are modular, insulated blocks that secure two or more wires together.  Terminal blocks are used to secure and/or terminate wires and, in their simplest form, consist of several individual terminals arranged in a long strip. Terminals are useful for connecting wiring to a ground or, in the case of electrical power, for connecting electrical switches and outlets to the mains.
                   <br></br><br></br>
                   Terminal bodies typically consist of a copper alloy with the same expansion coefficient as the wire intended for use.
                    </p>
                    {/* <div className="terminalaccordian"> */}
                {/* <p style={{color:'#005E8C'}}>PRODUCT RANGE</p> */}
                <div className="mainaccp" >
            <p style={{color:'#005E8C'}}>PRODUCT RANGE</p>
              <div className="accordionItem-0-3-239" 
              // onClick={() => navigate("/elmex/Elmex/SolarProducts")}
              >
                <button className="accordionButton-0-3-240 selected">
                wire (termination) technology
               <svg
  className="dropdownIcon-0-3-241"
  width="6"
  height="10"
  viewBox="0 0 6 10"
  fill="none"
>
  <path
    d="M4.99995 5L0 0L-0.699995 0.7L3.59995 5L-0.699995 9.3L0 10L4.99995 5Z"
    fill="currentColor"
  ></path>
</svg>

                </button>
                <div className="optionsList-0-3-242">
                  <div className="optionsListInner-0-3-243"></div>
                </div>
              </div>
              <div className="accordionItem-0-3-239"  
              // onClick={() => navigate("/elmex/Elmex/TerminalBlocks")}
              >
                <button className="accordionButton-0-3-240 selected">
                feed through application 
               <svg
  className="dropdownIcon-0-3-241"
  width="6"
  height="10"
  viewBox="0 0 6 10"
  fill="none"
>
  <path
    d="M4.99995 5L0 0L-0.699995 0.7L3.59995 5L-0.699995 9.3L0 10L4.99995 5Z"
    fill="currentColor"
  ></path>
</svg>

                </button>
                <div className="optionsList-0-3-242">
                  <div className="optionsListInner-0-3-243"></div>
                </div>
              </div>
              <div className="accordionItem-0-3-239"  
              // onClick={() => navigate("/elmex/Elmex/TerminalBlocks")}
              >
                <button className="accordionButton-0-3-240 selected">
                functional application
               <svg
  className="dropdownIcon-0-3-241"
  width="6"
  height="10"
  viewBox="0 0 6 10"
  fill="none"
>
  <path
    d="M4.99995 5L0 0L-0.699995 0.7L3.59995 5L-0.699995 9.3L0 10L4.99995 5Z"
    fill="currentColor"
  ></path>
</svg>

                </button>
                <div className="optionsList-0-3-242">
                  <div className="optionsListInner-0-3-243"></div>
                </div>
              </div> <div className="accordionItem-0-3-239"  
              // onClick={() => navigate("/elmex/Elmex/TerminalBlocks")}
              >
                <button className="accordionButton-0-3-240 selected">
                melamine
               <svg
  className="dropdownIcon-0-3-241"
  width="6"
  height="10"
  viewBox="0 0 6 10"
  fill="none"
>
  <path
    d="M4.99995 5L0 0L-0.699995 0.7L3.59995 5L-0.699995 9.3L0 10L4.99995 5Z"
    fill="currentColor"
  ></path>
</svg>

                </button>
                <div className="optionsList-0-3-242">
                  <div className="optionsListInner-0-3-243"></div>
                </div>
              </div>
            </div>
                    <div className="button-group margin-top margin-large">
                   
                    </div>
                  </div>
                  <div className="process-layout_content-right">
                   
                    <div
                      className="process-layout_content-item content-item-2"
                      style={{ color: "white",border:'none' }}
                    >
                      <div className="margin-bottom margin-xsmall">
                      <img src={producte} className="insideproductim" ></img>
                      </div>
                      <div className="margin-bottom margin-xsmall">
                        
                      </div>
                     
                    </div>
                 
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="cterminaldesc">
          <div className="terminalcard-details" style={{ marginTop: "px" }}>
            <div className="ricontainer">
              <div className="row">
                <div className="col-12">
                  <div className="rititle-heading" id="terminalonew">
                    wire (termination) technology - screw clamp{" "}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-4 col-sm-4 col-md-4 col-xl-4 col-xs-12">
                  <div className="ricard">
                    <div className="ricard-body">
                      <div className="ricard-image">
                        <img src={w1}></img>
                      </div>
                      <h4 className="ricard-title">
                        Feed Through Terminal - Screw Clamp
                      </h4>
                      <p className="ricard-text ">
                        Universally Mountable Terminals for one input and one
                        output for pin type lug suitable for 0.5 to 95 sq mm
                        conductor size.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-4 col-md-4 col-xl-4 col-xs-12">
                  <div className="ricard">
                    <div className="ricard-body">
                      <div className="ricard-image">
                        <img src={w2}></img>
                      </div>
                      <h4 className="ricard-title">
                        Earth Terminal - Screw Clamp
                      </h4>
                      <p className="ricard-text ">
                        Elmex Earth Terminal Blocks replace conventional bus-bar
                        used for earthing.
                      </p>
                    </div>
                  </div>
                </div>{" "}
                <div className="col-lg-4 col-sm-4 col-md-4 col-xl-4 col-xs-12">
                  <div className="ricard">
                    <div className="ricard-body">
                      <div className="ricard-image">
                        <img src={w3}></img>
                      </div>
                      <h4 className="ricard-title">
                        Multi-Level Terminal - Screw Clamp
                      </h4>
                      <p className="ricard-text ">
                        Multi-Level Terminal Blocks for limited space and high
                        density wiring.
                      </p>
                    </div>
                  </div>
                </div>
                
              </div>
            </div>
            {/* </div> */}
          </div>
          <div className="terminalcard-details" style={{ marginTop: "px" }}>
            <div className="ricontainer">
              <div className="row">
                <div className="col-12">
                  <div className="rititle-heading" id="terminaltwo">
                  wire (termination) technology - spring clamp 
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-4 col-sm-4 col-md-4 col-xl-4 col-xs-12">
                  <div className="ricard">
                    <div className="ricard-body">
                      <div className="ricard-image">
                        <img src={ws1}></img>
                      </div>
                      <h4 className="ricard-title">
                      Fuse Terminal - Spring Clamp

                      </h4>
                      <p className="ricard-text ">
                      Terminal Blocks with built-in safety-fuse lever for over-current protection.

                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-4 col-md-4 col-xl-4 col-xs-12">
                  <div className="ricard">
                    <div className="ricard-body">
                      <div className="ricard-image">
                        <img src={ws2}></img>
                      </div>
                      <h4 className="ricard-title">
                      Micro Terminal - Spring Clamp

                      </h4>
                      <p className="ricard-text ">
                      Compact Terminal Blocks for extremely limited space mounted on TS15 Din Rail.

                      </p>
                    </div>
                  </div>
                </div>{" "}
                <div className="col-lg-4 col-sm-4 col-md-4 col-xl-4 col-xs-12">
                  <div className="ricard">
                    <div className="ricard-body">
                      <div className="ricard-image">
                        <img src={ws3}></img>
                      </div>
                      <h4 className="ricard-title">
                      Multi-Level Terminal - Spring Clamp

                      </h4>
                      <p className="ricard-text ">
                      Multi-Level Terminal Blocks for limited space and high density wiring.

                      </p>
                    </div>
                  </div>
                </div>
                
              </div>
            </div>
            {/* </div> */}
          </div>
          <div className="terminalcard-details" style={{ marginTop: "px" }}>
            <div className="ricontainer">
              <div className="row">
                <div className="col-12">
                  <div className="rititle-heading">
                  wire (termination) technology - stud-screw driver operated
 
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-4 col-sm-4 col-md-4 col-xl-4 col-xs-12">
                  <div className="ricard">
                    <div className="ricard-body">
                      <div className="ricard-image">
                        <img src={wss1}></img>
                      </div>
                      <h4 className="ricard-title">
                      Stud Type Terminal - Screw Driver Operated


                      </h4>
                      <p className="ricard-text ">
                      Terminal Blocks suitable for ring-type / fork-type lug for more secured connection.


                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-4 col-md-4 col-xl-4 col-xs-12">
                  <div className="ricard">
                    <div className="ricard-body">
                      <div className="ricard-image">
                        <img src={wss2}></img>
                      </div>
                      <h4 className="ricard-title">
                      Disconnecting Terminal - Stud Type

                      </h4>
                      <p className="ricard-text ">
                      Terminal Blocks with Disconnection Facility for high make-break, CT Secondary and SCADA applications.


                      </p>
                    </div>
                  </div>
                </div>{" "}
                <div className="col-lg-4 col-sm-4 col-md-4 col-xl-4 col-xs-12">
                  <div className="ricard">
                    <div className="ricard-body">
                      <div className="ricard-image">
                        <img src={wss3}></img>
                      </div>
                      <h4 className="ricard-title">
                      Stud Type Terminal - Shrouded-Screw Driver Operated


                      </h4>
                      <p className="ricard-text ">
                      Fully Enclosed (Shrouded) Terminal Blocks suitable for ring / fork-type lug for more safe and secured connection.

                      </p>
                    </div>
                  </div>
                </div>
                
              </div>
            </div>
            {/* </div> */}
          </div>
          <div className="terminalcard-details" style={{ marginTop: "px" }}>
            <div className="ricontainer">
              <div className="row">
                <div className="col-12">
                  <div className="rititle-heading" id="terminalthree">
                  wire (termination) technology - stud-nut driver operated
 
 
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-4 col-sm-4 col-md-4 col-xl-4 col-xs-12">
                  <div className="ricard">
                    <div className="ricard-body">
                      <div className="ricard-image">
                        <img src={wsn1}></img>
                      </div>
                      <h4 className="ricard-title">
                      Power - Bus-Bar Terminal



                      </h4>
                      <p className="ricard-text ">
                      Terminal Blocks for Power/Bus-Bar/High Current applications suitable for fork-type or ring-type lugs.



                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-4 col-md-4 col-xl-4 col-xs-12">
                  <div className="ricard">
                    <div className="ricard-body">
                      <div className="ricard-image">
                      <img src={wsn2}></img>
                      </div>
                      <h4 className="ricard-title">
                      Panel Mounted Terminal - Screw Clamp


                      </h4>
                      <p className="ricard-text ">
                      Panel Mount Terminals are stackable which can be fixed on the surface with the help of fixing screws at the end



                      </p>
                    </div>
                  </div>
                </div>{" "}
                <div className="col-lg-4 col-sm-4 col-md-4 col-xl-4 col-xs-12">
                  <div className="ricard">
                    <div className="ricard-body">
                      <div className="ricard-image">
                      <img src={wsn3}></img>
                      </div>
                      <h4 className="ricard-title">
                      Stud Type Terminal - Nut Driver Operated



                      </h4>
                      <p className="ricard-text ">
                      Terminal Blocks suitable for ring-type / fork-type lug for more secured connection.


                      </p>
                    </div>
                  </div>
                </div>
                
              </div>
            </div>
            {/* </div> */}
          </div>
          <div className="terminalcard-details" style={{ marginTop: "px" }}>
            <div className="ricontainer">
              <div className="row">
                <div className="col-12">
                  <div className="rititle-heading">
                  wire (termination) technology - stud-screw and nut driver operated
 
 
 
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-4 col-sm-4 col-md-4 col-xl-4 col-xs-12">
                  <div className="ricard">
                    <div className="ricard-body">
                      <div className="ricard-image">
                      <img src={wsn3}></img>
                      </div>
                      <h4 className="ricard-title">
                      Stud Type Terminal - Screw and Nut Driver Operated




                      </h4>
                      <p className="ricard-text ">
                      Terminal Blocks suitable for ring-type / fork-type lug for more secured connection.



                      </p>
                    </div>
                  </div>
                </div>
              
                
              </div>
            </div>
            {/* </div> */}
          </div>
          <div className="terminalcard-details" style={{ marginTop: "px" }}>
            <div className="ricontainer">
              <div className="row">
                <div className="col-12">
                  <div className="rititle-heading">
                  wire (termination) technology - stud-hinged type
 
 
 
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-4 col-sm-4 col-md-4 col-xl-4 col-xs-12">
                  <div className="ricard">
                    <div className="ricard-body">
                      <div className="ricard-image">
                      <img src={wsh1}></img>
                      </div>
                      <h4 className="ricard-title">
                      Stud Type Terminal - Hinged Type




                      </h4>
                      <p className="ricard-text ">
                      Fully Enclosed (Shrouded) Terminal Blocks suitable for ring / fork-type lug for more safe and secured connection.




                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-4 col-md-4 col-xl-4 col-xs-12">
                  <div className="ricard">
                    <div className="ricard-body">
                      <div className="ricard-image">
                      <img src={wsh2}></img>
                      </div>
                      <h4 className="ricard-title">
                      Disconnecting Terminal - Hinged Type



                      </h4>
                      <p className="ricard-text ">
                      Terminal Blocks with Disconnection Facility for high make-break, CT Secondary and SCADA applications.



                      </p>
                    </div>
                  </div>
                </div>{" "}
            
                
              </div>
            </div>
            {/* </div> */}
          </div>

          <div className="terminalcard-details" style={{ marginTop: "px" }}>
            <div className="ricontainer">
              <div className="row">
                <div className="col-12">
                  <div className="rititle-heading">
                  wire (termination) technology - nut bolt connection
 
 
 
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-4 col-sm-4 col-md-4 col-xl-4 col-xs-12">
                  <div className="ricard">
                    <div className="ricard-body">
                      <div className="ricard-image">
                      <img src={wnb1}></img>
                      </div>
                      <h4 className="ricard-title">
                      Power - Bus-Bar Terminal



                      </h4>
                      <p className="ricard-text ">
                      Terminal Blocks for Power/Bus-Bar/High Current applications suitable for fork-type or ring-type lugs.



                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-4 col-md-4 col-xl-4 col-xs-12">
                  <div className="ricard">
                    <div className="ricard-body">
                      <div className="ricard-image">
                      <img src={wnb2}></img>
                      </div>
                      <h4 className="ricard-title">
                      Finger Safe Power Terminal



                      </h4>
                      <p className="ricard-text ">
                      Finger Safe Fully Enclosed Terminal Blocks for high-current applications.




                      </p>
                    </div>
                  </div>
                </div>{" "}
                <div className="col-lg-4 col-sm-4 col-md-4 col-xl-4 col-xs-12">
                  <div className="ricard">
                    <div className="ricard-body">
                      <div className="ricard-image">
                      <img src={wnb3}></img>
                      </div>
                      <h4 className="ricard-title">
                      Panel Mounted Terminal - Nut Bolt Type




                      </h4>
                      <p className="ricard-text ">
                      Panel Mount Terminals are stackable which can be fixed on the surface with the help of fixing screws at the end


                      </p>
                    </div>
                  </div>
                </div>
                
              </div>
            </div>
            {/* </div> */}
          </div>
          <div className="terminalcard-details" style={{ marginTop: "px" }}>
            <div className="ricontainer">
              <div className="row">
                <div className="col-12">
                  <div className="rititle-heading">
                  wire (termination) technology - nut bolt connection
 
 
 
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-4 col-sm-4 col-md-4 col-xl-4 col-xs-12">
                  <div className="ricard">
                    <div className="ricard-body">
                      <div className="ricard-image">
                      <img src={wb1}></img>
                      </div>
                      <h4 className="ricard-title">
                      Power - Bus-Bar Terminal



                      </h4>
                      <p className="ricard-text ">
                      Terminal Blocks for Power/Bus-Bar/High Current applications suitable for fork-type or ring-type lugs.



                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-4 col-md-4 col-xl-4 col-xs-12">
                  <div className="ricard">
                    <div className="ricard-body">
                      <div className="ricard-image">
                      <img src={wb1}></img>
                      </div>
                      <h4 className="ricard-title">
                      Finger Safe Power Terminal



                      </h4>
                      <p className="ricard-text ">
                      Finger Safe Fully Enclosed Terminal Blocks for high-current applications.




                      </p>
                    </div>
                  </div>
                </div>{" "}
                <div className="col-lg-4 col-sm-4 col-md-4 col-xl-4 col-xs-12">
                  <div className="ricard">
                    <div className="ricard-body">
                      <div className="ricard-image">
                      <img src={wb3}></img>
                      </div>
                      <h4 className="ricard-title">
                      Panel Mounted Terminal - Nut Bolt Type




                      </h4>
                      <p className="ricard-text ">
                      Panel Mount Terminals are stackable which can be fixed on the surface with the help of fixing screws at the end


                      </p>
                    </div>
                  </div>
                </div>
                
              </div>
            </div>
            {/* </div> */}
          </div>
          <div className="terminalcard-details" style={{ marginTop: "px" }}>
            <div className="ricontainer">
              <div className="row">
                <div className="col-12">
                  <div className="rititle-heading">
                  wire (termination) technology - brass clamp
 
 
 
 
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-4 col-sm-4 col-md-4 col-xl-4 col-xs-12">
                  <div className="ricard">
                    <div className="ricard-body">
                      <div className="ricard-image">
                      <img src={wsh1}></img>
                      </div>
                      <h4 className="ricard-title">
                      Feed Through Terminal - Brass Clamp




                      </h4>
                      <p className="ricard-text ">
                      Universally Mountable Terminals with Brass Screw Clamp for protection against atmospheric corrosion for pin type lug suitable for 0.5 to 10 sq mm conductor size.




                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-4 col-md-4 col-xl-4 col-xs-12">
                  <div className="ricard">
                    <div className="ricard-body">
                      <div className="ricard-image">
                      <img src={w1}></img>
                      </div>
                      <h4 className="ricard-title">
                      Earth Terminal - Brass Clamp




                      </h4>
                      <p className="ricard-text ">
                      Earthing (Grounding) Terminal Blocks with brass-clamp environments with high atmospheric corrosion.





                      </p>
                    </div>
                  </div>
                </div>{" "}
                <div className="col-lg-4 col-sm-4 col-md-4 col-xl-4 col-xs-12">
                  <div className="ricard">
                    <div className="ricard-body">
                      <div className="ricard-image">
                      <img src={wsh1}></img>
                      </div>
                      <h4 className="ricard-title">
                      Fuse Terminal - Brass Clamp





                      </h4>
                      <p className="ricard-text ">
                      Terminal Blocks with built-in safety-fuse lever for over-current protection.



                      </p>
                    </div>
                  </div>
                </div>
                
              </div>
            </div>
            {/* </div> */}
          </div>
          <div className="terminalcard-details" style={{ marginTop: "px" }}>
            <div className="ricontainer">
              <div className="row">
                <div className="col-12">
                  <div className="rititle-heading" id="terminalfour">
                  feed through appliation
 
 
 
 
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-4 col-sm-4 col-md-4 col-xl-4 col-xs-12">
                  <div className="ricard">
                    <div className="ricard-body">
                      <div className="ricard-image">
                      <img src={w1}></img>
                      </div>
                      <h4 className="ricard-title">
                      Feed Through Terminal - Screw Clamp




                      </h4>
                      <p className="ricard-text ">
                      Universally Mountable Terminals for one input and one output for pin type lug suitable for 0.5 to 95 sq mm conductor size.




                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-4 col-md-4 col-xl-4 col-xs-12">
                  <div className="ricard">
                    <div className="ricard-body">
                      <div className="ricard-image">
                        <img src={wb1}></img>
                      </div>
                      <h4 className="ricard-title">
                      Multi-Level Terminal - Screw Clamp





                      </h4>
                      <p className="ricard-text ">
                      Multi-Level Terminal Blocks for limited space and high density wiring.






                      </p>
                    </div>
                  </div>
                </div>{" "}
                <div className="col-lg-4 col-sm-4 col-md-4 col-xl-4 col-xs-12">
                  <div className="ricard">
                    <div className="ricard-body">
                      <div className="ricard-image">
                      <img src={wsh1}></img>
                      </div>
                      <h4 className="ricard-title">
                      Spring Loaded Terminal






                      </h4>
                      <p className="ricard-text ">
                      Terminal blocks with pre-compressed springs suitable for applications at vibrational affected installation sites.




                      </p>
                    </div>
                  </div>
                </div>
                
              </div>
            </div>
            <div className='downlaod-solapnel' >
      
      <a className='havellviewmore' href={et} target='_blank' style={{marginTop:'3rem',cursor:'pointer'}}> 
      Download Brochure
</a>

    </div>
            {/* </div> */}
          </div>
        </div>
        <div className='downlaod-solapnel' style={{opacity:'0'}} >
      
      <a className='havellviewmore' href={et} target='_blank' style={{marginTop:'3rem',cursor:'pointer'}}> 
      Download Brochure
</a>

    </div>
    </>
  );
}

export default Terminal;
