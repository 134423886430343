

import React from 'react';
import './sticky.css';
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
const Sticky = () => {
 
  const navigate = useNavigate();
  const handleClick = () => {
   
   
        window.scrollTo(0, 0);
  };

  return (
    <div className="sticky-button-container">
         <Link to="/partners">
      <button onClick={handleClick} className="sticky-button">
       Partner with us
      </button>
      </Link>
    </div>
  );
};

export default Sticky;
