import React from "react";
import "./contact.css";
import { useViewportScroll, motion } from "framer-motion";
import Fade from 'react-reveal/Fade';
function Contact() {
  const handleCallClick = (phoneNumber) => {
    window.location.href = `tel:${phoneNumber}`;
  };
  
  return (
    <>
      <div className="contact-header">
        <main className="Layout-main-0-3-1">
          <div className="Layout-inner-0-3-2">
            <div className="tl-edges">
              <div className="tl-wrapper tl-wrapper--mount tl-wrapper-status--entered">
                <div>
                  <section className="Layout-section-0-3-33 gutter bottomMargin grid">
                  <Fade bottom>
                    <h1 className="titleNoImage-0-3-228">
                      BRING YOUR VISION TO LIFE
                    </h1>
                    </Fade>
                    <div className="captionsNoImage-0-3-229">
                    <Fade bottom>
                      <span className="caption-0-3-231">Built to spec</span>
                      <span className="caption-0-3-231">Since. 2018</span>
                      </Fade>
                    </div>
                    <div className="">
                      <div className="contact-line1"></div>
                    </div>
                  </section>
                  <div className="white-line not-visible-mobile about-line" ></div>

                  <section className="section-0-3-236">
                    <div className="header-0-3-243 Layout-section-0-3-33 gutter grid">
                    <Fade bottom>
                      <h2 className="title-0-3-545">SUPPORT AT THE READY</h2>
                      </Fade>
                      <div className="copyContainer-0-3-245">
                        <div
                          className="copy-0-3-246 RichText-container-0-3-248"
                          style={{ margin: "0px 0px 25px" }}
                        >
                          <Fade bottom>
                          <div>
  <p style={{ color: "rgba(92, 92, 92, 0.70)", display: "inline" }}>
    Our team of experienced specialists are ready to take your call. 
  </p>
  <br></br> <br></br>
  <p style={{ color: "rgba(92, 92, 92, 0.70)", display: "inline" }}>To know more information about the products please contact:</p>
  <br></br>
  <br></br>
  <table className="gvkcontact">
  <tr style={{opacity:'0'}}>
    <th>General Enquiries </th>
    <th>GVK Solar</th>
    <th>T-Fit</th>
  </tr>
  <tr>
    <td style={{textDecoration:'underline'}}>General Enquiries</td>
    <td style={{textDecoration:'underline'}}>GVK Solar</td>
    <td style={{textDecoration:'underline'}}> T-Fit</td>
  </tr>
  <tr>
        <td onClick={() => handleCallClick('+919844594600')} style={{cursor:'pointer',fontWeight:'600'}}>+91 9844594600</td>
        <td onClick={() => handleCallClick('+917760565553')} style={{cursor:'pointer',fontWeight:'600'}}>+91 7760565553</td>
        <td onClick={() => handleCallClick('+917760165553')} style={{cursor:'pointer',fontWeight:'600'}}>+91 7760165553</td>
      </tr>
 
</table>
</div>

                          </Fade>
                        </div>
                        {/* <a
                          href="tel:1800835035"
                          target="_blank"
                          rel="noreferrer"
                          className="button-0-3-39"
                        >
                          <span>
                            Call{" "}
                            <span className="buttonLabel-0-3-247">
                              1800 835 035
                            </span>
                          </span>
                          <span className="iconContainer-0-3-43">
                            <svg
                              className="icon-0-3-42"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 18 8"
                            >
                              <polyline
                                vector-effect="non-scaling-stroke"
                                points="12.8,0.6 16.1,4 12.8,7.3"
                                stroke="currentColor"
                                fill="transparent"
                              ></polyline>
                              <line
                                vector-effect="non-scaling-stroke"
                                x1="0"
                                y1="4"
                                x2="16.1"
                                y2="4"
                                stroke="currentColor"
                              ></line>
                            </svg>
                          </span>
                          <div className="clone-0-3-40" aria-hidden="true">
                            <span className="cloneContent-0-3-41">
                              <span>
                                Call
                                <span className="buttonLabel-0-3-247">
                                  1800 835 035
                                </span>
                              </span>
                              <span className="iconContainer-0-3-43">
                                <svg
                                  className="icon-0-3-42"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 18 8"
                                >
                                  <polyline
                                    vector-effect="non-scaling-stroke"
                                    points="12.8,0.6 16.1,4 12.8,7.3"
                                    stroke="currentColor"
                                    fill="transparent"
                                  ></polyline>
                                  <line
                                    vector-effect="non-scaling-stroke"
                                    x1="0"
                                    y1="4"
                                    x2="16.1"
                                    y2="4"
                                    stroke="currentColor"
                                  ></line>
                                </svg>
                              </span>
                            </span>
                            
                          </div>
                          
                        </a> */}
                        
                      </div>
                      
                   
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </main>

        {/* <div className="contact-line"></div> */}
      </div>
    </>
  );
}

export default Contact;
