import React from 'react';
import "./panel.css"

function Pcards() {
  return (
    <div>
           {/* {padata.map((value, index) => ( */}
  
     {/* ))} */}
{/* </section> */}
    </div>
  )
}

export default Pcards