import React from 'react';
import "./careers.css";
import cap from "../assets/cap.jpeg"
import Fade from 'react-reveal/Fade';
function Career() {
  const sectionStyle = {
    backgroundImage: `url(${cap})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat', // Prevent image from repeating
    height: '300px', // Adjust the height to fit the image
  };
  return (
    <div>
    <section className="sub_header_banner about-header pr" style={sectionStyle}>
      <div className="head_title">
        <div className="container pr">
        <Fade bottom>
          <h1>Careers</h1></Fade>
        </div>
      </div>
    </section>
<div className='carrmacss'>
<section class="current_openings" >
<div class=" ">
 <div class="rcontainer pr">
 <div class="title-bar title-center" data-aos="fade-left"  >

 <Fade bottom>
 <h2>Current Openings</h2>
 </Fade>
 <div class="dots-line"><span></span> <span></span> <span></span></div>
 </div>
<div class="openings_txt"   > 
<Fade bottom> 
 <p>If you think you can bring an array of talents to our organization, so feel free to send in your CV to <a href="mailto:gvksolar.engg@gmail.com / sales@gvkengg.com">gvksolar.engg@gmail.com / sales@gvkengg.com</a> with a cover letter. We have vacancies from time to time.
</p>
</Fade>
 </div>
 </div>
 <div class="openings_row" >   
<div class="accordion_tab accordion_4 " >  
<div class="acc-row ">
<a class="min_box-h_tab "><h3> Manager – Sales /Marketing (Solar Products)
</h3></a>
<div class="min_box-tx_tab">  
<div class="faq_txt"> 
       <div class=" ">

<p><strong style={{color:'#5c5c5c'}}>Job Description  :</strong></p>
<ul>
<li >Responsible for generate sales for domestic & Industrial solar thermal and PV products </li>
<li >Responsible for delivering on assigned targets every month </li>
<li >Responsible for generating leads for commercial and industrial solar thermal and PV products
</li>
<li >Designing and rolling out schemes for local markets to generate sales 
</li>

</ul>
<br></br>
<li style={{listStyle:"none"}}>The Marketing/ Sales Executive shall directly report into the Director of the company. </li>
<li style={{listStyle:"none"}}>Compensation and Incentives will be best on-par with experience and industry standards </li>
<p><strong>Qualifications  :</strong></p>
<ul>
<li>Diploma/ B.E with 2-3 Years of Experience selling Solar Products.</li> 

</ul>
<p><strong>Location:</strong></p>
<ul>
<li>Bangalore</li>

</ul>

</div> </div>   </div> </div></div> <div class="clearfix"></div>
</div>
<div class="openings_row" >   
<div class="accordion_tab accordion_4 " >  
<div class="acc-row ">
<a class="min_box-h_tab "><h3> Manager – Sales / Marketing (Wire/Cables/Switchgear)
</h3></a>
<div class="min_box-tx_tab">  
<div class="faq_txt"> 
       <div class=" ">
<br></br>
<p><strong>Retail Sales :</strong></p>
<ul>
<li >Exploring new markets and generating new business leads. </li>



</ul>
<p><strong>Job Description  :</strong></p>
<ul>
<li >Demonstrating & presenting products . </li>
<li>Market research to identify selling possibilities 
</li>
<li>Negotiate/close deals & handle complaints </li>
<li>Actively seek out new sales opportunities through cold calling & networking. 
</li>



</ul>
<p><strong>Qualifications  :</strong></p>
<ul>
<li >Any Graduate/ITI/Diploma/B. E /B. Tech/ Exp. In the same field who can handle channel Sales & retail sales.  </li>

<li style={{listStyle:"none"}}>Able to cultivate lasting relationship with key decision makers. Interpersonal skills and skilful organization of tasks; ability to work with little or no supervision. Good negotiation skills; Confidant personality. 
</li>
</ul>
<li style={{listStyle:"none"}}>Industry Preference: Electrical Products namely Cables & Wires, Switchgears, Electrical Panels, LED</li>
<li>Job Type  : Full-time </li>
<li>Salary : Negotiable/Best in Industry/No bar for deserving candidate.</li>
<li>Required experience :</li>
<ul>
<li >For Diploma/ B.E/B.Tech Minimum 2 -3 years of experience. </li>
<li>For I.T.I Minimum 5 -6 years of experience.
</li>
<li>For Graduate/Exp Minimum 5 -8 years of experience.
 </li>




</ul>
<p><strong>Location:</strong></p>
<ul>
<li>Bangalore</li>

</ul>

</div> </div>   </div> </div></div> <div class="clearfix"></div>
</div>
 </div>
 
</section>
</div>
    </div>
  )
}

export default Career
