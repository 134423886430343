import React from 'react'
import keii from "../../assets/keii.png";
import kei1 from "../../assets/kei1.png";
import kei2 from "../../assets/kei2.png";
import kei3 from "../../assets/kei3.png";
import kei4 from "../../assets/kei4.png";
import havell1 from "../../assets/havell1.png";
import havell2 from "../../assets/havell2.png";
import havell3 from "../../assets/havell3.png";
import Havells from "../../assets/pdfs/Havells.pdf";
import haveels from "../../assets/havells.png"
import "./havell.css";

import havelllogo from "../../assets/havelllogo.png";
import { useNavigate } from "react-router-dom";
function Havell() {
    const navigate = useNavigate();
    function handleClick(event) {
      event.preventDefault();
      const sectionId = event.target.getAttribute('data-section-id');
      const section = document.getElementById(sectionId);
      section.scrollIntoView({ behavior: 'smooth' });
    }
    const productItems = [
      
      { title: "HT POWER CABLES", link: "" },
      { title: "LT POWER CABLES", link: "" },
      { title: "LT CONTROL CABLES", link: "" },
      { title: "FIRE SURVIVAL CABLES", link: "" },
     
    ];
  
    const panelCardItems = [
      { img: havell1, title: "HT POWER CABLES" },
      { img: havell2, title: "LT POWER CABLES" },
      { img: havell2, title: "LT CONTROL CABLES" },
      { img: havell3, title: "FIRE SURVIVAL CABLES" },
      
    ];
    
  return (
    <div>
          <div className='havelslomp'>
         <section className="section_process">
          <div className="padding-global">
            <div className="container-large _90vw">
              <div className="padding-top padding-xhuge">
                <div className="w-layout-grid process-layout_component">
                  <div className="process-layout_content-left" style={{top:'0',position:'inherit'}}>
                    <div className="margin-bottom margin-medium">
                      <div className="section-number-wrapper margin-bottom margin-medium">
                      
                      </div>
                      <div className="margin-bottom">
                      <div className="havellcenter-container">
  <h2
    text-split=""
    words-slide-from-right=""
    className='havellogo'
  >
    <img src={havelllogo} alt="Havell Logo" />
  </h2>
</div>

                        <h2
                          text-split=""
                          words-slide-from-right=""
                          className="heading-style-h2 _8vw-title"
                          style={{marginTop:'0.5rem'}}
                        >
                     Industrial Cables for Multiple Applications and Specifications
                        </h2>
                     
                      </div>
                    </div>
                    <p
                      style={{ opacity: "1" ,marginTop:'0.8rem'}}
                      className="text-size-medium max-width-medium text-weight-light"
                    >
              Cables are an important aspect of any industry and Havells, being India's largest manufacturer of cables and wires, produces a range of industrial cables including Multicore Cables, Low Voltage and High Voltage cables, EHV capable, Extra High Voltage Cables, etc. The cables are manufactured under eco friendly norms and are safe, reliable, fire retardant, fire resistant, lead-free & halogen free.
                    </p>
                   
         
          <div>
          <div className="mainaccp">
  <p style={{ color: '#005E8C' }}>PRODUCT RANGE</p>
  {productItems.map((product, index) => (
    <div className="accordionItem-0-3-239" 
    // onClick={() => navigate(product.link)} 
    key={index}>
      <button className="accordionButton-0-3-240 selected">
        {product.title}
        <svg
  className="dropdownIcon-0-3-241"
  width="6"
  height="10"
  viewBox="0 0 6 10"
  fill="none"
>
  <path
    d="M4.99995 5L0 0L-0.699995 0.7L3.59995 5L-0.699995 9.3L0 10L4.99995 5Z"
    fill="currentColor"
  ></path>
</svg>
      </button>
      <div className="optionsList-0-3-242">
        <div className="optionsListInner-0-3-243"></div>
      </div>
    </div>
  ))}
</div>

            
          </div>
          
                    <div className="button-group margin-top margin-large">
                   
                    </div>
                  </div>
                  <div className="process-layout_content-right">
                   
                    <div
                      className="process-layout_content-item content-item-2"
                      style={{ color: "white",border:'none' }}
                    >
                      <div className="margin-bottom margin-xsmall">
                      <img src={haveels} className="insideproductim" ></img>
                      </div>
                      <div className="margin-bottom margin-xsmall">
                        
                      </div>
                     
                    </div>
                 
                  </div>
                </div>
              </div>
            </div>
          </div>
          
        </section>
        <section className="panelcardsmainsect">
        <section className="havellcards-01">
  {panelCardItems.map((card, index) => (
    <a 
    href="#" key={index} className="havellcard-link">
      <article className="panelcard">
        <figure className="panelcard-img">
          <img src={card.img} alt={card.title} />
        </figure>
        <div className="panelcard-body">
          <h2 className="panelcard-title" style={{ textDecoration: 'none', borderBottom: '0' }}>
            {card.title}
          </h2>
        </div>
      </article>
    </a>
  ))}
  
</section>



        </section>
    </div>
    <p>
<a className='havellviewmore' href={Havells} target='_blank'> <svg width="27" height="16" viewBox="0 0 37 16" fill="none" xmlns="http://www.w3.org/2000/svg">
{/* <path d="M1 7C0.447715 7 0 7.44772 0 8C0 8.55228 0.447715 9 1 9V7ZM36.7071 8.70711C37.0976 8.31658 37.0976 7.68342 36.7071 7.29289L30.3431 0.928932C29.9526 0.538408 29.3195 0.538408 28.9289 0.928932C28.5384 1.31946 28.5384 1.95262 28.9289 2.34315L34.5858 8L28.9289 13.6569C28.5384 14.0474 28.5384 14.6805 28.9289 15.0711C29.3195 15.4616 29.9526 15.4616 30.3431 15.0711L36.7071 8.70711ZM1 9H36V7H1V9Z" fill="#5C5C5C" fill-opacity="0.8"/> */}
</svg></a>
<div className='downlaod-solapnel' >
      
      <a className='havellviewmore' href={Havells} target='_blank' > 
      Download Brochure
</a>

    </div>
</p>
    </div>
  )
}

export default Havell