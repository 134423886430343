import React from "react";
import "./projet.css";
import project from "../assets/project.png";
import projectc from "../assets/projectc.png";
import projectp from "../assets/projectp.png";
import projecte from "../assets/projecte.png";
import projectr from "../assets/projectr.png";
import projectlt from "../assets/projectlt.png";
import { SmoothProvider } from 'react-smooth-scrolling'
import { useViewportScroll, motion } from "framer-motion";
function Project() {
  return (
    <div
    
    >
      {/* <div className="projectmainline"></div> */}
      < motion.main 
        // initial={{opacity: 0}}
        // animate={{opacity: 1}}
        // exit={{opacity:0}}
        // transition={{ duration: 4.5 }}
      className="Layout-main-0-3-1">
        <div className="Layout-inner-0-3-2">
          <div className="tl-edges">
            <div className="tl-wrapper tl-wrapper--mount tl-wrapper-status--entered">
              <div>
                <section className="section-0-3-224">
                  <header className="header-0-3-225">
                    {/* <h1 className="title-0-3-226">Select Projects</h1> */}
                    <h1 className="title-0-4" >Projects</h1>
                  </header>
                  {/* <div className="line-0-3-229"></div> */}

                  {/* <div className="line-0-3-229"></div> */}
                </section>
                <section className="section-0-3-240 Layout-section-0-3-33 gutter grid">
                  <div className="bottomLine-0-3-248">
                    <div className="line-0-3-107 horizontal-0-3-108 horizontal-d97-0-3-283 extend"></div>
                  </div>
                  <span className="tile-0-3-243 large">
                    <div className="imageContainer-0-3-246">
                      <div className="placeholder-0-3-262 fill">
                        <img className="image-0-3-94" src={project}></img>
                      </div>
                    </div>
                    <div className="tileContent-0-3-245">
                      <div className="titleContainer-0-3-256">
                        <div className="titleFieldset-0-3-257">
                          <span className="caption-0-3-228 titleCaption-0-3-251 caption-0-3-250"></span>
                          <span className="title-0-3-253">
                            <div className="placeholder-0-3-262" style={{textTransform:"uppercase",color:'#005E8C'}}>t-fit </div>
                          </span>
                        </div>
                      </div>
                      <div className="summaryContainer-0-3-260"></div>
                      <div className="meta-0-3-254">
                        <span className="caption-0-3-228 caption-0-3-250"></span>
                        <div className="placeholder-0-3-262" style={{color:'rgba(92, 92, 92, 0.70)'}}>
                          T-Fit's class-leading pipe insulation technology: Used
                          across pharmaceutical cleanrooms, food & beverage
                          manufacturing & high temperature industries{" "}
                        </div>
                      </div>
                    </div>
                    <div className="verticalLine-0-3-249">
                      <div className="line-0-3-107 vertical-0-3-109 vertical-d78-0-3-264"></div>
                    </div>
                    <div className="line-0-3-107 horizontal-0-3-108 horizontal-d81-0-3-267 extend mobileLine-0-3-244"></div>
                  </span>
                  <span className="tile-0-3-243">
                    <div className="imageContainer-0-3-246">
                      <div className="placeholder-0-3-262 fill">
                        <img className="image-0-3-94" src={projectc}></img>
                      </div>
                    </div>
                    <div className="tileContent-0-3-245">
                      <div className="titleContainer-0-3-256">
                        <div className="titleFieldset-0-3-257">
                          <span className="caption-0-3-228 titleCaption-0-3-251 caption-0-3-250"></span>
                          <span className="title-0-3-253">
                            <div className="placeholder-0-3-262" style={{textTransform:"uppercase",color:'#005E8C'}}>crompton</div>
                          </span>
                        </div>
                        <div className="productFieldset-0-3-258">
                       
                          <span className="title-0-3-253"></span>
                        </div>
                        <div className="arrowFieldset-0-3-259">
                          <span className="button-0-3-39">
                            <span className="iconContainer-0-3-43">
                              <svg
                                className="icon-0-3-42"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 18 8"
                              >
                                <polyline
                                  vector-effect="non-scaling-stroke"
                                  points="12.8,0.6 16.1,4 12.8,7.3"
                                  stroke="currentColor"
                                  fill="transparent"
                                ></polyline>
                                <line
                                  vector-effect="non-scaling-stroke"
                                  x1="0"
                                  y1="4"
                                  x2="16.1"
                                  y2="4"
                                  stroke="currentColor"
                                ></line>
                              </svg>
                            </span>
                            <div className="clone-0-3-40" aria-hidden="true">
                              <span className="cloneContent-0-3-41">
                                <span className="iconContainer-0-3-43">
                                  <svg
                                    className="icon-0-3-42"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 18 8"
                                  >
                                    <polyline
                                      vector-effect="non-scaling-stroke"
                                      points="12.8,0.6 16.1,4 12.8,7.3"
                                      stroke="currentColor"
                                      fill="transparent"
                                    ></polyline>
                                    <line
                                      vector-effect="non-scaling-stroke"
                                      x1="0"
                                      y1="4"
                                      x2="16.1"
                                      y2="4"
                                      stroke="currentColor"
                                    ></line>
                                  </svg>
                                </span>
                              </span>
                            </div>
                          </span>
                        </div>
                      </div>
                      <div className="summaryContainer-0-3-260"></div>
                      <div className="meta-0-3-254">
                        <span className="caption-0-3-228 caption-0-3-250"></span>
                        <div className="placeholder-0-3-262" style={{color:'rgba(92, 92, 92, 0.70)'}}>
                          experts in industrial and commercial lightings
                        </div>
                      </div>
                    </div>
                    <div className="verticalLine-0-3-249">
                      <div className="line-0-3-107 vertical-0-3-109 vertical-d86-0-3-272"></div>
                    </div>
                    <div className="line-0-3-107 horizontal-0-3-108 horizontal-d89-0-3-275 extend mobileLine-0-3-244"></div>
                  </span>
                  <span className="tile-0-3-243 noPadding">
                    <div className="imageContainer-0-3-246">
                      <div className="placeholder-0-3-262 fill">
                        <img className="image-0-3-94" src={projectp}></img>
                      </div>
                    </div>
                    <div className="tileContent-0-3-245">
                      <div className="titleContainer-0-3-256">
                        <div className="titleFieldset-0-3-257">
                          <span className="caption-0-3-228 titleCaption-0-3-251 caption-0-3-250"></span>
                          <span className="title-0-3-253">
                            <div className="placeholder-0-3-262" style={{textTransform:"uppercase",color:'#005E8C'}}>panel boards</div>
                          </span>
                        </div>
                        <div className="productFieldset-0-3-258">
                     
                          <span className="title-0-3-253"></span>
                        </div>
                        <div className="arrowFieldset-0-3-259">
                          <span className="button-0-3-39">
                            <span className="iconContainer-0-3-43">
                              <svg
                                className="icon-0-3-42"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 18 8"
                              >
                                <polyline
                                  vector-effect="non-scaling-stroke"
                                  points="12.8,0.6 16.1,4 12.8,7.3"
                                  stroke="currentColor"
                                  fill="transparent"
                                ></polyline>
                                <line
                                  vector-effect="non-scaling-stroke"
                                  x1="0"
                                  y1="4"
                                  x2="16.1"
                                  y2="4"
                                  stroke="currentColor"
                                ></line>
                              </svg>
                            </span>
                            <div className="clone-0-3-40" aria-hidden="true">
                              <span className="cloneContent-0-3-41">
                                <span className="iconContainer-0-3-43">
                                  <svg
                                    className="icon-0-3-42"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 18 8"
                                  >
                                    <polyline
                                      vector-effect="non-scaling-stroke"
                                      points="12.8,0.6 16.1,4 12.8,7.3"
                                      stroke="currentColor"
                                      fill="transparent"
                                    ></polyline>
                                    <line
                                      vector-effect="non-scaling-stroke"
                                      x1="0"
                                      y1="4"
                                      x2="16.1"
                                      y2="4"
                                      stroke="currentColor"
                                    ></line>
                                  </svg>
                                </span>
                              </span>
                            </div>
                          </span>
                        </div>
                      </div>
                      <div className="summaryContainer-0-3-260"></div>
                      <div className="meta-0-3-254">
                        <span className="caption-0-3-228 caption-0-3-250"></span>
                        <div className="placeholder-0-3-262" style={{color:'rgba(92, 92, 92, 0.70)'}}>
                          protect branch circuits from overloads and short
                          circuits.
                        </div>
                      </div>
                    </div>
                    <div className="line-0-3-107 horizontal-0-3-108 horizontal-d93-0-3-279 extend mobileLine-0-3-244"></div>
                  </span>
                  <div className="bottomLine-0-3-248">
                    <div className="line-0-3-107 horizontal-0-3-108 horizontal-d97-0-3-283 extend"></div>
                  </div>
                  <span className="tile-0-3-243">
                    <div className="imageContainer-0-3-246">
                      <div className="placeholder-0-3-262 fill">
                        <img className="image-0-3-94" src={projecte}></img>
                      </div>
                    </div>
                    <div className="tileContent-0-3-245">
                      <div className="titleContainer-0-3-256">
                        <div className="titleFieldset-0-3-257">
                          <span className="caption-0-3-228 titleCaption-0-3-251 caption-0-3-250"></span>
                          <span className="title-0-3-253">
                            <div className="placeholder-0-3-262" style={{textTransform:"uppercase",color:'#005E8C'}}>ELMEX</div>
                          </span>
                        </div>
                        <div className="productFieldset-0-3-258">
                        
                          <span className="title-0-3-253"></span>
                        </div>
                        <div className="arrowFieldset-0-3-259">
                          <span className="button-0-3-39">
                            <span className="iconContainer-0-3-43">
                              <svg
                                className="icon-0-3-42"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 18 8"
                              >
                                <polyline
                                  vector-effect="non-scaling-stroke"
                                  points="12.8,0.6 16.1,4 12.8,7.3"
                                  stroke="currentColor"
                                  fill="transparent"
                                ></polyline>
                                <line
                                  vector-effect="non-scaling-stroke"
                                  x1="0"
                                  y1="4"
                                  x2="16.1"
                                  y2="4"
                                  stroke="currentColor"
                                ></line>
                              </svg>
                            </span>
                            <div className="clone-0-3-40" aria-hidden="true">
                              <span className="cloneContent-0-3-41">
                                <span className="iconContainer-0-3-43">
                                  <svg
                                    className="icon-0-3-42"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 18 8"
                                  >
                                    <polyline
                                      vector-effect="non-scaling-stroke"
                                      points="12.8,0.6 16.1,4 12.8,7.3"
                                      stroke="currentColor"
                                      fill="transparent"
                                    ></polyline>
                                    <line
                                      vector-effect="non-scaling-stroke"
                                      x1="0"
                                      y1="4"
                                      x2="16.1"
                                      y2="4"
                                      stroke="currentColor"
                                    ></line>
                                  </svg>
                                </span>
                              </span>
                            </div>
                          </span>
                        </div>
                      </div>
                      <div className="summaryContainer-0-3-260"></div>
                      <div className="meta-0-3-254">
                        <span className="caption-0-3-228 caption-0-3-250"></span>
                        <div className="placeholder-0-3-262" style={{color:'rgba(92, 92, 92, 0.70)'}}>
                          Elmex is pioneer in electrical wire termination
                          technology from India. Started in 1963, Elmex made
                          terminal blocks are certified by ASTA, DEKRA and carry
                          certification of CE, UL, cUL, ATEX, and Gost -R.
                        </div>
                       
                      </div>
                    </div>
                    <div className="verticalLine-0-3-249">
                      <div className="line-0-3-107 vertical-0-3-109 vertical-d102-0-3-288"></div>
                    </div>
                    <div className="line-0-3-107 horizontal-0-3-108 horizontal-d105-0-3-291 extend mobileLine-0-3-244"></div>
                  </span>
                  <span className="tile-0-3-243">
                    <div className="imageContainer-0-3-246">
                      <div className="placeholder-0-3-262 fill">
                        <img className="image-0-3-94" src={projectr}></img>
                      </div>
                    </div>
                    <div className="tileContent-0-3-245">
                      <div className="titleContainer-0-3-256">
                        <div className="titleFieldset-0-3-257">
                          <span className="caption-0-3-228 titleCaption-0-3-251 caption-0-3-250"></span>
                          <span className="title-0-3-253">
                            <div className="placeholder-0-3-262" style={{textTransform:"uppercase",color:'#005E8C'}}>
                              rishab instruments
                            </div>
                          </span>
                        </div>
                        <div className="productFieldset-0-3-258">
                     
                          <span className="title-0-3-253"></span>
                        </div>
                        <div className="arrowFieldset-0-3-259">
                          <span className="button-0-3-39">
                            <span className="iconContainer-0-3-43">
                              <svg
                                className="icon-0-3-42"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 18 8"
                              >
                                <polyline
                                  vector-effect="non-scaling-stroke"
                                  points="12.8,0.6 16.1,4 12.8,7.3"
                                  stroke="currentColor"
                                  fill="transparent"
                                ></polyline>
                                <line
                                  vector-effect="non-scaling-stroke"
                                  x1="0"
                                  y1="4"
                                  x2="16.1"
                                  y2="4"
                                  stroke="currentColor"
                                ></line>
                              </svg>
                            </span>
                            <div className="clone-0-3-40" aria-hidden="true">
                              <span className="cloneContent-0-3-41">
                                <span className="iconContainer-0-3-43">
                                  <svg
                                    className="icon-0-3-42"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 18 8"
                                  >
                                    <polyline
                                      vector-effect="non-scaling-stroke"
                                      points="12.8,0.6 16.1,4 12.8,7.3"
                                      stroke="currentColor"
                                      fill="transparent"
                                    ></polyline>
                                    <line
                                      vector-effect="non-scaling-stroke"
                                      x1="0"
                                      y1="4"
                                      x2="16.1"
                                      y2="4"
                                      stroke="currentColor"
                                    ></line>
                                  </svg>
                                </span>
                              </span>
                            </div>
                          </span>
                        </div>
                      </div>
                      <div className="summaryContainer-0-3-260"></div>
                      <div className="meta-0-3-254">
                        <span className="caption-0-3-228 caption-0-3-250"></span>
                        <div className="placeholder-0-3-262" style={{color:'rgba(92, 92, 92, 0.70)'}}>
                          PFC (power factor controller) is a feature included in
                          some computer and other power supply boxes that
                          reduces the amount of reactive power generated by a
                          computer.
                        </div>
                      </div>
                    </div>
                    <div className="verticalLine-0-3-249">
                      <div className="line-0-3-107 vertical-0-3-109 vertical-d110-0-3-296"></div>
                    </div>
                    <div className="line-0-3-107 horizontal-0-3-108 horizontal-d113-0-3-299 extend mobileLine-0-3-244"></div>
                  </span>
                  <span className="tile-0-3-243 large noPadding">
                    <div className="imageContainer-0-3-246">
                      <div className="placeholder-0-3-262 fill">
                        <img className="image-0-3-94" src={projectlt}></img>
                      </div>
                    </div>
                    <div className="tileContent-0-3-245">
                      <div className="titleContainer-0-3-256">
                        <div className="titleFieldset-0-3-257">
                          <span className="caption-0-3-228 titleCaption-0-3-251 caption-0-3-250"></span>
                          <span className="title-0-3-253">
                            <div className="placeholder-0-3-262" style={{textTransform:"uppercase",color:'#005E8C'}}>
                              l&t switchgears
                            </div>
                          </span>
                        </div>
                        <div className="productFieldset-0-3-258">
                          
                          <span className="title-0-3-253"></span>
                        </div>
                        <div className="arrowFieldset-0-3-259">
                          <span className="button-0-3-39">
                            <span className="iconContainer-0-3-43">
                              <svg
                                className="icon-0-3-42"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 18 8"
                              >
                                <polyline
                                  vector-effect="non-scaling-stroke"
                                  points="12.8,0.6 16.1,4 12.8,7.3"
                                  stroke="currentColor"
                                  fill="transparent"
                                ></polyline>
                                <line
                                  vector-effect="non-scaling-stroke"
                                  x1="0"
                                  y1="4"
                                  x2="16.1"
                                  y2="4"
                                  stroke="currentColor"
                                ></line>
                              </svg>
                            </span>
                            <div className="clone-0-3-40" aria-hidden="true">
                              <span className="cloneContent-0-3-41">
                                <span className="iconContainer-0-3-43">
                                  <svg
                                    className="icon-0-3-42"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 18 8"
                                  >
                                    <polyline
                                      vector-effect="non-scaling-stroke"
                                      points="12.8,0.6 16.1,4 12.8,7.3"
                                      stroke="currentColor"
                                      fill="transparent"
                                    ></polyline>
                                    <line
                                      vector-effect="non-scaling-stroke"
                                      x1="0"
                                      y1="4"
                                      x2="16.1"
                                      y2="4"
                                      stroke="currentColor"
                                    ></line>
                                  </svg>
                                </span>
                              </span>
                            </div>
                          </span>
                        </div>
                      </div>
                      <div className="summaryContainer-0-3-260"></div>
                      <div className="meta-0-3-254">
                        <span className="caption-0-3-228 caption-0-3-250"></span>
                        <div className="placeholder-0-3-262" style={{color:'rgba(92, 92, 92, 0.70)'}}>
                          L&T is India's largest manufacturer of low voltage
                          switchgear and controlgear.
                        </div>
                      </div>
                    </div>
                    <div className="line-0-3-107 horizontal-0-3-108 horizontal-d117-0-3-303 extend mobileLine-0-3-244"></div>
                  </span>
                  <div className="bottomLine-0-3-248">
                    <div className="line-0-3-107 horizontal-0-3-108 horizontal-d121-0-3-307 extend"></div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </motion.main>
    </div>
  );
}

export default Project;
