import React from "react";
import rilogo from "../../assets/rilogo.png";
import "./rishab.css";
import ps from "../../assets/rishab/ps.png";
import ct from "../../assets/rishab/ct1.png";
import ct1 from "../../assets/rishab/ct2.png";
import ct3 from "../../assets/rishab/ct3.png";
import dm1 from "../../assets/rishab/dm1.png";
import dm2 from "../../assets/rishab/dm2.png";
import dm3 from "../../assets/rishab/dm3.png";
import et1 from "../../assets/rishab/et1.png";
import et2 from "../../assets/rishab/et2.png";
import mmc from "../../assets/rishab/mmc.png";
import ps1 from "../../assets/rishab/ps1.png";
import rironmeter from "../../assets/rishab/rironmeter.png";
import rishab2 from "../../assets/rishab/rishab2.png";
import rishab3 from "../../assets/rishab/rishab3.png";
import rishabet from "../../assets/rishab/rishabet.png";
import ri from "../../assets/pdfs/ri.pdf"

function Card(props) {
  return (
    <>
      {/* {props.details.map((value, index) => ( */}
        <div className="rishabcard-details">
          <div className="ricontainer" 
          // style={{ marginTop: "30px" }}
          >
            <div className="row">
              <div className="col-12" id="rishabanalog">
                <div className="rititle-heading">analog panel meters</div>
              </div>
            </div>
            <div className="row" >
              <div className="col-lg-4 col-sm-4 col-md-4 col-xl-4 col-xs-12">
                <div className="ricard">
                  <div className="ricard-body">
                    <div className="ricard-image">
                      <img src={rishabet}></img>
                    </div>
                    <h4 className="ricard-title">Elapsed Time Meter (Hours Run Meter)</h4>
                    <p className="ricard-text ">ETM72 & ETM96 Elapsed Time Meters (Hours Run Meters) are used to monitor ON/RUN time of plant/equipment which allows the user to check functions such as Production Efficiency, Cost and service period monitoring.</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-4 col-md-4 col-xl-4 col-xs-12" >
                <div className="ricard"  >
                  <div className="ricard-body">
                    <div className="ricard-image">
                      <img src={rishab2}></img>
                    </div>
                    <h4 className="ricard-title">DIN rail Moving Iron meter (EQ,PQ,VQ,ZQ 35)</h4>
                    <p className="ricard-text ">EQ35, PQ35, VQ35 & ZQ35 meters measure AC/DC Voltage or Current and frequency. These meters are installed on 35mm Din rail, the mounting width is 45mm and it is housed in UL 94 V-0 approved polycarbonate case and can be mounted in Mosaic mounting (cascading).</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-4 col-md-4 col-xl-4 col-xs-12" >
                <div className="ricard" id="rishabanalog2">
                  <div className="ricard-body" >
                    <div className="ricard-image">
                      <img src={rishab3}></img>
                    </div>
                    <h4 className="ricard-title">Challenger series meters</h4>
                    <p className="ricard-text ">Challenger 361, 362, 363 & 364 meters measure AC/DC Voltage or Current and frequency. The design features detachable lower fascia which allows the flexibility of either surface or window mounting. These meters have a rear zero adjuster screw for tamperproof installation.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="ricontainer" style={{ marginTop: "30px" }}>
            <div className="row">
              <div className="col-12" id="rishabanatwo">
                <div className="rititle-heading">multifunction meters</div>
              </div>
            </div>
            <div className="row" >
              <div className="col-lg-4 col-sm-4 col-md-4 col-xl-4 col-xs-12">
                <div className="ricard">
                  <div className="ricard-body">
                    <div className="ricard-image">
                      <img src={mmc}></img>
                    </div>
                    <h4 className="ricard-title">Compact VAF Meter - RISH CR12</h4>
                    <p className="ricard-text ">ETM72 & ETM96 Elapsed Time Meters (Hours Run Meters) are used to monitor ON/RUN time of plant/equipment which allows the user to check functions such as Production Efficiency, Cost and service period monitoring.</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-4 col-md-4 col-xl-4 col-xs-12" >
                <div className="ricard"  >
                  <div className="ricard-body">
                    <div className="ricard-image">
                      <img src={mmc}></img>
                    </div>
                    <h4 className="ricard-title">Compact VAF Meter - RISH CD30/60</h4>
                    <p className="ricard-text ">RISH CD30/60 Compact VAF Meter measures electrical parameters in 1 Phase Network for AC direct current measurement application.</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-4 col-md-4 col-xl-4 col-xs-12" >
                <div className="ricard" id="rishabanalog2">
                  <div className="ricard-body" >
                    <div className="ricard-image">
                      <img src={rironmeter}></img>
                    </div>
                    <h4 className="ricard-title">Moving Iron meter AC ammeters and voltmeters 90deg (EQ)</h4>
                    <p className="ricard-text ">Single channel RISH EMDC 6000 is specially designed to measure, display and communicate DC Voltage, Current, Power and Energy to monitor and control the target system.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="ricontainer" style={{ marginTop: "30px" }}>
            <div className="row">
              <div className="col-12" id="rishabthree">
                <div className="rititle-heading">current transformers</div>
              </div>
            </div>
            <div className="row" >
              <div className="col-lg-4 col-sm-4 col-md-4 col-xl-4 col-xs-12">
                <div className="ricard">
                  <div className="ricard-body">
                    <div className="ricard-image">
                      <img src={ct}></img>
                    </div>
                    <h4 className="ricard-title">3 Phase RJ12 Current Transformer
</h4>
                    <p className="ricard-text ">Rish 3 Phase CTs are Square & round type, encapsulated with UL94V-0 approved polycarbonate. It has RJ12 100mA secondary output with RJ12 interface cable, Available from 60A...800A primary current for Square type and 63A,125A & 250A for nano round type CT's.</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-4 col-md-4 col-xl-4 col-xs-12" >
                <div className="ricard"  >
                  <div className="ricard-body">
                    <div className="ricard-image">
                      <img src={ct1}></img>
                    </div>
                    <h4 className="ricard-title">RISH RESIN CAST PROTECTION CT</h4>
                    <p className="ricard-text ">Rish resin cast protection CTs are square type and molded with epoxy resin. Protection CTs feature with transparent terminal cover & wide range of primary current ratings, case widths.  Wall or foot mounting options available.</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-4 col-md-4 col-xl-4 col-xs-12" >
                <div className="ricard" id="rishabfur">
                  <div className="ricard-body" >
                    <div className="ricard-image">
                      <img src={ct3}></img>
                    </div>
                    <h4 className="ricard-title">RISH Compact Split Core CT</h4>
                    <p className="ricard-text ">Rish Compact Split core CTs are compact in size as compared to earlier Split core CTs and encapsulated with UL94V-0 approved polycarbonate having an openable jaw which has special kind of locking profile to avoid accidental opening.
</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="ricontainer" style={{ marginTop: "30px" }}>
            <div className="row">
              <div className="col-12" id="rishabfive">
                <div className="rititle-heading">digital multimeter</div>
              </div>
            </div>
            <div className="row" >
              <div className="col-lg-4 col-sm-4 col-md-4 col-xl-4 col-xs-12">
                <div className="ricard">
                  <div className="ricard-body">
                    <div className="ricard-image">
                      <img src={dm1}></img>
                    </div>
                    <h4 className="ricard-title">Rishabh Young / Rishabh iYoung
</h4>
                    <p className="ricard-text ">The new Rishabh Young and Rishabh iYoung Multimeter are compact, Handy & Trendy multimeters specially targeted for applications of Electrical contractors, Service Industry, Technicians, HVAC and also the education sector i.e., students who will be the future of tomorrow.</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-4 col-md-4 col-xl-4 col-xs-12" >
                <div className="ricard"  >
                  <div className="ricard-body">
                    <div className="ricard-image">
                      <img src={dm2}></img>
                    </div>
                    <h4 className="ricard-title">Rishabh 6012/6013/6015/6016 Bluetooth Multimeter</h4>
                    <p className="ricard-text ">Rish X’mer CTs are square type and encapsulated with UL94V-0 approved polycarbonate. Metering CTs feature with wire sealable terminal covers & wide range of primary current ratings, Bus bar sizes, case widths.  Wall, cable, bus bar & DIN rail mounting options available.</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-4 col-md-4 col-xl-4 col-xs-12" >
                <div className="ricard" id="rishabanalog2">
                  <div className="ricard-body" >
                    <div className="ricard-image">
                      <img src={dm3}></img>
                    </div>
                    <h4 className="ricard-title">RISHABH 6016</h4>
                    <p className="ricard-text ">The 6016 new multimeter is made for professional use that offers large range count,reliability, ruggedness, a complete tool for test automation, 100KHz Bandwidth and is equipped with 30 different measuring functions alongwith data logging facility & plug and play USB for connection with PC.
</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="ricontainer" style={{ marginTop: "30px" }}>
            <div className="row">
              <div className="col-12" id="rishabanalog">
                <div className="rititle-heading">earth testers</div>
              </div>
            </div>
            <div className="row" >
              <div className="col-lg-4 col-sm-4 col-md-4 col-xl-4 col-xs-12">
                <div className="ricard">
                  <div className="ricard-body">
                    <div className="ricard-image">
                      <img src={et1}></img>
                    </div>
                    <h4 className="ricard-title">AET 23 Smart Digital Earth Tester
</h4>
                    <p className="ricard-text ">The Agam, AET 23 is a Smart  Digital Earth Tester capable of performing measurements of both earth resistance in ohms and also calculates AC earth voltage from 0-200V.Earth resistance testing can be performed across three ranges: 0-20 Ohms , 0-200 Ohms and 0-2000 Ohms.</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-4 col-md-4 col-xl-4 col-xs-12" >
                <div className="ricard"  >
                  <div className="ricard-body">
                    <div className="ricard-image">
                      <img src={et2}></img>
                    </div>
                    <h4 className="ricard-title">Earth Testers</h4>
                    <p className="ricard-text ">ET series are the portable Earth Testers for measurement of earth resistance and soil resistivity. The compact design allows the use in industrial as well as household application.</p>
                  </div>
                </div>
              </div>
              {/* <div className="col-lg-4 col-sm-4 col-md-4 col-xl-4 col-xs-12" >
                <div className="ricard" id="rishabanalog2">
                  <div className="ricard-body" >
                    <div className="ricard-image">
                      <img src=""></img>
                    </div>
                    <h4 className="ricard-title">RISHABH 6016</h4>
                    <p className="ricard-text ">The 6016 new multimeter is made for professional use that offers large range count,reliability, ruggedness, a complete tool for test automation, 100KHz Bandwidth and is equipped with 30 different measuring functions alongwith data logging facility & plug and play USB for connection with PC.
</p>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
          <div className="ricontainer" style={{ marginTop: "30px" }}>
            <div className="row">
              <div className="col-12" id="risabsix">
                <div className="rititle-heading">Power Supply</div>
              </div>
            </div>
            <div className="row" >
              <div className="col-lg-4 col-sm-4 col-md-4 col-xl-4 col-xs-12">
                <div className="ricard">
                  <div className="ricard-body">
                    <div className="ricard-image">
                      <img src={ps}></img>
                    </div>
                    <h4 className="ricard-title">Rish Flex 48024A (1 Phase)
</h4>
                    <p className="ricard-text ">1Phase, 115-230Vac I/P& 24Vdc 384 to 480 W O/p, Adjustable o/p voltage range 22 – 27 Vdc. High efficiency ≥92% & compact size (w-h-d) 85x120x140 mm & DIN Rail Mountable.</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-4 col-md-4 col-xl-4 col-xs-12" >
                <div className="ricard"  >
                  <div className="ricard-body">
                    <div className="ricard-image">
                      <img src={ps}></img>
                    </div>
                    <h4 className="ricard-title">Rish Flex 24024A (1 Phase)</h4>
                    <p className="ricard-text ">1Phase, 115-230Vac I/P& 24Vdc 192 to 240 W O/p, Adjustable o/p voltage range 22 – 27 Vdc. High efficiency ≥91% & compact size (w-h-d) 72x115x135mm & DIN Rail Mountable.</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-4 col-md-4 col-xl-4 col-xs-12" >
                <div className="ricard" id="rishabanalog2">
                  <div className="ricard-body" >
                    <div className="ricard-image">
                      <img src={ps1}></img>
                    </div>
                    <h4 className="ricard-title">Rish Flex 15048A (1 Phase)</h4>
                    <p className="ricard-text ">1Phase, 115-230Vac I/P& 48Vdc 120 to 150 W O/p, Adjustable o/p voltage range 41 – 55 Vdc. High efficiency ≥91% & compact size (w-h-d) 55x110x105 mm & DIN Rail Mountable.
</p>
                  </div>
                </div>
              </div>
              
            </div>
          </div>
        </div>
     
      {/* ))} */}
    </>
  );
}

export default Card;
