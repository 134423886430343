import React from "react";
import "./tfit.css";
import rilogo from "../../assets/rilogo.png";
import d1 from "../../assets/d1.png";
import tfitclean from "../../assets/tfitclean.png";
import tfitc from "../../assets/tfitc.png";
import tfith from "../../assets/tfith.png";
import tfitp from "../../assets/tfitp.png";
import th from "../../assets/th.png";
import tp from "../../assets/tp.png";
import tfitcc from "../../assets/tfitcc.png";
import tfitblue from "../../assets/tfitblue.png";
import tfitbt from "../../assets/tfitbt.png";
import tfitbth from "../../assets/tfitbth.png"
// import Tdata from "./Titdata";
import tfithygn from "../../assets/tfithg.png";
import tfitr from "../../assets/tfitpr.png";
import tfitcn from "../../assets/tfitcn.png";
import tfih from "../../assets/tfitheclean.png"
import tfithg from "../../assets/tfithg.png";
import tfitpro from "../../assets/tfitpro.png";
import Havells from "../../assets/pdfs/tfitc.pdf";
import tfitpp from "../../assets/pdfs/tfitp.pdf";
import tfihh from "../../assets/pdfs/tfith.pdf";


import Data from "./Titdata";
function Tfit() {
  const handleDownload = () => {
    // Replace 'external-pdf-url' with the actual URL of the PDF file you want to download
    const pdfUrl = './sample.pdf';
    
    // Create an anchor element to trigger the download
    const link = document.createElement('a');
    link.href = pdfUrl;
    link.download = './sample.pdf'; // Specify the desired download filename
    link.target = '_blank';
    
    // Simulate a click on the anchor element
    link.click();
  };


  return (
    <>
    <div className="tfitmainbody">
      <h1 className="tfithead">
        The best High quality, high-performance, long lasting insulation
        solution
      </h1>
      <img src={d1} className="tfitmimage"></img>

      <h2 className="tfitc01">There’s only one T-FIT®</h2>
      <p className="tfitc02">
        Only T-FIT® crosslinked PVDF foam insulation is made using Zotefoams
        unique, patented three-stage manufacturing process. It is the only
        genuine crosslinked PVDF foam insulation on the market, so only T-FIT®
        provides unrivalled performance and minimal maintenance, year after year
        of a class-leading service life.
      </p>

      <div className="tfitccontainer">
        <div className="card">
          <div className="card-header">
            <img src={tfitblue} alt="rover" />
          </div>
          <div className="card-body">
            <p>
              <span style={{ color: "#005E8C" }}>
                Clean room pipe insulation{" "}
              </span>{" "}
              designed for demanding cleanroom environments in pharmaceutical,
              biotech and semiconductor manufacture
            </p>
            <ul>
              <li>FM4910 Specification tested</li>
              <li>Produced using FDA-compliant resin</li>
              <li>Hydrophobic, bacteria-resistant (ASTM G21-15 certified)</li>
              <li>Wide operating temperature range (-80oC to +160oC)</li>
              <li>Easy to clean</li>
              <li>Excellent flammability credentials</li>
              <li>Resistant to most chemicals</li>
              <li>UV-resistant insulation</li>
              <li>Will not shed particulates</li>
              <li>Very low VOC/outgassing</li>
            </ul>
            <div className="user">
              <img src={tfitclean} alt="user" />
              {/* <div className="user-info">
          <h5>July Dec</h5>
          <small>2h ago</small>
        </div> */}
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-header">
            <img src={tfitbt} alt="ballons" />
          </div>
          <div className="card-body">
            {/* <span className="tag tag-purple">Popular</span> */}
            <p>
            <span style={{ color: "#005E8C" }}>
              Pipe insulation tailored for aseptic areas in FMCG applications,
              </span>
              especially food and beverage, dairy and personal care which
              require pipe insulation that is suitable for both chilled and hot
              processing applications
            </p>
            <ul>
              <li>Wide operating temperature range (-80oC to +145oC)</li>
              <li>Easy to clean</li>
              <li>Withstands continuous temperature fluctuations</li>
              <li>Produced using FDA-compliant resin</li>
              <li>Hydrophobic, bacteria-resistant (ASTM G21-15 certified)</li>
              <li>Resistant to most chemicals</li>
              <li>Excellent flammability credentials</li>
              <li>UV-resistant insulation</li>
            </ul>
            <div className="user">
              <img src={th} alt="user" />
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-header">
            <img src={tfitbth} alt="city" />
          </div>
          <div className="card-body">
            {/* <span className="tag tag-pink">Design</span> */}
            <p>
              Developed for insulating rigorous high-temperature industrial
              processes, T-FIT Process  <span style={{ color: "#005E8C" }}> heat and fire-resistant pipe insulation </span>
              offers the highest level of physical protection around hot pipe
              works
            </p>
            <ul>
              <li>High-temperature performance</li>
              <li>Wide operating temperature range (-20oC to +200oC)</li>
              <li>Robust and durable</li>
              <li>Hydrophobic, bacteria-resistant (ASTM G21-15 certified)</li>
              <li>Resistant to most chemicals</li>
              <li>Integral aluminium cladding</li>
              <li>Automotive industry approvals</li>
              <li>UV-resistant</li>
            </ul>
            <div className="user">
              <img src={tp} alt="user" />
            </div>
          </div>
        </div>
      </div>
      {/* <label className="sepline-01"></label> */}
      <div className="atfitflex-container">
        <div className="tfitcleandesc01">
          <h1 className="tfitcleanhead-01">T-FIT®</h1>
          <h5 className="tfitcleanhead-02">
            T-FIT® CLEAN
            <br></br>
            <span>Unique Insulation Technology</span>
          </h5>
          <section className="section_process">
          <div className="padding-global">
            <div className="container-large _90vw">
              <div className="padding-top padding-xhuge">
                <div className="w-layout-grid process-layout_component">
                  <div className="process-layout_content-left" style={{top:'0'}}>
                    <div className="margin-bottom margin-medium">
                      <div className="section-number-wrapper margin-bottom margin-medium">
                        {/* <div className="number-circle">
                                            <div className="text-color-black text-size-regular font-satoshi">3</div>
                                        </div> */}
                      </div>
                      <div className="margin-bottom">
                      <img src={tfitcc} className="atfitclean-01img" ></img>
                      </div>
                    </div>
                  
                    <div className="button-group margin-top margin-large">
                    
                    </div>
                  </div>
                  <div className="process-layout_content-right">
                    {/* <div
                      className="process-layout_content-item content-item-1"
                      style={{ color: "rgba(92, 92, 92, 0.70)",border:'none' }}
                    > */}
                     
                      <ng-container>
  <div className="product-detaila">
    <div className="scroll-containera">
      <div className="product-infoa">
        <table class="responsive-tablea">
          <thead>
            <tr>
              <th>Property</th>
              <th>Test Standard</th>
              <th>Typical Value</th>
            </tr>
          </thead>
          <tbody>
            {Data.map((value, index) => (
              <tr key={index}>
                <td>{value.prop}</td>
                <td>{value.std}</td>
                <td>{value.value}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  </div>
</ng-container>
                    {/* </div> */}
                    <div className='downlaod-solapnel' >
      
        <a className='havellviewmore' href={Havells} target='_blank'> 
        Download Brochure
</a>
 
      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        </div>
      </div>
   
    
      <div className="secondshe">
      <div className="tfithygflex-container">
      <div className="tfitcleandesc01">
          <h1 className="tfitcleanhead-01">T-FIT®</h1>
          <h5 className="tfitcleanhead-02">
            {/* T-FIT® CLEAN */}
             T-FIT® HYGEINE




{/* Unique Insulation Technology */}


            <br></br>
            <span>Unique Insulation Technology</span>
          </h5>
          <section className="section_process">
          <div className="padding-global">
            <div className="container-large _90vw">
              <div className="padding-top padding-xhuge">
                <div className="w-layout-grid process-layout_component">
                  <div className="process-layout_content-left" style={{top:'0'}}>
                    <div className="margin-bottom margin-medium">
                      <div className="section-number-wrapper margin-bottom margin-medium">
                        {/* <div className="number-circle">
                                            <div className="text-color-black text-size-regular font-satoshi">3</div>
                                        </div> */}
                      </div>
                      <div className="margin-bottom">
                      <img src={tfithygn} className="atfitclean-01img" ></img>
                      </div>
                    </div>
                 
                    <div className="button-group margin-top margin-large">
                   
                    </div>
                  </div>
                  <div className="process-layout_content-right">
                    {/* <div
                      className="process-layout_content-item content-item-1"
                      style={{ color: "rgba(92, 92, 92, 0.70)",border:'none' }}
                    > */}
                     
                      <ng-container>
  <div className="product-detaila">
    <div className="scroll-containera">
      <div className="product-infoa">
        <table class="responsive-tablea">
          <thead>
            <tr>
              <th>Property</th>
              <th>Test Standard</th>
              <th>Typical Value</th>
            </tr>
          </thead>
          <tbody>
            {Data.map((value, index) => (
              <tr key={index}>
                <td>{value.prop}</td>
                <td>{value.std}</td>
                <td>{value.value}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  </div>
</ng-container>
                    {/* </div> */}
                    <div className='downlaod-solapnel' >
     
      <a className='havellviewmore' href={tfihh} target='_blank'>
      Download Brochure
</a>


    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        </div>
      </div>
      {/* tfit 3*/}
      </div>
      <div className="secondshe">
      <div className="tfithygflex-container">
      <div className="tfitcleandesc01">
          <h1 className="tfitcleanhead-01">T-FIT®</h1>
          <h5 className="tfitcleanhead-02">
          T-FIT® PROCESS
{/* Unique Insulation Technology */}


            <br></br>
            <span>Unique Insulation Technology</span>
          </h5>
          <section className="section_process">
          <div className="padding-global">
            <div className="container-large _90vw">
              <div className="padding-top padding-xhuge">
                <div className="w-layout-grid process-layout_component">
                  <div className="process-layout_content-left" style={{top:'0'}}>
                    <div className="margin-bottom margin-medium">
                      <div className="section-number-wrapper margin-bottom margin-medium">
                        {/* <div className="number-circle">
                                            <div className="text-color-black text-size-regular font-satoshi">3</div>
                                        </div> */}
                      </div>
                      <div className="margin-bottom">
                      <img src={tfitpro} className="atfitclean-01img" ></img>
                      </div>
                    </div>
                 
                    <div className="button-group margin-top margin-large">
                   
                    </div>
                  </div>
                  <div className="process-layout_content-right">
                    {/* <div
                      className="process-layout_content-item content-item-1"
                      style={{ color: "rgba(92, 92, 92, 0.70)",border:'none' }}
                    > */}
                     
                      <ng-container>
  <div className="product-detaila">
    <div className="scroll-containera">
      <div className="product-infoa">
        <table class="responsive-tablea">
          <thead>
            <tr>
              <th>Property</th>
              <th>Test Standard</th>
              <th>Typical Value</th>
            </tr>
          </thead>
          <tbody>
            {Data.map((value, index) => (
              <tr key={index}>
                <td>{value.prop}</td>
                <td>{value.std}</td>
                <td>{value.value}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  </div>
</ng-container>
                    {/* </div> */}
                    <div className="tfitbox">
                      <div className='downlaod-solapnel tfit' >
     
      <a className='havellviewmore' href={tfitpp} target='_blank'>
      Download Brochure
</a>


    </div>
    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
       
        </div>
      </div>
      </div>


    </div>
    
      </>
  );
}

export default Tfit;
