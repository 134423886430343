const Data =
[
  {
    "id": 1,
    "prop": "Material",
    "std": "",
    "value": " Manufactured from an FDA Compliant Kynar® based resin"
  },

  {
    "id": 2,
    "prop": "Service Temperature",
    "std": "See Notes Below*",
    "value": "      -80 °C to +160 °C"
  },

  {
    "id": 3,
    "prop": "Thermal Conductivity ",
    "std": "",
    "value": " "
  },

  {
    "id": 4,
    "prop": "Mean temperature of 0 °C        ",
    "std": "",
    "value": "0.0314 W/m.K "
  },
  {
    "id": 5,
    "prop": "Mean temperature of 25 °C        ",
    "std": "ISO 8301 ",
    "value": " 0.0347 W/m.K        "
  },
  {
    "id": 6,
    "prop": "Mean temperature of 80 °C        ",
    "std": "",
    "value": " 0.0434 W/m.K        "
  },
  {
    "id": 7,
    "prop": "Fire Certification        ",
    "std": "EN13501-1",
    "value": "B - s1, d0        "
  },
  {
    "id": 8,
    "prop": "Euroclass",
    "std": "Flaming Spread Index @ 25mm   ",
    "value": " 5"
  },
  {
    "id": 9,
    "prop": "UL723 / ASTM E84        ",
    "std": "Smoke Developed Index @ 25mm  ",
    "value": " 45"
  },
  {
    "id": 10,
    "prop": "Water Vapour Transmission        ",
    "std": "ISO 1663  ",
    "value": " 6500, μ Valu        "
  },
  {
    "id": 11,
    "prop": "Outgassing",
    "std": "VDI 2083-P17        ",
    "value": " 8.86 μg/g"
  },
  {
    "id": 12,
    "prop": "Fungus Resistance        ",
    "std": "ASTM G21-15  ",
    "value": " Full test complete No signs after 28 days"
  },
  {
    "id": 13,
    "prop": "Factory Mutual 4910 Cleanroom",
    "std": ""
  },
  {
    "id": 13,
    "std": "Specification Tested"
  },
  {
    "id": 13,
    "prop": "Materials Flammability Test Protocol"
  }
]
export default Data